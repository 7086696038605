// Node
import config from 'react-global-configuration'
// API
import axios from '@api/instance'
import { admissionStatusBuilderMapper as builder } from '@api/endpoints/admissionAPI/builders/admissionStatus/mapper'
import { validator } from '@api/utils/validator'
// Types
import APITypes from '@api/types/api'

// Экспорт API поступления
export const admissionAPI = {
    getAdmissionList() {
        return axios[config.get('api.admissionList.type') as APITypes.AxiosType]
            .get(config.get('api.admissionList.url'))
            .then((response: APITypes.AxiosResponse) => {
                // Возврат ответа API, пропущенный через валидатор
                return validator.validate(
                    response.data,
                    'admissionAPI', // директория эндпоинта
                    'AdmissionListView', // тип валидатора
                )
            })
            .catch(() => {
                return null
            })
    },
    getAdmissionStatus() {
        return axios[config.get('api.admissionStatus.type') as APITypes.AxiosType]
            .get(config.get('api.admissionStatus.url'))
            .then((response: APITypes.AxiosResponse) => {
                // Возврат ответа API, пропущенный через валидатор и маппер
                return builder.dataMapping(
                    validator.validate(
                        response.data,
                        'admissionAPI', // директория эндпоинта
                        'AdmissionStatusView', // тип валидатора
                    ),
                )
            })
            .catch(() => {
                return null
            })
    },
}
