import React from 'react'
// Node
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material'
// Types
import MaterialPasswordTypes from './types/MaterialPassword'

/*
 * Компонент "Поле ввода с паролем" (обертка над MaterialUI)
 */

// Экспорт компонента
export const MaterialPassword: React.FC<MaterialPasswordTypes.ComponentProps> = ({ label }) => {
    const [values, setValues] = React.useState<MaterialPasswordTypes.PasswordProps>({
        password: '',
        showPassword: false,
    })

    const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value })
    }

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        })
    }

    const handleMouseDownPassword = (event: React.SyntheticEvent) => {
        event.preventDefault()
    }

    return (
        <FormControl {...{ sx: { m: 1, width: '25ch' }, variant: 'outlined' }}>
            <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
            <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {values.showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                    </InputAdornment>
                }
                label="{label}"
            />
        </FormControl>
    )
}

// Свойства по умолчанию
MaterialPassword.defaultProps = {}

// Экспорт компонента
export default MaterialPassword
