import React from 'react'
// Node
import { Box, Tab, Tabs } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
// Types
import MaterialTabsTypes from './types/MaterialTabs'
// Own
import useStyleMui from './styles/style.mui'

/*
 * Компонент "Вкладки" (обертка над MaterialUI)
 */

// Компонент панели таба
const TabPanel: React.FC<{
    tabBoxStyle: MaterialTabsTypes.ComponentProps['tabBoxStyle']
    children: MaterialTabsTypes.tabItem['content']
    value: MaterialTabsTypes.ComponentProps['activeTabIndex']
    index: MaterialTabsTypes.ComponentProps['activeTabIndex']
    showedTabIndexes: Array<MaterialTabsTypes.ComponentProps['activeTabIndex']>
    renderOnFirstChangeOnly: MaterialTabsTypes.ComponentProps['renderOnFirstChangeOnly']
    styleMui: any
}> = (props) => {
    // Преобразование пропсов в локальные константы
    const {
        tabBoxStyle,
        children,
        value,
        index,
        showedTabIndexes,
        renderOnFirstChangeOnly,
        styleMui,
        ...other
    } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {
                // Если индекс панели таба соответствует текущему значению value
                (value === index ||
                    // или установлен маркер "рендер панели только при первом отображении", а
                    // индекс панели таба содержится в массиве индексов просмотренных табов
                    (renderOnFirstChangeOnly && showedTabIndexes.includes(index))) && (
                    <Box className={styleMui.panelBox} sx={tabBoxStyle}>
                        {children}
                    </Box>
                )
            }
        </div>
    )
}

// Индексы просмотренных табов
const showedTabIndexes: Array<MaterialTabsTypes.ComponentProps['activeTabIndex']> = []

// Экспорт компонента
export const MaterialTabs: React.FC<MaterialTabsTypes.ComponentProps> = (props) => {
    // Преобразование пропсов в локальные константы
    const {
        tabs,
        activeTabIndex,
        variant,
        onClick,
        onClose,
        tabStyle,
        tabsListStyle,
        tabBoxStyle,
        orientation,
        renderOnFirstChangeOnly,
    } = props

    const styleMui = useStyleMui(props)

    // Установка локального значения переменной value
    const [value, setValue] = React.useState(activeTabIndex || 0)

    // Событие переключения табов
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
        onClick && onClick(newValue)
    }

    // Хук эффекта, завязанный на изменение локальной переменной value
    React.useEffect(() => {
        // Если value не содержится в массиве индексов просмотренных табов
        if (!showedTabIndexes.includes(value)) {
            // Записать value в массив индексов просмотренных табов
            showedTabIndexes.push(value)
        }
    }, [value])

    // Хук эффекта, завязанный на изменение пропса activeTabIndex
    React.useEffect(() => {
        if (typeof activeTabIndex !== 'undefined') {
            // Если activeTabIndex не содержится в массиве индексов просмотренных табов
            if (!showedTabIndexes.includes(activeTabIndex)) {
                // Записать activeTabIndex в массив индексов просмотренных табов
                showedTabIndexes.push(activeTabIndex)
            }
            // Установка локального значения переменной value в activeTabIndex
            setValue(activeTabIndex)
        }
    }, [activeTabIndex])

    // Контент лейблов и панелей табов
    const TabLabelsContent: Array<MaterialTabsTypes.tabItem['label']> = []
    const TabPanelsContent: Array<MaterialTabsTypes.tabItem['content']> = []
    tabs?.forEach((oneTab, index: number) => {
        // Иконка таба
        const tabIcon = oneTab.icon ? (
            <Box>
                <Box sx={{ float: 'left' }}>{oneTab.icon}</Box>
                {onClose && (
                    <Box sx={{ float: 'right' }}>
                        <CloseIcon
                            fontSize={'inherit'}
                            color="secondary"
                            onClick={(e: React.SyntheticEvent) => {
                                onClose(oneTab?.object)
                                e.stopPropagation()
                            }}
                        />
                    </Box>
                )}
            </Box>
        ) : (
            ''
        )
        TabLabelsContent.push(
            <Tab
                sx={tabStyle}
                label={oneTab.label}
                key={`tabLabel_${index}`}
                icon={tabIcon}
                onClick={oneTab.onClick}
            />,
        )
        TabPanelsContent.push(
            <TabPanel
                value={value}
                index={index}
                tabBoxStyle={tabBoxStyle}
                key={`tabPanel_${index}`}
                showedTabIndexes={showedTabIndexes}
                renderOnFirstChangeOnly={renderOnFirstChangeOnly}
                styleMui={styleMui}
            >
                {oneTab.content}
            </TabPanel>,
        )
    })

    return (
        <Box className={styleMui.tabsBox}>
            <Tabs
                sx={tabsListStyle}
                variant={variant}
                scrollButtons={variant === 'scrollable' ? true : false}
                orientation={orientation ? orientation : 'horizontal'}
                allowScrollButtonsMobile={true}
                value={value}
                onChange={handleChange}
                textColor="inherit"
                className={styleMui.tabs}
            >
                {TabLabelsContent}
            </Tabs>
            {TabPanelsContent}
        </Box>
    )
}

// Свойства по умолчанию
MaterialTabs.defaultProps = {
    activeTabIndex: 0,
    variant: 'fullWidth',
    tabStyle: {},
    tabsListStyle: {},
    tabBoxStyle: {},
    renderOnFirstChangeOnly: true,
    orientation: 'horizontal',
}

// Экспорт компонента
export default MaterialTabs
