import React from 'react'
// Node
import { ImportContacts as LabelImportantIcon } from '@mui/icons-material'
// Components
import { LinkField } from '@commonComponents/DataFields'
// Types
import EducationItemTypes from '../types/EducationItem'
import MaterialListTypes from '@materialComponents/MaterialList/types/MaterialList'
// Own
import EducationItemSkeleton from '../skeleton'

// Экспорт элементов списка
export const useListItems = (
    props: EducationItemTypes.ComponentProps,
): MaterialListTypes.ComponentProps['listItems'] => {
    // Если загрузка не завершена
    if (!props.loadingEducationItemComplete) {
        // Возврат массив элементов списка из скелетонов
        return EducationItemSkeleton.listItems
    }
    // Поля обучения, которые попадут в список
    const educationFields: EducationItemTypes.EducationFields = {
        specQualifier: { title: 'Код специальности' },
        groupAbbreviation: { title: 'Аббревиатура' },
        kindTraining: { title: 'Форма обучения' },
        instituteName: { title: 'Институт', linkField: 'instituteUrl' },
        kafedraName: { title: 'Кафедра', linkField: 'kafedraUrl' },
        qualificationName: { title: 'Квалификация' },
    }

    // Массив элементов списка
    const listItems: MaterialListTypes.ComponentProps['listItems'] = []

    // Проход по всем полям обучения, которые попадут в список
    for (const key in educationFields) {
        // Если обучение - не пустой объект
        if (props?.educationItem) {
            // Текущее поле
            const currentField = educationFields[key]
            // Текущее значение
            const currentValue = props?.educationItem[key]

            // Формирование элемента списка
            listItems.push({
                // Заголовок поля обучения
                primary: currentField.title,
                // Значение поля обучения
                secondary: currentField.linkField ? (
                    <LinkField
                        fieldId={`educationListItem_${key}`}
                        linkText={currentValue}
                        linkURL={props?.educationItem[currentField.linkField]}
                        linkTarget={'_blank'}
                        linkSX={{ display: 'block', width: 'fit-content' }}
                    />
                ) : currentValue ? (
                    currentValue
                ) : (
                    'не указано'
                ),
                // Иконка элемента списка
                avatar: <LabelImportantIcon />,
            })
        }
    }

    // Возврат сформированного массива элементов списка
    return listItems
}
