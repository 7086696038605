import React from 'react'
// Node
import { Box, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
// Types
import { AppStateType } from '@redux/store'
import EducationPlanDisciplineTypes from './types/EducationPlanDiscipline'
// Own
import useStyleMui from './styles/style.mui'
import { getDisciplineHeader, getDisciplineContent } from './data/data.discipline_blocks'

/*
 * Компонент "Дисциплина"
 */

const EducationDiscipline: React.FC<EducationPlanDisciplineTypes.ComponentProps> = (props) => {
    // Преобразование пропсов в локальные константы
    const { discipline, filteredItems, disciplineType, documents } = props

    // Стиль компонента
    const styleMui = useStyleMui()

    // Ширина контента
    const contentWidth = useSelector((state: AppStateType) => state.windowParamsStore?.contentWidth)

    // Маркер того, является ли разрешение экрана больше брекпоинта 'sm'
    const isUpSM = useSelector((state: AppStateType) => state.windowParamsStore?.isUpSM)    

    // Заголовок дисциплины
    const disciplineHeader = getDisciplineHeader({ disciplineType, filteredItems, styleMui })
    // Контент дисциплины
    const disciplineContent = getDisciplineContent({ disciplineType, filteredItems, styleMui, contentWidth, isUpSM })

    return (
        <Box>
            <Typography className={styleMui.disciplineName} variant={'h5'}>{discipline.name}</Typography>
            <Box>
                <Box className={styleMui.contentBlock}>
                    { disciplineHeader }
                </Box>
                <Box className={styleMui.contentBlock}>
                    { disciplineContent }
                </Box>
                { documents &&
                    <Box className={styleMui.contentBlock}>
                        <Box className={styleMui.caption} component={'span'}>
                            Документы
                        </Box>
                        {documents}
                    </Box>
                }
            </Box>
        </Box >
    )
}

// Экспорт компонента
export default EducationDiscipline
