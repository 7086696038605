// Node
import config from 'react-global-configuration'
// Types
import APITypes from '../types/api'

// Тип колбэка-подписчика на отправку запроса
type SubscriberType = (token: string) => void

// Экспорт перехватчика ответов сервера для axios
export const responseInterceptor = (axios: APITypes.AxiosWrapper) => {
    //=== НАСТРОЙКИ ЭСПОНЕНЦИАЛЬНОГО ОТКЛАДЫВАНИЯ ===

    // Количество попыток отправки
    // (если 0 - бесконечная отправка до получения успешного овета сервера)
    const retryCount = config.get('interceptors.response.retryCount')
    // Максимальный интервал повтора запроса(мс)
    const maxBackoff = config.get('interceptors.response.maxBackoff')

    //=== НАСТРОЙКИ ДЛЯ ОТПРАВКИ ПРОСРОЧЕННЫХ ТОКЕНОВ ===

    // Массив колбэков-подписчиков на отправку запроса после рефреша токена
    let tokenRefreshSubscribers: Array<SubscriberType> = []
    // Маркер того, что рефреш токенов запущен
    let isTokenRefreshing = false

    // Подписать колбэк на последующую отправку запроса после рефреша токена
    const subscribeTokenRefresh = (subscriber: SubscriberType) => {
        tokenRefreshSubscribers.push(subscriber)
    }

    // Событие по окончании рефреша токенов
    const onTokenRefreshed = (token: string) => {
        // Проход по всем подписчикам
        tokenRefreshSubscribers.forEach((subscriber) => {
            // Вызов колбека с повторной отправкой запроса
            subscriber(token)
        })
    }

    //=== ПЕРЕХВАТ ОТВЕТОВ СЕРВЕРА ===
    axios.instance.interceptors.response.use(
        // Успешный ответ сервера
        (response: APITypes.AxiosResponse) => {
            return response
        },
        // Ответ сервера с ошибкой
        (error: APITypes.AxiosError) => {
            // Получение объекта keycloak из объекта window
            const keycloak = window.keycloak
            // Получение параметров запроса(config) и ответа(status)
            const {
                config,
                response: { status },
            } = error

            //=== ПРОСРОЧЕННЫЕ ТОКЕНЫ ===

            // Если запрос еще не был повторно отправлен(не установлен заголовок isRetryAttempt)
            // и ответ от сервера для этого запроса сообщает об ошибке аутентификации/авторизации
            if (status === 401 && !config.headers.isRetryAttempt) {
                // Если процесс рефреша токенов не запущен
                if (!isTokenRefreshing) {
                    // Устанавливаем маркер о том, что рефреш токенов запущен
                    isTokenRefreshing = true
                    keycloak
                        // Выполняем рефреш токенов
                        .updateToken(-1)
                        // В случае успешного рефреша
                        .then(() => {
                            // Устанавливаем маркер о том, что рефреш токенов завершен
                            isTokenRefreshing = false
                            // Запуск события по окончании рефреша токенов
                            onTokenRefreshed(keycloak.token)
                            // Очистка массива колбэков-подписчиков на отправку запроса
                            tokenRefreshSubscribers = []
                        })
                        // В случае ошибки при рефреше
                        .catch(() => {
                            // Вывод ошибки в консоль браузера
                            console.error('Произошла ошибка при рефреше токенов!')
                            // Установка заголовка Authorization для экземпляра axios
                            axios.setAuthHeader('')
                            // Выход из сеанса keycloak
                            keycloak.logout()
                        })
                }

                // Возврат промиса с выполнением запроса
                // Сюда складываются все запросы, которые затем будут единовременно отправлены
                return new Promise((resolve) => {
                    // Установка колбэков с выполнением запроса в очередь подписчиков на отправку
                    subscribeTokenRefresh((token) => {
                        // Параметры отправляемого запроса
                        const requestConfig = { ...config }
                        // Установка заголовка Authorization для экземпляра axios
                        axios.setAuthHeader(token)
                        // Устанавливаем маркер о том, что запрос проходит повторную отправку
                        requestConfig.headers.isRetryAttempt = true
                        // Ресолвим отправку запроса
                        resolve(axios.instance.request(requestConfig))
                    })
                })
            }

            //=== ЭСПОНЕНЦИАЛЬНОЕ ОТКЛАДЫВАНИЕ ===

            // Если статус ответа принадлежит статусам, требующим повторной отправки
            if ([408, 429, 502, 503, 504, 599].includes(status)) {
                // Порядковый номер попытки отправить запрос
                const retryAttemptNumber = config.headers.retryAttemptNumber
                    ? // в начале 1, затем итерируется на + 1
                      config.headers.retryAttemptNumber + 1
                    : 1
                // Если количество попыток отправки установлено как "0"(бесконечная отправка)
                // или порядковый номер попытки не стал больше заданного количества попыток отправки
                if (!retryCount || retryAttemptNumber <= retryCount) {
                    // Случайная величина "дрожания" в интервале от 0 до 1000(целое число)
                    const randomNumber = Math.round(Math.random() * 1000)
                    // Минимальный интервал повтора запроса(мс)
                    const minBackoff = 2 ** retryAttemptNumber * 1000
                    // Итоговое время повтора запроса(мс)
                    const retryTime = Math.min(minBackoff + randomNumber, maxBackoff + randomNumber)

                    // Возврат промиса с выполнением запроса
                    return new Promise((resolve) => {
                        // Выполнить запрос через заданное время
                        setTimeout(() => {
                            // Параметры отправляемого запроса
                            const requestConfig = { ...config }
                            // Установка заголовка порядкового номер попытки отправить запрос
                            requestConfig.headers.retryAttemptNumber = retryAttemptNumber
                            // Ресолвим отправку запроса
                            resolve(axios.instance.request(requestConfig))
                        }, retryTime)
                    })
                }
            }

            // Возврат реджекта ошибки промиса
            return Promise.reject(error)
        },
    )
}
