// Node
import { makeStyles } from '@mui/styles'
import { Theme, useTheme } from '@mui/material/styles'

// Конструктор стилей
const useStyleMui = () => {
    // Использовать текущую тему
    const theme: Theme = useTheme()
    return makeStyles(() => ({
        accordionSummary: {
            '& .MuiAccordionSummary-content': {
                margin: 0,
            },
        },
        semesterName: {
            color: theme.palette.primary.main,
            [theme.breakpoints.down('sm')]: {
                overflowWrap: 'anywhere',
            },
        },
        document: {
            paddingTop: '8px',
            [theme.breakpoints.down('sm')]: {
                overflowWrap: 'anywhere',
            },
        },
    }))()
}

// Экспорт конструктора стилей
export default useStyleMui
