// Node
import config from 'react-global-configuration'
// API
import axios from '@api/instance'
import { builder } from '@api/utils/builder'
// Types
import APITypes from '@api/types/api'

// Экспорт API уведомлений
export const notificationsAPI = {
    getUnreadCount() {
        return axios[config.get('api.notificationsUnreadCount.type') as APITypes.AxiosType]
            .get(config.get('api.notificationsUnreadCount.url'))
            .then((response: APITypes.AxiosResponse) => {
                return response.data
            })
            .catch(() => {
                return null
            })
    },
    getNotificationsStructure() {
        return axios[config.get('api.notificationsStructure.type') as APITypes.AxiosType]
            .get(config.get('api.notificationsStructure.url'))
            .then((response: APITypes.AxiosResponse) => {
                return response.data
            })
            .catch(() => {
                return null
            })
    },
    getNotificationsGroup(group = 1, page = config.get('api.notificationsGroup.pagination.currentPage')) {
        return axios[config.get('api.notificationsGroup.type') as APITypes.AxiosType]
            .get(builder('notificationsGroup').dataForGet(group, page))
            .then((response: APITypes.AxiosResponse) => {
                // Возврат ответа API
                return builder('notificationsGroup').dataForThen(response, page)
            })
            .catch(() => {
                return null
            })
    },
    getNotificationsItem(notificationId = 1) {
        return axios[config.get('api.notificationsItem.type') as APITypes.AxiosType]
            .get(config.get('api.notificationsItem.url').replace('{notificationId}', notificationId))
            .then((response: APITypes.AxiosResponse) => {
                // Возврат ответа API
                return response.data
            })
            .catch(() => {
                return null
            })
    },
    markNotificationsItemAsRead(notificationId: number) {
        const endpointName = 'notificationsMarkItemAsRead'
        const type: APITypes.AxiosType = config.get(`api.${endpointName}.type`)
        const method: APITypes.AxiosMethodType = config.get(`api.${endpointName}.method`)        
        const url = config.get(`api.${endpointName}.url`).replace('{notificationId}', notificationId)
        
        return axios[type as APITypes.AxiosType][method](url)
            .then((response: APITypes.AxiosResponse) => {
                // Возврат ответа API
                return response.data
            })
            .catch(() => {
                return null
            })
    },    
    markNotificationsGroupAsRead(body = {}) {
        const endpointName = 'notificationsMarkGroupAsRead'
        const type: APITypes.AxiosType = config.get(`api.${endpointName}.type`)
        const method: APITypes.AxiosMethodType = config.get(`api.${endpointName}.method`)        
        const url = config.get(`api.${endpointName}.url`)
        
        return axios[type as APITypes.AxiosType][method](url, body)
            .then((response: APITypes.AxiosResponse) => {
                // Возврат ответа API
                return response.data
            })
            .catch(() => {
                return null
            })
    },
    markNotificationsGroupAsUnread(body = {}) {
        const endpointName = 'notificationsMarkGroupAsUnread'
        const type: APITypes.AxiosType = config.get(`api.${endpointName}.type`)
        const method: APITypes.AxiosMethodType = config.get(`api.${endpointName}.method`)        
        const url = config.get(`api.${endpointName}.url`)
        
        return axios[type as APITypes.AxiosType][method](url, body)
            .then((response: APITypes.AxiosResponse) => {
                // Возврат ответа API
                return response.data
            })
            .catch(() => {
                return null
            })
    },
}
