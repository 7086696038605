import React from 'react'
// Node
import _ from 'lodash-contrib'
import { Link } from 'react-router-dom'
import { Theme, useTheme } from '@mui/material/styles'
import { Grid } from '@mui/material'
// Components
import Select from '@commonComponents/Controls/Select'
// Types
import EducationSessionReducerTypes from '@redux/reducers/types/education-session-reducer'
import EducationTabsBlockTypes from '../../types/EducationTabsBlock'
import EducationSessionTypes from './types/EducationSession'
// Own
import { GetSemesterMenuItems, GetDisciplineMenuItems, GetLessonTypeMenuItems } from './data/data.menu_items'
import EducationSemester from '../EducationSemester'

/*
 * Компонент "Результаты сессий"
 */

// Кешированный компонент "Семестр"
const MemoEducationSemester = React.memo(EducationSemester)

const EducationSession: React.FC<EducationTabsBlockTypes.MapStateToProps> = (props) => {
    // Преобразование пропсов в локальные константы
    const { session_semestersList, session_currentSemester, session_disciplinesList, session_currentDiscipline, session_lessonTypesList, session_currentLessonType, session_educationSession } =
        props
    
    // Использовать текущую тему
    const theme: Theme = useTheme()
    
    // Элементы меню выпадающего списка с семестрами
    const semesterMenuItems = GetSemesterMenuItems({ session_semestersList, session_currentSemester })

    // Элементы меню выпадающего списка с дисциплинами
    const disciplineMenuItems = GetDisciplineMenuItems({ session_disciplinesList, session_currentDiscipline })

    // Элементы меню выпадающего списка с видами занятий
    const lessonTypeMenuItems = GetLessonTypeMenuItems({ session_lessonTypesList, session_currentLessonType })

    // Контент семестров
    const semestersContent: Array<React.ReactNode> = []

    // Счетчик отфильтрованных видов занятий
    let filteredItemsCount = 0

    // Количество первых элементов без ленивой загрузки
    const unLazyItemsCount = 1

    // Отфильтрованные виды занятий
    session_educationSession?.map((semester: EducationSessionTypes.EduSessionSemester, indexA: number) => {
        // Если семестр соответствует фильтру
        if (session_currentSemester?.number === 0 || session_currentSemester?.number === semester?.number) {
            const filteredItems: Array<EducationSessionTypes.ModifiedDisciplineItem> = []
            filteredItemsCount = 0
            semester?.disciplines?.forEach((disciplineItem: EducationSessionTypes.EduSessionDiscipline, indexB: number) => {
                disciplineItem?.items?.forEach((item: EducationSessionTypes.EduSessionDisciplineItem) => {
                    // Если вид аттестации соответствует фильтру
                    if (
                        (session_currentLessonType?.number === 0 ||
                            session_currentLessonType?.name === item.kindLesson)
                        &&
                        (session_currentDiscipline?.number === 0 ||
                            session_currentDiscipline?.name === disciplineItem.name)
                    ) {
                        if (!filteredItems[indexB]) {
                            filteredItems[indexB] = []
                        }
                        const modifiedItem: EducationSessionTypes.ModifiedDisciplineItem = Object.assign({}, item)
                        // Добавление дополнительных полей для вывода
                        modifiedItem.examiner = (
                            <Link
                                to={{ pathname: item?.examinerPageUrl }}
                                target={'_blank'}
                                key={`examinerFio${indexB}`}
                            >
                                {item?.examinerFio}
                            </Link>
                        )
                        // Элемент с ленивой загрузкой
                        modifiedItem.isLazy =
                            filteredItemsCount < unLazyItemsCount ? false : true
                        // Добавление вида аттестации в отфильтрованный массив
                        filteredItems[indexB].push(modifiedItem)
                        // Увеличение счетчика отфильтрованных видов занятий
                        filteredItemsCount++
                    }
                })
            })

            // Контент семестров
            if (!_.isEmpty(filteredItems)) {
                semestersContent.push(
                    <MemoEducationSemester
                        semester={semester}
                        filteredItems={filteredItems}
                        disciplineType={'session'}
                        key={`eduSessionSemester_${indexA}`}
                    />,
                )
            }
        }
    })

    return (
        <>
            <Grid container>
                <Grid item xs={12} sx={{ 
                    '&': {  
                        [theme.breakpoints.down('sm')]: {
                            display: 'grid',                            
                            gridTemplateAreas: `
                                "a b"
                                "a b"`,                
                            gridTemplateColumns: '50%',                            
                        }
                    } 
                }}>
                    <Select
                        title={'Семестр'}
                        items={semesterMenuItems}
                        value={
                            session_currentSemester
                            ? session_semestersList?.find(
                                (semesterItem: EducationSessionReducerTypes.SemesterItem) =>
                                    semesterItem.number === session_currentSemester.number,
                            )?.name
                            : 'Список семестров'                            
                        }
                    />

                    <Select
                        title={'Дисциплина'}
                        items={disciplineMenuItems}
                        value={
                            session_currentDiscipline
                            ? session_disciplinesList?.find(
                                (disciplineItem: EducationSessionReducerTypes.DisciplineItem) =>
                                    disciplineItem.number === session_currentDiscipline.number,
                            )?.name
                            : 'Список дисциплин'                          
                        }
                    />
                    
                    <Select
                        title={'Вид аттестации'}
                        items={lessonTypeMenuItems}
                        value={
                            session_currentLessonType
                            ? session_lessonTypesList?.find(
                                (lessonTypeItem: EducationSessionReducerTypes.LessonTypeItem) =>
                                    lessonTypeItem.number === session_currentLessonType.number,
                            )?.name
                            : 'Список видов аттестаций'                          
                        }
                    />
                    
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    {semestersContent}
                </Grid>
            </Grid>
        </>
    )
}

// Экспорт компонента
export default EducationSession
