import React from 'react'
// Node
import clsx from 'clsx'
import { AppBar, Toolbar } from '@mui/material'
// Blocks
import LogoBlock from '@blocks/LogoBlock'
import TopMenuBlock from '@blocks/TopMenuBlock'
// Layouts
import { MainLayoutContext } from '@layouts/MainLayout'
// Types
import MainLayoutTypes from '@layouts/MainLayout/types/MainLayout'
// Own
import useStyleMui from './styles/style.mui'

/*
 * Область "Шапка"
 */

const HeaderArea: React.FC<{ drawerWidth: number }> = (props) => {
    const styleMui = useStyleMui(props)

    // Получение данных об открытии/закрытии панели меню из контекста MainLayoutContext
    const useMainLayoutContext = (): MainLayoutTypes.MainLayoutContext =>
        React.useContext(MainLayoutContext) as MainLayoutTypes.MainLayoutContext
    const { panelOpenContext } = useMainLayoutContext()

    return (
        <AppBar
            position="fixed"
            className={clsx(styleMui.appBar, {
                [styleMui.appBarShift]: panelOpenContext,
            })}
        >
            <Toolbar>
                <LogoBlock />
                <TopMenuBlock />
            </Toolbar>
        </AppBar>
    )
}

// Экспорт области
export default HeaderArea
