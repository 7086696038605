import React from 'react'
// Node
import { connect } from 'react-redux'
import { Box, Chip, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
// Components
import MaterialDialog from '@materialComponents/MaterialDialog'
// Types
import { AppStateType } from '@redux/store'
import NotificationsGroupPageTypes from '../NotificationsGroupPage/types/NotificationsGroupPage'
import NotificationsItemTypes from './types/NotificationsItem'
// Utils
import { fDate, fTime } from '@utils/formatTime'
// Redux
import { getNotificationsItem } from '@redux/reducers/notifications-reducer'
// Own
import { getBadgeColor } from '../../data/data.badge_color'
import NotificationsItemSkeleton from './skeleton'


/*
 * Компонент "Элемент одного уведомленя"
 */

const NotificationsItem: React.FC<
    NotificationsItemTypes.ComponentProps
    & NotificationsItemTypes.MapStateToProps     
    & NotificationsItemTypes.MapDispatchToProps> = (
    props,
) => {
    // Преобразование пропсов в локальные константы
    const { content, showModal, setShowModal, getNotificationsItem, loadingNotificationsItemComplete } = props
    
    // Тема MUI
    const theme = useTheme()
    
    // Хук эффекта
    React.useEffect(() => {
        if(showModal) {
            // Получение одного элемента уведомления
            getNotificationsItem(content?.id)
        }
    }, [showModal])
    
    return (
        <MaterialDialog
            title={content?.title}
            contentCaption={
                <>
                    <Box sx={{ color: 'text.disabled', display: 'inline-block', width: '100%' }}>             
                        <Typography sx={{ float: 'left', pt: '1rem', mb: '8px' }}>
                            { `${fDate(content?.date)} | ${fTime(content?.date)} | ${content?.category?.title}` }
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'inline-block', mb: '1rem', width: '100%' }}>             
                        { content?.tags?.map((tag: NotificationsGroupPageTypes.Tag) => (
                            <Chip 
                                key={`notificationsItemTag${tag?.name}`}
                                label={tag?.title}
                                sx={{ 
                                    backgroundColor: getBadgeColor(tag?.style),
                                    color: '#fff',
                                    mr: 1,
                                }}
                                size={'small'}
                            />
                        ))}
                    </Box>
                </>
            }
            content={
                loadingNotificationsItemComplete ? (
                    typeof content?.body === 'string' ? (
                        <span dangerouslySetInnerHTML={{ __html: content?.body || '' }} />
                    ) : (
                        content?.body
                    )
                ) : (
                    <NotificationsItemSkeleton width={'100%'} shift={100} count={20} />
                )
            }
            titleSX={{ 
                mr: 2, flex: 1, py: 1,
                ml: {xs: 1, sm: 0, md: 2},
                fontSize: {xs: '1rem', sm: '1.25rem'}, 
                lineHeight: {xs: '1.4', sm: '1.6'},                      
            }}
            contentSX={{ ml: 1, mr: 1, pt: 2, flex: 1 }}
            sx={{
                '& .MuiAppBar-root': {
                    backgroundColor: theme.palette.secondary.main,
                },
            }}
            showModal={showModal}
            onClose={() => setShowModal(false)}
        /> 
    )
}

// Маппинг стейта в пропсы страницы
const mapStateToProps = (state: AppStateType): NotificationsItemTypes.MapStateToProps => ({
    loadingNotificationsItemComplete: state.loadStore.loadingComplete?.notificationsItem,
})

// Экспорт компонента
export default connect(mapStateToProps, { getNotificationsItem })(NotificationsItem)
