// Node
import { Theme, useTheme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

// Конструктор стилей
const useStyleMui = () => {
    // Использовать текущую тему
    const theme: Theme = useTheme()

    const paginationButtonsMarginTop = '5px'
    
    return makeStyles(() => ({
        pagination: {
            '&': {
                marginLeft: '-15px',
                marginRight: '-15px',                
            },         
            '& .MuiPagination-ul': {
                [theme.breakpoints.down('sm')]: {
                    display: 'grid',
                    margin: 'auto',
                    width: 'fit-content',                                                
                    gridTemplateAreas: `
                        "control1 control2 . . . control3 control4"
                        "page1 page2 page3 page4 page5 page6 page7"`,                
                    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',                
                    '& li' : {                                                             
                        '&:nth-last-child(4)' : { gridArea: 'page6', marginTop: paginationButtonsMarginTop, },
                        '&:nth-last-child(3)' : { gridArea: 'page7', marginTop: paginationButtonsMarginTop, },

                        '&:nth-child(3)' : { gridArea: 'page1', marginTop: paginationButtonsMarginTop, },                    
                        '&:nth-child(4)' : { gridArea: 'page2', marginTop: paginationButtonsMarginTop, },                  
                        '&:nth-child(5)' : { gridArea: 'page3', marginTop: paginationButtonsMarginTop, },
                        '&:nth-child(6)' : { gridArea: 'page4', marginTop: paginationButtonsMarginTop, },
                        '&:nth-child(7)' : { gridArea: 'page5', marginTop: paginationButtonsMarginTop, },
                        '&:nth-child(8)' : { gridArea: 'page6', marginTop: paginationButtonsMarginTop, },

                        '&:nth-child(1)' : { gridArea: 'control1', },
                        '&:nth-child(2)' : { gridArea: 'control2', },                    
                        '&:nth-last-child(2)' : { gridArea: 'control3', },
                        '&:nth-last-child(1)' : { gridArea: 'control4', },                                        
                    }
                }
            },                   
        },       
    }))()
}

// Экспорт конструктора стилей
export default useStyleMui
