// Node
import { makeStyles } from '@mui/styles'
import { Theme, useTheme } from '@mui/material/styles'
// Types
import MaterialTabsTypes from '../types/MaterialTabs'

// Конструктор стилей
const useStyleMui = (props?: MaterialTabsTypes.ComponentProps) => {
    // Использовать текущую тему
    const theme: Theme = useTheme()
    const isVerticalTabs = props?.orientation === 'vertical'

    return makeStyles(() => ({
        tabsBox: {
            flexGrow: 1,
            bgcolor: 'background.paper',
            [theme.breakpoints.up('md')]: {
                display: isVerticalTabs ? 'flex' : 'block',
                height: 'max-content',
            },
            '& [role="tabpanel"]': {
                width: '100%',
            },
        },
        tabs: {
            borderRight: 1,
            borderColor: 'divider',
            maxWidth: props?.tabsWidth ? `${props?.tabsWidth}px` : 'unset',
            [theme.breakpoints.up('md')]: {
                minWidth: props?.tabsMinWidth ? `${props?.tabsMinWidth}px` : 'unset',
                minHeight: props?.tabsMinHeight ? `${props?.tabsMinHeight}px` : 'unset',
                position: props?.sticky ? 'sticky' : 'relative',
                top: props?.sticky ? '64px' : 'unset',
                height: 'max-content',
            },
        },
        panelBox: {
            [theme.breakpoints.up('xs')]: {
                padding: `${theme.spacing(3)} 0`,
            },
            [theme.breakpoints.up('md')]: {
                padding: isVerticalTabs ? `0 ${theme.spacing(3)}` : `${theme.spacing(3)} 0`,
            },
        },
    }))()
}

// Экспорт конструктора стилей
export default useStyleMui
