import React from 'react'
// Node
import { Box, Skeleton } from '@mui/material'
import config from 'react-global-configuration'

/*
 * Скелетон списка обучений страницы "Мое обучение"
 */

// Анимация скелетона
const animation = config.get('skeleton.animation')

// Массив данных для отображения скелетона
const EducationListSkeleton = {
    tabLabel: <Skeleton variant="rectangular" width={70} height={30} animation={animation} />,
    listItem: (
        <Box sx={{ mb: 2 }} >    
            <Skeleton variant="rectangular" width={'100%'} height={60} animation={animation} />
        </Box>
    )
}

// Экспорт скелетона
export default EducationListSkeleton
