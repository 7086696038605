// Node
import { makeStyles } from '@mui/styles'
// Types
import MaterialCardTypes from '../types/MaterialCard'

// Объявление интерфейса IComponentProps для доступа к его свойствам
declare const IComponentProps: MaterialCardTypes.ComponentProps

// Конструктор стилей
const useStyleMui = (props: typeof IComponentProps.cardImageStyle) => {
    return makeStyles(() => ({
        cardMedia: props?.cardImageStyle,
    }))()
}

// Экспорт конструктора стилей
export default useStyleMui
