// Types
import FillStatusBlockTypes from '../types/FillStatusBlock'

// Экспорт данных чек-листа
export const useCheckListData = (
    checklist: FillStatusBlockTypes.MapStateToProps['checklist'],
    onClick: FillStatusBlockTypes.ComponentProps['onClick'],
    handleClick: (message: string) => void,
) => {
    // Маппинг кода элемента на другие данные чек-листа
    const appCodeMapping = [
        {
            appCode: 'PHOTO',
            onClick: () => handleClick('Для изменения фотографии перейдите в пункт меню "Обо мне"'),
        },
        {
            appCode: 'PROFILE',
            onClick: () =>
                handleClick('Для изменения данных профиля перейдите в пункт меню "Обо мне"'),
        },
        { appCode: 'IDENTITY_DOC', onClick: () => onClick(1) },
        { appCode: 'DOCUMENT_FILES', onClick: () => onClick(9) },
        { appCode: 'GRADUATION', onClick: () => onClick(2) },
        { appCode: 'DOCUMENT_FILES', onClick: () => onClick(9) },
        { appCode: 'DOCUMENTAL_ADDRESS', onClick: () => onClick(3) },
        { appCode: 'GRADES', onClick: () => onClick(5) },
        { appCode: 'SPECIALITIES', onClick: () => onClick(6) },
        { appCode: 'ACTUAL_ADDRESS', onClick: () => onClick(3) },
        { appCode: 'PRIVILEGES', onClick: () => onClick(7) },
        { appCode: 'DOCUMENT_FILES', onClick: () => onClick(9) },
        { appCode: 'ACHIEVEMENTS', onClick: () => onClick(8) },
        { appCode: 'DOCUMENT_FILES', onClick: () => onClick(9) },
    ]

    const newCheckList: FillStatusBlockTypes.MapStateToProps['checklist'] = []
    checklist?.forEach((listItem: FillStatusBlockTypes.ChecklistItem) => {
        let onClickValue = null
        appCodeMapping?.forEach((mapping) => {
            if (listItem.appCode === mapping.appCode) {
                onClickValue = mapping.onClick
            }
        })
        newCheckList.push({ ...listItem, onClick: onClickValue })
    })

    // Возврат модифицированного чек-листа
    return newCheckList
}
