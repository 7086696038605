import React from 'react'
// Node
import { Box, List, ListItem } from '@mui/material'
// Types
import EducationPlanDisciplineTypes from '../types/EducationPlanDiscipline'

// Заголовок дисциплины
export const getDisciplineHeader = (props: 
    {styleMui: {[param: string]: any}} & EducationPlanDisciplineTypes.ComponentProps
) => {
// Преобразование пропсов в локальные константы
const { disciplineType, filteredItems, styleMui, } = props
    switch (disciplineType) {
        case 'plan':
            return <>                    
                <Box className={styleMui.caption} component={'span'}>
                    Кафедра
                </Box>
                <Box className={styleMui.captionText} component={'span'}>
                    {filteredItems[0].kafedraName}
                </Box>                    
            </>
            break
        case 'session':
            return <>
                <Box className={styleMui.caption} component={'span'}>
                    Вид аттестации
                </Box>
            </>
            break
    }
}

// Контент дисциплины
export const getDisciplineContent = (props: 
    {styleMui: {[param: string]: any}, contentWidth: number | null, isUpSM: boolean}
    & EducationPlanDisciplineTypes.ComponentProps
) => {
// Преобразование пропсов в локальные константы
const { disciplineType, filteredItems, styleMui, contentWidth, isUpSM, } = props
    switch (disciplineType) {
        case 'plan':
            return <>                    
                <Box className={styleMui.caption} component={'span'}>
                    Виды занятий
                </Box>
                <List className={styleMui.list} sx={{maxWidth: isUpSM ? 'fit-content' : Number(contentWidth) - 50}}>
                    {filteredItems?.map((item: EducationPlanDisciplineTypes.ComponentProps['filteredItems'], index: number) => (
                        <ListItem className={styleMui.listItem} key={`filteredListItem_${index}`}>
                            <Box className={`${styleMui.listItemCaption} _grey`} component={'span'}>{item?.name}</Box>
                            <Box component={'span'}>{item?.hours}</Box>
                        </ListItem>
                    ))}
                </List>                    
            </>
            break
        case 'session':
            return <>
                <List className={styleMui.list} sx={{mt: -1, maxWidth: isUpSM ? 'fit-content' : Number(contentWidth) - 50}}>
                    {filteredItems?.map((item: EducationPlanDisciplineTypes.ComponentProps['filteredItems'], index: number) => (
                        <>
                            <ListItem className={styleMui.listItem} key={`filteredListItem_${index}`}>
                                <Box className={`${styleMui.listItemCaption} _grey`} component={'span'}>{item?.kindLesson}</Box>
                            </ListItem>
                            <ListItem className={`${styleMui.listItem} ${styleMui.listItemShift}`} key={`filteredListItem_${index}`}>
                                <Box className={styleMui.listItemCaption} component={'span'}>преподаватель: </Box>
                                <Box className={`${styleMui.listItemCaption} ${styleMui.listItemValue}`} component={'span'}>{item?.examiner}</Box>
                                <Box className={styleMui.listItemCaption} component={'span'}>оценка: </Box>
                                <Box component={'span'}>{item?.mark}</Box>
                            </ListItem>
                        </>
                    ))}
                </List>
            </>
            break
    }
}
