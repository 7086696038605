// Node
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import config from 'react-global-configuration'
import _ from 'lodash-contrib'
// API
import { newsAPI } from '@api/endpoints/newsAPI'
// Types
import NewsReducerTypes from '@redux/reducers/types/news-reducer'
import { AppDispatchType } from '@redux/store'
import NewsAPITypes from '@api/endpoints/newsAPI/types/newsAPI'
import { AxiosInstanceType } from '@src/config/endpoints/real'
// Создатели действий пагинации
import { setCurrentPage, setItemsPerPage, setTotalItems } from './pagination-reducer'
// Создатели действий загрузки
import { setLoading, setLoaded } from './load-reducer'

// Начальное состояние
const initialState: NewsReducerTypes.InitialState = {
    // Массив новостей
    news: [],
}

// Слайс новостей
const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        // Установить список новостей
        setNews(
            state: NewsReducerTypes.InitialState,
            action: PayloadAction<NewsReducerTypes.InitialState['news']>,
        ) {
            state.news = action.payload
        },
        // Установить одну новость
        setNewsItem(
            state: NewsReducerTypes.InitialState,
            action: PayloadAction<NewsReducerTypes.NewsItem>,
        ) {
            if (action.payload && typeof action.payload['id'] === 'number') {
                // Индекс одной новости в массиве новостей(ищется по id)
                const findedIndex: number = _.findIndex(
                    state.news,
                    (obj: NewsReducerTypes.NewsItem) => obj.id === action.payload.id,
                )
                // Обновление свойств новости
                for (const key in action.payload) {
                    state.news[findedIndex][key] = action.payload[key]
                }
            }
        },
    },
})

// Экспорт создателей действий
export const { setNews, setNewsItem } = newsSlice.actions
// Экспорт редьюсера
export default newsSlice.reducer

//THUNK CREATORS

// Получение новостей
export const getNews = (page = config.get('api.news.pagination.currentPage')) => {
    return (dispatch: AppDispatchType) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading('news'))

        // Получение данных через API новостей
        newsAPI.getNews(page).then((response: NewsAPITypes.NewsList) => {
            // Установить новости
            dispatch(setNews(response?.content))
                        
            // Установить текущую страницу пагинации
            dispatch(setCurrentPage(['news', response?.number]))
            // Если работает настроенный экземпляр axios(реальные данные)
            if (config.get('api.news.type') === AxiosInstanceType) {
                // Установить количество элементов на странице
                dispatch(setItemsPerPage(['news', response?.size]))
                // Установить общее количество элементов
                dispatch(setTotalItems(['news', response?.totalElements]))
            }
            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded('news'))
        })
    }
}

// Получение одной новости
export const getNewsItem = (page: NewsReducerTypes.NewsItem['id']) => {
    return (dispatch: AppDispatchType) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading('newsItem'))

        // Получение данных через API новостей
        newsAPI.getNewsItem(page).then((response: NewsAPITypes.NewsListItem) => {
            // Установить новости
            dispatch(setNewsItem(response))
            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded('newsItem'))
        })
    }
}
