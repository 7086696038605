/* tslint:disable */
// generated by typescript-json-validator
import { inspect } from 'util'
import Ajv from 'ajv'
import AdmissionStatusView from './AdmissionStatusView.d'
export const ajv = new Ajv({
    allErrors: true,
    coerceTypes: false,
    format: 'fast',
    nullable: true,
    unicode: true,
    uniqueItems: true,
    useDefaults: true,
})

ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'))

export { AdmissionStatusView }
export const AdmissionStatusViewSchema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    defaultProperties: [],
    definitions: {
        'AdmissionAPITypes.ChecklistItem': {
            defaultProperties: [],
            properties: {
                appCode: {
                    enum: [
                        'ACHIEVEMENTS',
                        'ACTUAL_ADDRESS',
                        'BIRTH_DOCUMENT',
                        'DOCUMENTAL_ADDRESS',
                        'DOCUMENT_FILES',
                        'GRADES',
                        'GRADUATION',
                        'IDENTITY_DOC',
                        'LANGUAGE_SKILLS',
                        'PHOTO',
                        'PRIVILEGES',
                        'PROFILE',
                        'RELATIVES',
                        'SIGNED_DOCUMENT_FILES',
                        'SPECIALITIES',
                    ],
                    type: 'string',
                },
                changeDate: {
                    type: ['null', 'string'],
                },
                detail: {
                    type: ['null', 'string'],
                },
                mandatory: {
                    type: 'boolean',
                },
                name: {
                    type: 'string',
                },
                needAction: {
                    type: 'boolean',
                },
                needCheck: {
                    type: 'boolean',
                },
                sortingIndex: {},
                status: {
                    enum: ['FAILED', 'IN_PROGRESS', 'PASSED'],
                    type: 'string',
                },
                statusText: {
                    type: 'string',
                },
            },
            required: [
                'appCode',
                'changeDate',
                'detail',
                'mandatory',
                'name',
                'needAction',
                'needCheck',
                'sortingIndex',
                'status',
                'statusText',
            ],
            type: 'object',
        },
        'AdmissionAPITypes.EntrantStatus': {
            defaultProperties: [],
            properties: {
                checkComment: {
                    type: ['null', 'string'],
                },
                code: {
                    type: 'string',
                },
                date: {
                    type: ['null', 'string'],
                },
                maySendForCheck: {
                    type: 'boolean',
                },
                mayTakeForEdit: {
                    type: 'boolean',
                },
                text: {
                    type: 'string',
                },
            },
            required: ['checkComment', 'code', 'date', 'maySendForCheck', 'mayTakeForEdit', 'text'],
            type: 'object',
        },
    },
    properties: {
        checklist: {
            items: {
                $ref: '#/definitions/AdmissionAPITypes.ChecklistItem',
            },
            type: 'array',
        },
        helpMessage: {
            type: 'string',
        },
        status: {
            $ref: '#/definitions/AdmissionAPITypes.EntrantStatus',
        },
    },
    required: ['checklist', 'helpMessage', 'status'],
    type: 'object',
}
export type ValidateFunction<T> = ((data: unknown) => data is T) &
    Pick<Ajv.ValidateFunction, 'errors'>
export const isAdmissionStatusView = ajv.compile(
    AdmissionStatusViewSchema,
) as ValidateFunction<AdmissionStatusView>
export default function validate(value: unknown): AdmissionStatusView {
    if (isAdmissionStatusView(value)) {
        return value
    } else {
        throw new Error(
            ajv.errorsText(
                isAdmissionStatusView.errors!.filter((e: any) => e.keyword !== 'if'),
                { dataVar: 'AdmissionStatusView' },
            ) +
                '\n\n' +
                inspect(value),
        )
    }
}
