import React from 'react'
// Node
import config from 'react-global-configuration'
import { connect } from 'react-redux'
import { Grid, Pagination } from '@mui/material'
// Redux
import { getNews } from '@redux/reducers/news-reducer'
// Types
import { AppStateType } from '@redux/store'
import NewsPageTypes from './types/NewsPage'
// Own
import NewsCardBlock from './blocks/NewsCardBlock'
import useStyleMui from './styles/style.mui'
import NewsPageSkeleton from './skeleton'

/*
 * Страница "Новости"
 */

const NewsPage: React.FC<NewsPageTypes.MapStateToProps & NewsPageTypes.MapDispatchToProps> = (
    props,
) => {
    // Преобразование пропсов в локальные константы
    const { news, currentPage, itemsPerPage, totalItems, loadingNewsComplete, getNews } = props
    
    const styleMui = useStyleMui()
    
    // Количество страниц пагинации
    const pagesCount = Math.ceil(totalItems / itemsPerPage)

    // Установка страницы, по которой был произведен клик(аналог currentPage)
    // clickedPage будет доступно мгновенно при клике на пагинацию, вто время как
    // currentPage приходит из стейта с задержкой
    const [clickedPage, setClickedPage] = React.useState(currentPage)

    // Маркер 1-й новости
    let latestCardLarge = false
    // Маркер 2-й и 3-й новости
    let latestCard = false

    // Установка маркеров начальных новостей
    const setLatestCards = (index: number) => {
        // Индекс карточки новости с учетом пагинации(влияет на вид карточки)
        const newsCardIndex = index + (clickedPage - 1) * itemsPerPage

        // Маркер 1-й новости
        latestCardLarge = newsCardIndex === 0
        // Маркер 2-й и 3-й новости
        latestCard = newsCardIndex === 1 || newsCardIndex === 2
    }

    // Контент скелетона
    const skeletonContent: Array<React.ReactElement> = []

    // Задать контент скелетона
    const setSkeletonContent = () => {
        for (let index = 0; index < itemsPerPage; index++) {
            // Установка маркеров начальных новостей
            setLatestCards(index)

            skeletonContent.push(
                <NewsPageSkeleton
                    key={`NewsPageSkeleton_${index}`}
                    latestCard={latestCard}
                    latestCardLarge={latestCardLarge}
                />,
            )
        }
    }
    setSkeletonContent()

    // Хук эффекта
    React.useEffect(() => {
        // Получить массив новостей
        getNews()
        // Установка страницы, по которой был произведен клик
        setClickedPage(config.get('api.news.pagination.currentPage'))
    }, [])

    // Событие изменения страницы
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        // Получить массив новостей с номером страницы
        getNews(value)
        // Установка страницы, по которой был произведен клик
        setClickedPage(value)
    }

    return (
        <>
            <Pagination
                className={styleMui.pagination}
                sx={{ mx: 'auto', mb: '1.5rem' }}
                count={pagesCount}
                page={currentPage}
                onChange={handlePageChange}
                showFirstButton
                showLastButton                
            />
            <Grid container spacing={3}>
                {loadingNewsComplete
                    ? news?.map((data, index) => {
                          // Установка маркеров начальных новостей
                          setLatestCards(index)

                          return (
                              <NewsCardBlock
                                  key={`NewsCardBlock_${index}`}
                                  latestCard={latestCard}
                                  latestCardLarge={latestCardLarge}
                                  index={index}
                                  data={data}
                              />
                          )
                      })
                    : skeletonContent}
            </Grid>
            <Pagination
                className={styleMui.pagination}
                sx={{ mx: 'auto', mt: '1.5rem' }}
                count={pagesCount}
                page={currentPage}
                onChange={handlePageChange}
                showFirstButton
                showLastButton
            />
        </>
    )
}

// Маппинг стейта в пропсы страницы
const mapStateToProps = (state: AppStateType): NewsPageTypes.MapStateToProps => ({
    news: state.newsStore?.news,
    currentPage: state.paginationStore?.news?.currentPage,
    itemsPerPage: state.paginationStore?.news?.itemsPerPage,
    totalItems: state.paginationStore?.news?.totalItems,
    loadingNewsComplete: state.loadStore.loadingComplete?.news,
})

// Экспорт страницы
export default connect(mapStateToProps, { getNews })(NewsPage)
