/* tslint:disable */
// generated by typescript-json-validator
import { inspect } from 'util'
import Ajv from 'ajv'
import AdmissionListView from './AdmissionListView.d'
export const ajv = new Ajv({
    allErrors: true,
    coerceTypes: false,
    format: 'fast',
    nullable: true,
    unicode: true,
    uniqueItems: true,
    useDefaults: true,
})

ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'))

export { AdmissionListView }
export const AdmissionListViewSchema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    defaultProperties: [],
    definitions: {
        'AdmissionAPITypes.AdmissionExam': {
            defaultProperties: [],
            properties: {
                disciplineName: {
                    type: 'string',
                },
                examKind: {
                    type: ['null', 'string'],
                },
                id: {
                    type: ['null', 'string', 'number'],
                },
                mark: {
                    type: 'string',
                },
            },
            required: ['disciplineName', 'examKind', 'id', 'mark'],
            type: 'object',
        },
        'AdmissionAPITypes.AdmissionListItem': {
            defaultProperties: [],
            properties: {
                abbrEgroup: {
                    type: 'string',
                },
                active: {
                    type: 'boolean',
                },
                apprenticeship: {
                    type: 'string',
                },
                ddelivery: {
                    type: ['null', 'string'],
                },
                eduIdentifier: {
                    type: 'string',
                },
                eduWhenGiven: {
                    type: 'string',
                },
                entIdentifier: {
                    type: 'string',
                },
                entStatusColor: {
                    type: ['null', 'string'],
                },
                entStatusText: {
                    type: 'string',
                },
                exams: {
                    items: {
                        $ref: '#/definitions/AdmissionAPITypes.AdmissionExam',
                    },
                    type: 'array',
                },
                idEntrant: {
                    type: ['string', 'number'],
                },
                idParagraph: {
                    type: ['string', 'number'],
                },
                institute: {
                    type: 'string',
                },
                instituteUrl: {
                    type: ['null', 'number'],
                },
                isActiveInt: {
                    type: ['string', 'number'],
                },
                isOriginalText: {
                    type: 'string',
                },
                kafedra: {
                    type: 'string',
                },
                kafedraUrl: {
                    type: ['null', 'string'],
                },
                klfCurrent: {
                    type: 'string',
                },
                klfName: {
                    type: 'string',
                },
                kpName: {
                    type: ['null', 'string'],
                },
                krName: {
                    type: 'string',
                },
                ktName: {
                    type: 'string',
                },
                needInHostelText: {
                    type: 'string',
                },
                onDate: {
                    type: 'string',
                },
                preferenceIndex: {
                    type: ['string', 'number'],
                },
                price: {
                    type: ['null', 'string'],
                },
                qname: {
                    type: ['null', 'string'],
                },
                ratingUrl: {
                    type: ['null', 'string'],
                },
                representative: {
                    type: ['null', 'string'],
                },
                specName: {
                    type: 'string',
                },
                specQualifier: {
                    type: 'string',
                },
                specializationName: {
                    type: ['null', 'string'],
                },
                status: {
                    type: 'string',
                },
                urlReportContract: {
                    type: ['null', 'string'],
                },
                urlReportEntrantAgreement: {
                    type: ['null', 'string'],
                },
                urlReportEntrantSubmission: {
                    type: 'string',
                },
                urlReportGrades: {
                    type: 'string',
                },
                urlReportPersdataAgreement: {
                    type: 'string',
                },
                year: {
                    type: 'string',
                },
            },
            required: [
                'abbrEgroup',
                'active',
                'apprenticeship',
                'ddelivery',
                'eduIdentifier',
                'eduWhenGiven',
                'entIdentifier',
                'entStatusColor',
                'entStatusText',
                'exams',
                'idEntrant',
                'idParagraph',
                'institute',
                'instituteUrl',
                'isActiveInt',
                'isOriginalText',
                'kafedra',
                'kafedraUrl',
                'klfCurrent',
                'klfName',
                'kpName',
                'krName',
                'ktName',
                'needInHostelText',
                'onDate',
                'preferenceIndex',
                'price',
                'qname',
                'ratingUrl',
                'representative',
                'specName',
                'specQualifier',
                'specializationName',
                'status',
                'urlReportContract',
                'urlReportEntrantAgreement',
                'urlReportEntrantSubmission',
                'urlReportGrades',
                'urlReportPersdataAgreement',
                'year',
            ],
            type: 'object',
        },
    },
    properties: {
        admissions: {
            items: {
                $ref: '#/definitions/AdmissionAPITypes.AdmissionListItem',
            },
            type: 'array',
        },
        helpMessage: {
            type: 'string',
        },
        helpMessageExtended: {
            type: ['null', 'string'],
        },
        submitButtonText: {
            type: 'string',
        },
    },
    required: ['admissions', 'helpMessage', 'submitButtonText'],
    type: 'object',
}
export type ValidateFunction<T> = ((data: unknown) => data is T) &
    Pick<Ajv.ValidateFunction, 'errors'>
export const isAdmissionListView = ajv.compile(
    AdmissionListViewSchema,
) as ValidateFunction<AdmissionListView>
export default function validate(value: unknown): AdmissionListView {
    if (isAdmissionListView(value)) {
        return value
    } else {
        throw new Error(
            ajv.errorsText(
                isAdmissionListView.errors!.filter((e: any) => e.keyword !== 'if'),
                { dataVar: 'AdmissionListView' },
            ) +
                '\n\n' +
                inspect(value),
        )
    }
}
