// Node
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// API
import { educationAPI } from '@api/endpoints/educationAPI'
// Types
import EducationAPITypes from '@api/endpoints/educationAPI/types/educationAPI'
import EducationSessionReducerTypes from '@redux/reducers/types/education-session-reducer'
import EducationListReducerTypes from '@redux/reducers/types/education-list-reducer'
import { AppDispatchType } from '@redux/store'
import APITypes from '@api/types/api'
// Создатели действий загрузки
import { setLoading, setLoaded } from './load-reducer'

// Объект "Выбрать все"
const selectAllObject = { name: '- ВСЕ -', number: 0 }

// Начальное состояние
const initialState: EducationSessionReducerTypes.InitialState = {
    // Список семестров
    semestersList: [],
    // Текущий семестр
    currentSemester: selectAllObject,
    // Список дисциплин
    disciplinesList: [],
    // Текущая дисциплина
    currentDiscipline: selectAllObject,
    // Список видов аттестаций
    lessonTypesList: [],
    // Текущий вид аттестации
    currentLessonType: selectAllObject,
    // Результаты сессий
    educationSession: [],
}

// Слайс результатов сессий
const educationSessionSlice = createSlice({
    name: 'educationSession',
    initialState,
    reducers: {
        // Установить список семестров
        setSemestersList(
            state: EducationSessionReducerTypes.InitialState,
            action: PayloadAction<EducationSessionReducerTypes.InitialState['semestersList']>,
        ) {
            state.semestersList = action.payload
        },
        // Установить текущий семестр
        setСurrentSemester(
            state: EducationSessionReducerTypes.InitialState,
            action: PayloadAction<EducationSessionReducerTypes.InitialState['currentSemester']>,
        ) {
            state.currentSemester = action.payload
        },
        // Установить список дисциплин
        setDisciplinesList(
            state: EducationSessionReducerTypes.InitialState,
            action: PayloadAction<EducationSessionReducerTypes.InitialState['disciplinesList']>,
        ) {
            state.disciplinesList = action.payload
        },
        // Установить текущую дисциплину
        setСurrentDiscipline(
            state: EducationSessionReducerTypes.InitialState,
            action: PayloadAction<EducationSessionReducerTypes.InitialState['currentDiscipline']>,
        ) {
            state.currentDiscipline = action.payload
        },
        // Установить список видов аттестаций
        setLessonTypesList(
            state: EducationSessionReducerTypes.InitialState,
            action: PayloadAction<EducationSessionReducerTypes.InitialState['lessonTypesList']>,
        ) {
            state.lessonTypesList = action.payload
        },
        // Установить текущий вид аттестации
        setCurrentLessonType(
            state: EducationSessionReducerTypes.InitialState,
            action: PayloadAction<EducationSessionReducerTypes.InitialState['currentLessonType']>,
        ) {
            state.currentLessonType = action.payload
        },
        // Установить результаты сессий
        setEducationSession(
            state: EducationSessionReducerTypes.InitialState,
            action: PayloadAction<EducationSessionReducerTypes.InitialState['educationSession']>,
        ) {
            state.educationSession = action.payload
        },
    },
})

// Экспорт создателей действий
export const {
    setSemestersList,
    setСurrentSemester,
    setDisciplinesList,
    setСurrentDiscipline,
    setLessonTypesList,
    setCurrentLessonType,
    setEducationSession,
} = educationSessionSlice.actions
// Экспорт редьюсера
export default educationSessionSlice.reducer

//THUNK CREATORS

// Получение данных списка результатов сессий
export const getEducationSession = (
    idStudent: EducationListReducerTypes.EducationItem['educationId'],
) => {
    return (dispatch: AppDispatchType) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading('educationSession'))
        // Получение данных через API плана обучения
        educationAPI.getEducationSession(idStudent).then((response: APITypes.AxiosResponse) => {
            // Установить данные списка результатов сессий
            dispatch(setEducationSession(response))

            // Список семестров
            const semestersList: EducationSessionReducerTypes.InitialState['semestersList'] = []
            semestersList.push(selectAllObject)
            response?.forEach((semesterItem: EducationSessionReducerTypes.SemesterItem) => {
                semestersList.push({ name: semesterItem?.name, number: semesterItem?.number })
            })
            // Установить список семестров
            dispatch(
                setSemestersList(semestersList.sort((prev, next) => prev?.number - next?.number)),
            )

            // Неотсортированный список дисциплин (массив названий)
            const unsortedDisciplinesList: Array<EducationAPITypes.EduSessionDiscipline['name']> =
                []
            // Неотсортированный список видов аттестаций (массив названий)
            const unsortedLessonTypesList: Array<EducationAPITypes.SessionItem['kindLesson']> = []
            response?.forEach((semesterItem: EducationAPITypes.EduSessionSemester) => {
                semesterItem?.disciplines?.forEach(
                    (disciplineItem: EducationAPITypes.EduSessionDiscipline) => {
                        unsortedDisciplinesList.push(disciplineItem?.name)
                        disciplineItem?.items?.forEach((item: EducationAPITypes.SessionItem) => {
                            unsortedLessonTypesList.push(item?.kindLesson)
                        })
                    },
                )
            })

            // Отсортированный список дисциплин (массив объектов)
            const disciplinesList: EducationSessionReducerTypes.InitialState['disciplinesList'] = [
                selectAllObject,
            ].concat(
                unsortedDisciplinesList
                    // Сортировка дисциплин по алфавиту
                    .sort()
                    // Удаление повторов
                    .reduce(
                        (
                            result: Array<EducationAPITypes.EduSessionDiscipline['name']>,
                            item: EducationAPITypes.EduSessionDiscipline['name'],
                        ) => {
                            return result.includes(item) ? result : [...result, item]
                        },
                        [],
                    )
                    // Преобразование каждогй дисциплины к объекту
                    .map(
                        (
                            item: EducationSessionReducerTypes.DisciplineItem['name'],
                            index: number,
                        ) => ({
                            name: item,
                            number: index + 1,
                        }),
                    ),
            )

            // Отсортированный список видов аттестаций (массив объектов)
            const lessonTypesList: EducationSessionReducerTypes.InitialState['lessonTypesList'] = [
                selectAllObject,
            ].concat(
                unsortedLessonTypesList
                    // Сортировка видов аттестаций по алфавиту
                    .sort()
                    // Удаление повторов
                    .reduce(
                        (
                            result: Array<EducationAPITypes.SessionItem['kindLesson']>,
                            item: EducationAPITypes.SessionItem['kindLesson'],
                        ) => {
                            return result.includes(item) ? result : [...result, item]
                        },
                        [],
                    )
                    // Преобразование каждого вида аттестации к объекту
                    .map(
                        (
                            item: EducationSessionReducerTypes.LessonTypeItem['name'],
                            index: number,
                        ) => ({
                            name: item,
                            number: index + 1,
                        }),
                    ),
            )

            // Установить список видов аттестаций
            dispatch(setDisciplinesList(disciplinesList))

            // Установить список видов аттестаций
            dispatch(setLessonTypesList(lessonTypesList))

            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded('educationSession'))
        })
    }
}
