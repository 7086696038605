// Types
import APITypes from '@api/types/api'

// Экспорт типа исходного axios без настроек
export const AxiosOriginalType: APITypes.AxiosType = 'original'

// Объект с mock-эндпоинтами
export const mockEndpoints: ConfigTypes.EndpointRecord = {       
    // MENU
    menu: {
        url: 'mock/menu/employee-menu.json',
        method: 'get',
        type: AxiosOriginalType,
    },
    // ABOUT
    auth: {
        url: 'mock/profile/profile.json',
        method: 'get',
        type: AxiosOriginalType, 
    },    
    accounts: {
        url: 'mock/accounts/accounts.json',
        method: 'get',
        type: AxiosOriginalType,
    },    
    // NEWS
    news: {
        url: 'mock/news/news.json',
        method: 'get',
        type: AxiosOriginalType,
        pagination: {
            currentPage: 1,
            itemsPerPage: 11,
            totalItems: 23,
        },
    },
    newsItem: {
        url: 'mock/news/news-item',
        method: 'get',
        type: AxiosOriginalType,
    },
    // ADMISSION
    admissionList: {
        url: 'mock/admission/admission-list.json',
        method: 'get',
        type: AxiosOriginalType,
    },
    admissionStatus: {
        url: 'mock/admission/admission-status.json',
        method: 'get',
        type: AxiosOriginalType,
    },
    // EDUCATION
    educationList: {
        url: 'mock/education/education-list.json',
        method: 'get',
        type: AxiosOriginalType,
    },
    educationItem: {
        url: 'mock/education/education-item/{idStudentStart}.json',
        method: 'get',
        type: AxiosOriginalType,
    },
    educationPlan: {
        url: 'mock/education/education-plan.json',
        method: 'get',
        type: AxiosOriginalType,
    },
    educationSession: {
        url: 'mock/education/education-session.json',
        method: 'get',
        type: AxiosOriginalType,
    },
    educationPortfolio: {
        url: 'mock/education/education-portfolio.json',
        method: 'get',
        type: AxiosOriginalType,
    },
    educationPortfolioComments: {
        url: 'mock/education/education-portfolio-comment/{idPortfolioItem}.json',
        method: 'get',
        type: AxiosOriginalType,
    },
    // NOTIFICATIONS
    notificationsStructure: {
        url: 'mock/notifications/notifications-structure.json',
        method: 'get',
        type: AxiosOriginalType,
    },
    notificationsGroup: {
        url: 'mock/notifications/notifications-group/{groupId}.json',
        method: 'get',
        type: AxiosOriginalType,
        pagination: {
            currentPage: 1,
            itemsPerPage: 5,
            totalItems: 13,
        },
    },
    notificationsItem: {
        url: 'mock/notifications/notifications-item/{notificationId}.json', 
        method: 'get', 
        type: AxiosOriginalType,         
    },        
    notificationsUnreadCount: {
        url: 'mock/notifications/notifications-unread-count.json', 
        method: 'get', 
        type: AxiosOriginalType,        
    },
    notificationsMarkItemAsRead: {
        url: '',
        method: 'post', 
        type: AxiosOriginalType,         
    },
    notificationsMarkGroupAsRead: {
        url: '',
        method: 'post', 
        type: AxiosOriginalType,         
    },                
    notificationsMarkGroupAsUnread: {
        url: '',
        method: 'post', 
        type: AxiosOriginalType,         
    },    
}
