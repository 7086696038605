import React from 'react'
// Node
import { CssBaseline, useMediaQuery, useTheme } from '@mui/material'
// Areas
import ContentArea from '@areas/ContentArea'
import HeaderArea from '@areas/HeaderArea'
import LeftMenuArea from '@areas/LeftMenuArea'
// Types
import MainLayoutTypes from './types/MainLayout'
// Own
import useStyleMui from './styles/style.mui'

/*
 * Шаблон "Главный"
 */

// Экспорт контекста
export const MainLayoutContext = React.createContext<MainLayoutTypes.MainLayoutContext | null>(null)

const MainLayout = () => {
    const styleMui = useStyleMui()
    // Ширина левой панели меню
    const drawerWidth = 300

    // Тема MUI
    const theme = useTheme()
    // Раскрыта ли боковая панель меню(в зависимости от значения брекпойнтов темы MUI)
    const isPanelOpen = useMediaQuery(theme.breakpoints.up('lg'))

    // Локальное состояние открытия/закрытия боковой панели меню
    const [panelOpenContext, setPanelOpenContext] = React.useState(isPanelOpen)

    // Хук эффекта(привязан к изменению значения isPanelOpen)
    React.useEffect(() => {
        // Задать значение isPanelOpen(открытия/закрытия боковой панели меню)
        setPanelOpenContext(isPanelOpen)
    }, [isPanelOpen])

    return (
        <div className={styleMui.root}>
            <CssBaseline />
            <MainLayoutContext.Provider value={{ panelOpenContext, setPanelOpenContext }}>
                <HeaderArea drawerWidth={drawerWidth} />
                <LeftMenuArea drawerWidth={drawerWidth} />
                <ContentArea />
            </MainLayoutContext.Provider>
        </div>
    )
}

// Экспорт шаблона
export default MainLayout
