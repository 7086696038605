// Node
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// Types
import WindowParamsReducerTypes from '@redux/reducers/types/window-params-reducer'

// Начальное состояние
const initialState: WindowParamsReducerTypes.InitialState = {
    // Ширина окна
    windowWidth: null,
    // Ширина контента
    contentWidth: null,
    // Маркер того, является ли разрешение экрана больше брекпоинта 'sm'
    isUpSM: true,    
}

// Слайс параметров окна
const windowParamsSlice = createSlice({
    name: 'windowParams',
    initialState,
    reducers: {
        // Установить ширину окна
        setWindowWidth(
            state: WindowParamsReducerTypes.InitialState,
            action: PayloadAction<WindowParamsReducerTypes.InitialState['windowWidth']>,
        ) {
            state.windowWidth = action.payload
        },
        // Установить ширину контента
        setContentWidth(
            state: WindowParamsReducerTypes.InitialState,
            action: PayloadAction<WindowParamsReducerTypes.InitialState['contentWidth']>,
        ) {
            state.contentWidth = action.payload
        },
        // Установить маркер того, является ли разрешение экрана больше брекпоинта 'sm'
        setIsUpSM(
            state: WindowParamsReducerTypes.InitialState,
            action: PayloadAction<WindowParamsReducerTypes.InitialState['isUpSM']>,
        ) {
            state.isUpSM = action.payload
        },        
    },
})

// Экспорт создателей действий
export const { setWindowWidth, setContentWidth, setIsUpSM, } = windowParamsSlice.actions
// Экспорт редьюсера
export default windowParamsSlice.reducer
