import React from 'react'
// Node
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles'
// Components
import MaterialTabs from '@materialComponents/MaterialTabs'
// Redux
import { getEducationItem } from '@redux/reducers/education-list-reducer'
import { getEducationPlan } from '@redux/reducers/education-plan-reducer'
import { getEducationSession } from '@redux/reducers/education-session-reducer'
import { getEducationPortfolio } from '@redux/reducers/education-portfolio-reducer'
// Types
import { AppStateType } from '@redux/store'
import EducationTabsBlockTypes from './types/EducationTabsBlock'
// Own
import { EducationItem, EducationPlan, EducationPortfolio, EducationSession } from './components'
import EducationListSkeleton from '../../skeleton'

/*
 * Блок "Вкладки страницы "Мое обучение"
 */

const EducationTabsBlock: React.FC<
    EducationTabsBlockTypes.ComponentProps &
    EducationTabsBlockTypes.MapStateToProps &
    EducationTabsBlockTypes.MapDispatchToProps
> = (props) => {
    // Преобразование пропсов в локальные константы
    const {
        currentEducation,
        activeTabIndex,
        setActiveTabIndex,
        educationItem,
        loadingEducationItemComplete,
        getEducationItem,
        plan_semestersList,
        plan_currentSemester,
        plan_lessonTypesList,
        plan_currentLessonType,
        plan_educationPlan,
        getEducationPlan,
        session_semestersList,
        session_currentSemester,
        session_disciplinesList,
        session_currentDiscipline,
        session_lessonTypesList,
        session_currentLessonType,
        session_educationSession,
        getEducationSession,
        educationPortfolio,
        getEducationPortfolio,
    } = props
    
    // Тема MUI
    const theme = useTheme()
    
    // Хук эффекта
    React.useEffect(() => {
        if (currentEducation) {
            // Получение данных плана обучения
            getEducationItem(currentEducation.educationId)
            if (currentEducation?.showEduPlan) {
                // Получение данных дисциплин
                getEducationPlan(currentEducation.idEducationalPlan)
            }
            if (currentEducation?.showEduWork) {
                // Получение данных результатов сессий
                getEducationSession(currentEducation.educationId)
            }
            if (currentEducation?.showPortfolio) {
                // Получение данных портфолио
                getEducationPortfolio(currentEducation.educationId)
            }
        }
    }, [currentEducation])

    const educationTabs: Array<{label: React.ReactNode, content: React.ReactNode}>= []
    educationTabs.push({
        label: 'Учебный план',
        content: <EducationItem 
            educationItem={educationItem} 
            loadingEducationItemComplete={loadingEducationItemComplete} 
        />,
    })
    if (currentEducation?.showEduPlan) {
        educationTabs.push({
            label: 'Дисциплины',
            content: (
                <EducationPlan
                    plan_semestersList={plan_semestersList}
                    plan_currentSemester={plan_currentSemester}
                    plan_lessonTypesList={plan_lessonTypesList}
                    plan_currentLessonType={plan_currentLessonType}
                    plan_educationPlan={plan_educationPlan}
                />
            ),
        })
    }
    if (currentEducation?.showEduWork) {
        educationTabs.push({
            label: 'Результаты сессий',
            content: (
                <EducationSession
                    session_semestersList={session_semestersList}
                    session_currentSemester={session_currentSemester}
                    session_disciplinesList={session_disciplinesList}
                    session_currentDiscipline={session_currentDiscipline}
                    session_lessonTypesList={session_lessonTypesList}
                    session_currentLessonType={session_currentLessonType}
                    session_educationSession={session_educationSession}
                />
            ),
        })
    }
    if (currentEducation?.showPortfolio) {
        educationTabs.push({
            label: 'Портфолио',
            content: (
                <EducationPortfolio
                    educationPortfolio={educationPortfolio}
                />
            )
        })
    }
    
    if (!loadingEducationItemComplete) {
        educationTabs.forEach( (tab: any, index: number) => {
            educationTabs[index].label = EducationListSkeleton?.tabLabel
        })        
    }

    // Ширина контента
    const contentWidth = useSelector((state: AppStateType) => state.windowParamsStore?.contentWidth)

    // Маркер того, является ли разрешение экрана больше брекпоинта 'sm'
    const isUpSM = useSelector((state: AppStateType) => state.windowParamsStore?.isUpSM)
    
    return (
        <MaterialTabs
            tabs={educationTabs}
            tabsWidth={contentWidth}
            variant={'scrollable'}
            activeTabIndex={activeTabIndex}
            onClick={(newIndex) => setActiveTabIndex(newIndex)}
            tabStyle={{minWidth: 'fit-content'}}
            tabsListStyle={isUpSM ? {} : {marginLeft: '-15px'}}
            tabBoxStyle={isUpSM ? {} : {
                'h6': {fontSize: '1.1rem', lineHeight: '1.3'},
                '& > .MuiTypography-root': {mt: `${theme.spacing(1)} !important`},
            }}            
        />
    )
}

// Маппинг стейта в пропсы блока
const mapStateToProps = (state: AppStateType): EducationTabsBlockTypes.MapStateToProps => ({
    educationItem: state.educationListStore?.educationItem,
    loadingEducationItemComplete: state.loadStore.loadingComplete?.educationItem,

    plan_semestersList: state.educationPlanStore?.semestersList,
    plan_currentSemester: state.educationPlanStore?.currentSemester,
    plan_lessonTypesList: state.educationPlanStore?.lessonTypesList,
    plan_currentLessonType: state.educationPlanStore?.currentLessonType,
    plan_educationPlan: state.educationPlanStore?.educationPlan,

    session_semestersList: state.educationSessionStore?.semestersList,
    session_currentSemester: state.educationSessionStore?.currentSemester,
    session_disciplinesList: state.educationSessionStore?.disciplinesList,
    session_currentDiscipline: state.educationSessionStore?.currentDiscipline,
    session_lessonTypesList: state.educationSessionStore?.lessonTypesList,
    session_currentLessonType: state.educationSessionStore?.currentLessonType,
    session_educationSession: state.educationSessionStore?.educationSession,

    educationPortfolio: state.educationPortfolioStore?.educationPortfolio,
})

// Экспорт блока
export default connect(mapStateToProps, {
    getEducationItem, getEducationPlan, getEducationSession, getEducationPortfolio,
})(EducationTabsBlock)
