// Node
import { replace } from 'lodash'
import numeral from 'numeral'

/*
 * Методы обработки чисел
 */

// Разряды, отделенные запятой
export const fNumber = (number: number | string) => {
    return numeral(number).format()
}
// Сокращение тысячных разрядов буквенным обозначением
export const fShortenNumber = (number: number | string) => {
    return replace(numeral(number).format('0.00a'), '.00', '')
}
