// Node
import { useDispatch } from 'react-redux'
// Redux
import {
    setСurrentSemester,
    setСurrentDiscipline,
    setCurrentLessonType,
} from '@redux/reducers/education-session-reducer'

// Элементы меню выпадающего списка с обучениями
export const GetSemesterMenuItems = (props) => {
    // Преобразование пропсов в локальные константы
    const { session_semestersList, session_currentSemester } = props

    // Определение метода dispatch
    const dispatch = useDispatch()

    // Проход по всем семестрам
    return session_semestersList?.map((semesterItem) => ({
        name: semesterItem?.name,
        selected: session_currentSemester?.number === semesterItem?.number,
        onClick: () => {
            dispatch(setСurrentSemester(semesterItem))
        },
    }))
}

// Элементы меню выпадающего списка с дисциплинами
export const GetDisciplineMenuItems = (props) => {
    // Преобразование пропсов в локальные константы
    const { session_disciplinesList, session_currentDiscipline } = props

    // Определение метода dispatch
    const dispatch = useDispatch()

    // Проход по всем дисциплинам
    return session_disciplinesList?.map((disciplineItem) => ({
        name: disciplineItem?.name,
        selected: session_currentDiscipline?.number === disciplineItem?.number,
        onClick: () => {
            dispatch(setСurrentDiscipline(disciplineItem))
        },
    }))
}

// Элементы меню выпадающего списка с видами занятий
export const GetLessonTypeMenuItems = (props) => {
    // Преобразование пропсов в локальные константы
    const { session_lessonTypesList, session_currentLessonType } = props

    // Определение метода dispatch
    const dispatch = useDispatch()

    // Проход по всем видам занятий
    return session_lessonTypesList?.map((lessonTypeItem) => ({
        name: lessonTypeItem?.name,
        selected: session_currentLessonType?.number === lessonTypeItem?.number,
        onClick: () => {
            dispatch(setCurrentLessonType(lessonTypeItem))
        },
    }))
}
