import React from 'react'
// Node
import { Grid, Skeleton } from '@mui/material'
import config from 'react-global-configuration'

/*
 * Скелетон блока "Статус поступления"
 */

// Компонент скелетона
const FillStatusBlockSkeleton: React.FC<{ textCount?: number; stepsCount?: number }> = (props) => {
    // Преобразование пропсов в локальные константы
    const { textCount, stepsCount } = props

    // Анимация скелетона
    const animation = config.get('skeleton.animation')
    // Контент скелетона
    const content = []

    for (let i = 0; i < (textCount ? textCount : 2); i++) {
        content.push(
            <div key={`FillStatusBlockSkeletonText_${i}`}>
                <Skeleton variant="text" width={'70%'} animation={animation} />
                <Skeleton variant="text" width={'30%'} animation={animation} />
                <Skeleton variant="text" width={'50%'} animation={animation} />
            </div>,
        )
    }

    content.push(
        <div key={`FillStatusBlockSkeletonCaption`}>
            <br />
            <Skeleton variant="text" width={'35%'} animation={animation} />
            <br />
        </div>,
    )

    for (let i = 0; i < (stepsCount ? stepsCount : 4); i++) {
        content.push(
            <div key={`FillStatusBlockSkeletonSteps_${i}`}>
                <Grid container>
                    <Grid item xs={2}>
                        <Skeleton variant="circular" width={25} height={25} animation={animation} />
                    </Grid>
                    <Grid item xs={10}>
                        <Skeleton variant="rectangular" width={100} animation={animation} />
                    </Grid>
                </Grid>
                <br />
            </div>,
        )
    }

    return <> {content} </>
}

// Экспорт скелетона
export default FillStatusBlockSkeleton
