// Node
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// API
import { educationAPI } from '@api/endpoints/educationAPI'
// Types
import EducationAPITypes from '@api/endpoints/educationAPI/types/educationAPI'
import EducationPlanReducerTypes from '@redux/reducers/types/education-plan-reducer'
import EducationListReducerTypes from '@redux/reducers/types/education-list-reducer'
import { AppDispatchType } from '@redux/store'
import APITypes from '@api/types/api'
// Создатели действий загрузки
import { setLoading, setLoaded } from './load-reducer'

// Объект "Выбрать все"
const selectAllObject = { name: '- ВСЕ -', number: 0 }

// Начальное состояние
const initialState: EducationPlanReducerTypes.InitialState = {
    // Список семестров
    semestersList: [],
    // Текущий семестр
    currentSemester: selectAllObject,
    // Список видов занятий
    lessonTypesList: [],
    // Текущий вид занятий
    currentLessonType: selectAllObject,
    // Дисциплины
    educationPlan: [],
}

// Слайс дисциплин
const educationPlanSlice = createSlice({
    name: 'educationPlan',
    initialState,
    reducers: {
        // Установить список семестров
        setSemestersList(
            state: EducationPlanReducerTypes.InitialState,
            action: PayloadAction<EducationPlanReducerTypes.InitialState['semestersList']>,
        ) {
            state.semestersList = action.payload
        },
        // Установить текущий семестр
        setСurrentSemester(
            state: EducationPlanReducerTypes.InitialState,
            action: PayloadAction<EducationPlanReducerTypes.InitialState['currentSemester']>,
        ) {
            state.currentSemester = action.payload
        },
        // Установить список видов занятий
        setLessonTypesList(
            state: EducationPlanReducerTypes.InitialState,
            action: PayloadAction<EducationPlanReducerTypes.InitialState['lessonTypesList']>,
        ) {
            state.lessonTypesList = action.payload
        },
        // Установить текущий вид занятий
        setCurrentLessonType(
            state: EducationPlanReducerTypes.InitialState,
            action: PayloadAction<EducationPlanReducerTypes.InitialState['currentLessonType']>,
        ) {
            state.currentLessonType = action.payload
        },
        // Установить дисциплины
        setEducationPlan(
            state: EducationPlanReducerTypes.InitialState,
            action: PayloadAction<EducationPlanReducerTypes.InitialState['educationPlan']>,
        ) {
            state.educationPlan = action.payload
        },
    },
})

// Экспорт создателей действий
export const {
    setSemestersList,
    setСurrentSemester,
    setLessonTypesList,
    setCurrentLessonType,
    setEducationPlan,
} = educationPlanSlice.actions
// Экспорт редьюсера
export default educationPlanSlice.reducer

//THUNK CREATORS

// Получение данных списка дисциплин
export const getEducationPlan = (
    idEduPlan: EducationListReducerTypes.EducationItem['idEducationalPlan'],
) => {
    return (dispatch: AppDispatchType) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading('educationPlan'))
        // Получение данных через API плана обучения
        educationAPI.getEducationPlan(idEduPlan).then((response: APITypes.AxiosResponse) => {
            // Установить данные списка дисциплин
            dispatch(setEducationPlan(response))

            // Список семестров
            const semestersList: EducationPlanReducerTypes.InitialState['semestersList'] = []
            semestersList.push(selectAllObject)
            response?.forEach((semesterItem: EducationPlanReducerTypes.SemesterItem) => {
                semestersList.push({ name: semesterItem?.name, number: semesterItem?.number })
            })
            // Установить список семестров
            dispatch(
                setSemestersList(semestersList.sort((prev, next) => prev?.number - next?.number)),
            )

            // Неотсортированный список видов занятий (массив названий)
            const unsortedLessonTypesList: Array<EducationAPITypes.EduPlanDisciplineItem['name']> =
                []
            response?.forEach((lessonTypeItem: EducationAPITypes.EduPlanSemester) => {
                lessonTypeItem?.disciplines?.forEach(
                    (disciplineItem: EducationAPITypes.EduPlanDiscipline) => {
                        disciplineItem?.itemGroups?.forEach(
                            (itemGroupItem: EducationAPITypes.EduPlanDisciplineItemGroup) => {
                                itemGroupItem?.items?.forEach(
                                    (item: EducationAPITypes.EduPlanDisciplineItem) => {
                                        unsortedLessonTypesList.push(item?.name)
                                    },
                                )
                            },
                        )
                    },
                )
            })

            // Отсортированный список видов занятий (массив объектов)
            const lessonTypesList: EducationPlanReducerTypes.InitialState['lessonTypesList'] = [
                selectAllObject,
            ].concat(
                unsortedLessonTypesList
                    // Сортировка видов занятий по алфавиту
                    .sort()
                    // Удаление повторов
                    .reduce(
                        (
                            result: Array<EducationAPITypes.EduPlanDisciplineItem['name']>,
                            item: EducationAPITypes.EduPlanDisciplineItem['name'],
                        ) => {
                            return result.includes(item) ? result : [...result, item]
                        },
                        [],
                    )
                    // Преобразование каждого вида занятия к объекту
                    .map(
                        (
                            item: EducationPlanReducerTypes.LessonTypeItem['name'],
                            index: number,
                        ) => ({
                            name: item,
                            number: index + 1,
                        }),
                    ),
            )

            // Установить список видов занятий
            dispatch(setLessonTypesList(lessonTypesList))

            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded('educationPlan'))
        })
    }
}
