import React from 'react'
// Node
import { Box } from '@mui/material'
// Types
import SvgIconStyleTypes from './types/SvgIconStyle'

/*
 * Стилизованный компонент "Подложка иконки"
 */

const SvgIconStyle = ({ src, color = 'inherit', sx }: SvgIconStyleTypes.ComponentProps) => {
    return (
        <Box
            component="span"
            sx={{
                width: 24,
                height: 24,
                mask: `url(${src}) no-repeat center / contain`,
                WebkitMask: `url(${src}) no-repeat center / contain`,
                bgcolor: `${color}.main`,
                ...(color === 'inherit' && { bgcolor: 'currentColor' }),
                ...(color === 'action' && { bgcolor: 'action.active' }),
                ...(color === 'disabled' && { bgcolor: 'action.disabled' }),
                ...(color === 'paper' && { bgcolor: 'background.paper' }),
                ...sx,
            }}
        />
    )
}

// Экспорт компонента
export default SvgIconStyle
