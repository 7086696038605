import React from 'react'
// Node
import { Skeleton } from '@mui/material'
import config from 'react-global-configuration'
// Types
import MaterialListTypes from '@materialComponents/MaterialList/types/MaterialList'

/*
 * Скелетон страницы "Обо мне"
 */

// Количество элементов списка
const listItemCount = 10

// Анимация скелетона
const animation = config.get('skeleton.animation')

// Массив данных для отображения скелетона
const AboutPageSkeleton = {
    listItems: [] as MaterialListTypes.ComponentProps['listItems'],
    userName: <Skeleton variant="text" width={215} animation={animation} />,
    userPhoto: <Skeleton variant="rectangular" width={100} height={133} animation={animation} />,
    userButton: <Skeleton variant="rectangular" width={215} height={35} animation={animation} />,
    accountsTabs: {
        label: <Skeleton variant="rectangular" width={70} height={30} animation={animation} />,
        content: <Skeleton variant="rectangular" height={100} animation={animation} />,
    },
}

// Формирование массивов данных для отображения скелетона
// Проход по элементам списка
for (let i = 0; i < listItemCount; i++) {
    AboutPageSkeleton.listItems?.push({
        primary: <Skeleton variant="text" width={160} animation={animation} />,
        secondary: <Skeleton variant="text" width={70} animation={animation} />,
        avatar: <Skeleton variant="circular" width={40} height={40} animation={animation} />,
    })
}

// Экспорт скелетона
export default AboutPageSkeleton
