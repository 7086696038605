// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nEdgXyq40iaHAvlpAzAx { \r\n    background: #fff;\r\n    height: 100%;\r\n    position: relative;    \r\n    width: 100%;    \r\n    z-index: 9999;    \r\n}\r\n.nEdgXyq40iaHAvlpAzAx > div {   \r\n    left: 0;\r\n    margin: auto;        \r\n    position: fixed;    \r\n    right: 0;    \r\n    top: 0%;\r\n}", "",{"version":3,"sources":["webpack://./src/app/components/material/MaterialProgress/styles/style.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,aAAa;AACjB;AACA;IACI,OAAO;IACP,YAAY;IACZ,eAAe;IACf,QAAQ;IACR,OAAO;AACX","sourcesContent":[".progressWrapper { \r\n    background: #fff;\r\n    height: 100%;\r\n    position: relative;    \r\n    width: 100%;    \r\n    z-index: 9999;    \r\n}\r\n.progressWrapper > div {   \r\n    left: 0;\r\n    margin: auto;        \r\n    position: fixed;    \r\n    right: 0;    \r\n    top: 0%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressWrapper": "nEdgXyq40iaHAvlpAzAx"
};
export default ___CSS_LOADER_EXPORT___;
