// Node
import config from 'react-global-configuration'
import pagination from 'paginatejson'
// Types
import NewsAPITypes from '@api/endpoints/newsAPI/types/newsAPI'
// Own
import { newsBuilderMapper } from './mapper'

export const newsBuilderOriginal = {
    dataForGet(page: number) {
        page // - для EsLint 'no-unused-vars'
        return config.get('api.news.url')
    },
    dataForThen(response: APITypes.AxiosResponse, page: number): NewsAPITypes.NewsList {
        return {
            // Пакет "paginatejson" выдает фрагмент mock-файла в формате "json"
            content: pagination.paginate(
                response.data?.content,
                page,
                config.get('api.news.pagination.itemsPerPage'),
            )?.items,
            // Текущая страница
            number: page,
            // Дополнение "пустыми" данными для полноты типа
            size: 0,
            totalElements: 0,
        }
    },
    dataMapping(response: NewsAPITypes.NewsList) {
        return newsBuilderMapper.dataMapping(response)
    },
}
