import React from 'react'
// Node
import { Avatar } from '@mui/material'
// Types
import MaterialAvatarTypes from './types/MaterialAvatar'
// Own
import useStyleMui, { useStyledBadge } from './styles/style.mui'

/*
 * Компонент "Аватар" (обертка над MaterialUI)
 */

// Экспорт компонента
export const MaterialAvatar: React.FC<MaterialAvatarTypes.ComponentProps> = (props) => {
    // Преобразование пропсов в локальные константы
    const { isOnline, userImg, userName, anchorOrigin } = props

    const styleMui = useStyleMui()
    const StyledBadge = useStyledBadge(props)

    return (
        <div className={styleMui.root}>
            <StyledBadge
                overlap="circular"
                anchorOrigin={anchorOrigin}
                variant={isOnline ? 'dot' : 'standard'}
            >
                <Avatar alt="" src={userImg}>
                    {userName}
                </Avatar>
            </StyledBadge>
        </div>
    )
}

// Свойства по умолчанию
MaterialAvatar.defaultProps = {
    isOnline: false,
    userImg: '',
    userName: '',
    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    primary: false,
    secondary: false,
}

// Экспорт компонента
export default MaterialAvatar
