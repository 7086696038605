import React from 'react'
// Areas
import AuthArea from '@areas/AuthArea'

/*
 * Шаблон "Аутентификация"
 */

const AuthLayout = () => {
    return <AuthArea />
}

// Экспорт шаблона
export default AuthLayout
