// Node
import config from 'react-global-configuration'
// API
import axios from '@api/instance'
import { builder } from '@api/utils/builder'
import { validator } from '@api/utils/validator'
// Types
import APITypes from '@api/types/api'
import EducationAPITypes from './types/educationAPI'

// Экспорт API обучения
export const educationAPI = {
    getEducationList() {
        return axios[config.get('api.educationList.type') as APITypes.AxiosType]
            .get(config.get('api.educationList.url'))
            .then((response: APITypes.AxiosResponse) => {
                // Возврат ответа API, пропущенный через маппер
                return builder('educationList').dataMapping(response.data)
            })
            .catch(() => {
                return null
            })
    },
    getEducationItem(idStudentStart: EducationAPITypes.EducationListItem['idStudentStart']) {
        return axios[config.get('api.educationItem.type') as APITypes.AxiosType]
            .get(config.get('api.educationItem.url').replace('{idStudentStart}', idStudentStart))
            .then((response: APITypes.AxiosResponse) => {
                // Возврат ответа API, пропущенный через валидатор
                return validator.validate(
                    response.data,
                    'educationAPI', // директория эндпоинта
                    'EducationItem', // тип валидатора
                )
            })
            .catch(() => {
                return null
            })
    },
    getEducationPlan(idEduPlan: EducationAPITypes.EducationListItem['idEducationalPlan']) {
        return axios[config.get('api.educationPlan.type') as APITypes.AxiosType]
            .get(config.get('api.educationPlan.url').replace('{idEduPlan}', idEduPlan))
            .then((response: APITypes.AxiosResponse) => {
                // Возврат ответа API
                return response.data
            })
            .catch(() => {
                return null
            })
    },
    getEducationSession(idStudent: EducationAPITypes.EducationListItem['idStudentStart']) {
        return axios[config.get('api.educationSession.type') as APITypes.AxiosType]
            .get(config.get('api.educationSession.url').replace('{idStudent}', idStudent))
            .then((response: APITypes.AxiosResponse) => {
                // Возврат ответа API
                return response.data
            })
            .catch(() => {
                return null
            })
    },
    getEducationPortfolio(idStudent: EducationAPITypes.EducationListItem['idStudentStart']) {
        return axios[config.get('api.educationPortfolio.type') as APITypes.AxiosType]
            .get(config.get('api.educationPortfolio.url').replace('{idStudent}', idStudent))
            .then((response: APITypes.AxiosResponse) => {
                // Возврат ответа API
                return response.data
            })
            .catch(() => {
                return null
            })
    },
    getEducationPortfolioComments(
        idPortfolioItem: EducationAPITypes.PortfolioItem['idPortfolioItem'],
    ) {
        return axios[config.get('api.educationPortfolioComments.type') as APITypes.AxiosType]
            .get(
                config
                    .get('api.educationPortfolioComments.url')
                    .replace('{idPortfolioItem}', idPortfolioItem),
            )
            .then((response: APITypes.AxiosResponse) => {
                // Возврат ответа API
                return response.data
            })
            .catch(() => {
                return null
            })
    },
}
