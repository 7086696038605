import React from 'react'
// Node
import { connect } from 'react-redux'
import { Box, LinearProgress } from '@mui/material'
// Types
import { AppStateType } from '@redux/store'
import MaterialProgressTypes from './types/MaterialProgress'
// Own
import styleMod from './styles/style.module.css'

/*
 * Компонент "Прогресс" (обертка над MaterialUI)
 */

// Экспорт компонента
export const MaterialProgress: React.FC<MaterialProgressTypes.ComponentProps> = ({
    color,
    variant,
    loadingAppComplete,
}) => {
    return (
        <>
            {
                // Отрисовка прогресса только если загрузка в процессе
                loadingAppComplete === false ? (
                    <div className={styleMod.progressWrapper}>
                        <LinearProgress variant={variant} color={color} />
                    </div>
                ) : (
                    <Box sx={{ width: '100%' }}></Box>
                )
            }
        </>
    )
}

// Свойства по умолчанию
MaterialProgress.defaultProps = {
    color: 'primary',
    variant: 'indeterminate',
}

// Маппинг стейта в пропсы компонента
const mapStateToProps = (state: AppStateType): MaterialProgressTypes.MapStateToProps => ({
    loadingAppComplete: state.loadStore.loadingComplete.app,
})

// Экспорт контейнера компонента
export default connect(mapStateToProps)(MaterialProgress)
