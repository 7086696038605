// Node
import config from 'react-global-configuration'
import pagination from 'paginatejson'
// Types
import NotificationsAPITypes from '@api/endpoints/notificationsAPI/types/notificationsAPI'

export const notificationsGroupBuilderOriginal = {
    dataForGet(group: number, page: number) {
        page // - для EsLint 'no-unused-vars'
        return config.get('api.notificationsGroup.url').replace('{groupId}', group)
    },
    dataForThen(response: APITypes.AxiosResponse, page: number): NotificationsAPITypes.NotificationGroup {
        return {
            ...response.data,
            notificationsPage: {
                ...response.data?.notificationsPage,
                // Пакет "paginatejson" выдает фрагмент mock-файла в формате "json"
                content: pagination.paginate(
                    response.data?.notificationsPage?.content,
                    page,
                    response.data?.notificationsPage?.size,
                )?.items,
                // Текущая страница
                number: page,                
            }
        }
    },
}
