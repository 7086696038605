// Node
import { makeStyles } from '@mui/styles'
import { Theme, useTheme } from '@mui/material/styles'

// Конструктор стилей
const useStyleMui = () => {
    // Использовать текущую тему
    const theme: Theme = useTheme()
    return makeStyles(() => ({
        disciplineName: {
            marginBottom: '1rem',
            [theme.breakpoints.down('sm')]: {
                color: theme.palette.secondary.main,                
                fontSize: '1.2rem',
                overflowWrap: 'anywhere',
            },            
        },
        contentBlock: {
            marginBottom: '1rem',
            marginLeft: '3rem',
            [theme.breakpoints.down('sm')]: {                
                marginLeft: 0,
            },
        },
        caption: {
            fontWeight: 600,
            marginRight: '0.4rem',
        },
        captionText: {
            '& a': {
                color: theme.palette.primary.main,
            },
        },
        list: {
            paddingTop: 0,
        },
        listItem: {
            paddingBottom: 0,
            paddingLeft: 0,
            [theme.breakpoints.down('sm')]: {                
                marginLeft: 0,
            },            
        },
        listItemShift: {
            marginLeft: '3rem',
            [theme.breakpoints.down('sm')]: {                
                marginLeft: 0,
                display: 'grid',                            
                gridTemplateAreas: `"a" "a"`,                          
            },            
        },        
        listItemCaption: {
            marginRight: '0.4rem',
            '&._grey': {
                color: theme.palette.text.disabled,
            },
        },
        listItemValue: {            
            [theme.breakpoints.down('sm')]: {              
                overflowWrap: 'break-word',
            },            
        },
    }))()
}

// Экспорт конструктора стилей
export default useStyleMui
