import React from 'react'
// Node
import { connect } from 'react-redux'
import { Typography, Snackbar } from '@mui/material'
// Components
import MaterialStepper from '@materialComponents/MaterialStepper'
// Redux
import { getAdmissionStatus } from '@redux/reducers/admission-status-reducer'
// Types
import { AppStateType } from '@redux/store'
import FillStatusBlockTypes from './types/FillStatusBlock'
// Own
import { useCheckListData } from './data/data.check_list'
import FillStatusBlockSkeleton from './skeleton'

/*
 * Блок "Статус поступления"
 */

const FillStatusBlock: React.FC<
    FillStatusBlockTypes.ComponentProps &
        FillStatusBlockTypes.MapStateToProps &
        FillStatusBlockTypes.MapDispatchToProps
> = (props) => {
    // Преобразование пропсов в локальные константы
    const { helpMessage, loadingAadmissionStatusComplete, getAdmissionStatus, onClick } = props

    let checklist = props.checklist

    // Хук эффекта
    React.useEffect(() => {
        // Получение данных статуса поступления
        getAdmissionStatus()
    }, [])

    const [isSnackbarOpen, setSnackbarOpen] = React.useState(false)
    const [snackbarMessage, setSnackbarMessage] = React.useState('')

    const handleSnackbarClick = (message: string) => {
        setSnackbarOpen(true)
        setSnackbarMessage(message)
    }

    const handleSnackbarClose = () => {
        setSnackbarOpen(false)
    }

    // Модификация данных чек-листа
    checklist = useCheckListData(checklist, onClick, handleSnackbarClick)

    return (
        <>
            {loadingAadmissionStatusComplete ? (
                <>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={isSnackbarOpen}
                        onClose={handleSnackbarClose}
                        message={snackbarMessage}
                        key={'top center'}
                    />
                    <Typography paragraph>
                        {typeof helpMessage === 'string' ? (
                            <span dangerouslySetInnerHTML={{ __html: helpMessage || '' }} />
                        ) : (
                            helpMessage
                        )}
                    </Typography>
                    <Typography variant="h6">Необходимые пункты для подачи заявления</Typography>
                    <MaterialStepper steps={checklist?.filter((item) => item?.mandatory)} />
                    <br />
                    <Typography variant="h6">
                        Вы можете указать дополнительную информацию
                    </Typography>
                    <MaterialStepper steps={checklist?.filter((item) => !item?.mandatory)} />
                </>
            ) : (
                <FillStatusBlockSkeleton />
            )}
        </>
    )
}

// Маппинг стейта в пропсы блока
const mapStateToProps = (state: AppStateType): FillStatusBlockTypes.MapStateToProps => ({
    checklist: state.admissionStatusStore?.checklist,
    helpMessage: state.admissionStatusStore?.helpMessage,
    loadingAadmissionStatusComplete: state.loadStore.loadingComplete?.admissionStatus,
})

// Экспорт блока
export default connect(mapStateToProps, { getAdmissionStatus })(FillStatusBlock)
