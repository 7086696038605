import React from 'react'
// Node
import { Theme, useTheme } from '@mui/material/styles'
import _ from 'lodash-contrib'
import { Link } from 'react-router-dom'
import { Grid } from '@mui/material'
// Components
import Select from '@commonComponents/Controls/Select'
// Types
import EducationPlanReducerTypes from '@redux/reducers/types/education-plan-reducer'
import EducationTabsBlockTypes from '../../types/EducationTabsBlock'
import EducationPlanTypes from './types/EducationPlan'
// Own
import { GetSemesterMenuItems, GetLessonTypeMenuItems } from './data/data.menu_items'
import EducationSemester from '../EducationSemester'

/*
 * Компонент "Дисциплины"
 */

// Кешированный компонент "Семестр"
const MemoEducationSemester = React.memo(EducationSemester)

const EducationPlan: React.FC<EducationTabsBlockTypes.MapStateToProps> = (props) => {
    // Преобразование пропсов в локальные константы
    const { plan_semestersList, plan_currentSemester, plan_lessonTypesList, plan_currentLessonType, plan_educationPlan } =
        props
    
    // Использовать текущую тему
    const theme: Theme = useTheme()
    
    // Элементы меню выпадающего списка с семестрами
    const semesterMenuItems = GetSemesterMenuItems({ plan_semestersList, plan_currentSemester })

    // Элементы меню выпадающего списка с видами занятий
    const lessonTypeMenuItems = GetLessonTypeMenuItems({ plan_lessonTypesList, plan_currentLessonType })

    // Контент семестров
    const semestersContent: Array<React.ReactNode> = []

    // Счетчик отфильтрованных видов занятий
    let filteredItemsCount = 0

    // Количество первых элементов без ленивой загрузки
    const unLazyItemsCount = 1

    // Отфильтрованные виды занятий
    plan_educationPlan?.map((semester: EducationPlanTypes.EduPlanSemester, indexA: number) => {
        // Если семестр соответствует фильтру
        if (plan_currentSemester?.number === 0 || plan_currentSemester?.number === semester?.number) {
            const filteredItems: Array<EducationPlanTypes.ModifiedDisciplineItem> = []
            filteredItemsCount = 0
            semester?.disciplines?.forEach((disciplineItem: EducationPlanTypes.EduPlanDiscipline, indexB: number) => {
                disciplineItem?.itemGroups?.forEach((groupItem: EducationPlanTypes.EduPlanDisciplineItemGroup) => {
                    groupItem?.items?.forEach((item: EducationPlanTypes.EduPlanDisciplineItem) => {
                        // Если вид занятия соответствует фильтру
                        if (
                            plan_currentLessonType?.number === 0 ||
                            plan_currentLessonType?.name === item.name
                        ) {
                            if (!filteredItems[indexB]) {
                                filteredItems[indexB] = []
                            }
                            const modifiedItem: EducationPlanTypes.ModifiedDisciplineItem = Object.assign({}, item)
                            // Добавление дополнительных полей для вывода
                            modifiedItem.kafedraName = (
                                <Link
                                    to={{ pathname: disciplineItem?.kafedraUrl }}
                                    target={'_blank'}
                                    key={`kafedraName_${indexB}`}
                                >
                                    {disciplineItem?.kafedraName}
                                </Link>
                            )
                            // Элемент с ленивой загрузкой
                            modifiedItem.isLazy =
                                filteredItemsCount < unLazyItemsCount ? false : true
                            // Добавление вида занятия в отфильтрованный массив
                            filteredItems[indexB].push(modifiedItem)
                            // Увеличение счетчика отфильтрованных видов занятий
                            filteredItemsCount++
                        }
                    })
                })
            })

            // Контент семестров
            if (!_.isEmpty(filteredItems)) {
                semestersContent.push(
                    <MemoEducationSemester
                        semester={semester}
                        filteredItems={filteredItems}
                        disciplineType={'plan'}
                        key={`eduPlanSemester_${indexA}`}
                    />,
                )
            }
        }
    })

    return (
        <>
            <Grid container>
                <Grid item xs={12} sx={{                    
                    [theme.breakpoints.down('sm')]: {
                        display: 'grid',                            
                        gridTemplateAreas: `
                            "a b"
                            "a b"`,                
                        gridTemplateColumns: '50%',                            
                    }                    
                }}>
                    <Select
                        title={'Семестр'}
                        items={semesterMenuItems}
                        value={
                            plan_currentSemester
                            ? plan_semestersList?.find(
                                (semesterItem: EducationPlanReducerTypes.SemesterItem) =>
                                    semesterItem.number === plan_currentSemester.number,
                            )?.name
                            : 'Список семестров'                            
                        }
                    />
                    
                    <Select
                        title={'Вид занятий'}
                        items={lessonTypeMenuItems}
                        value={
                            plan_currentLessonType
                            ? plan_lessonTypesList?.find(
                                (lessonTypeItem: EducationPlanReducerTypes.LessonTypeItem) =>
                                    lessonTypeItem.number === plan_currentLessonType.number,
                            )?.name
                            : 'Список видов занятий'                            
                        }
                    />                                        
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    {semestersContent}
                </Grid>
            </Grid>
        </>
    )
}

// Экспорт компонента
export default EducationPlan
