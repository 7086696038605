// Node
import { makeStyles } from '@mui/styles'

// Конструктор стилей
const useStyleMui = () => {
    return makeStyles(() => ({
        root: {
            display: 'flex',
            flexGrow: 1,
        },
    }))()
}

// Экспорт конструктора стилей
export default useStyleMui
