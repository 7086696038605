import React from 'react'
// Node
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Badge, Box, IconButton } from '@mui/material'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'
// Components
import MaterialAvatar from '@materialComponents/MaterialAvatar'
import MaterialMenu from '@materialComponents/MaterialMenu'
// Redux
import { setActiveMenuURL } from '@redux/reducers/menu-reducer'
import { getUnreadCount } from '@redux/reducers/notifications-reducer'
// Types
import { AppStateType } from '@redux/store'
import TopMenuBlockTypes from './types/TopMenuBlock'
// Own
import { useMenuItems, useMobileMenuItems } from './data/data'
import useStyleMui from './styles/style.mui'

/*
 * Блок "Верхняя навигация"
 */

const TopMenuBlock: React.FC<
    TopMenuBlockTypes.MapStateToProps & TopMenuBlockTypes.MapDispatchToProps
> = (props) => {
    // Преобразование пропсов в локальные константы
    const { activeMenuURL, setActiveMenuURL, photoUrl, getUnreadCount } = props
    
    // Браузерная история
    const history = useHistory()
    
    const styleMui = useStyleMui()
    const menuItems = useMenuItems()
    const mobileMenuItems = useMobileMenuItems(props)

    // Меню
    const menuId = 'top-menu'
    // Мобильное Меню
    const mobileMenuId = 'top-menu-mobile'

    // Хук эффекта
    React.useEffect(() => {
        // Получить количество непрочитанных уведомлений
        getUnreadCount()
    }, [])

    return (
        <>
            <Box className={styleMui.sectionDesktop}>
                {mobileMenuItems.map((item, index) => {
                    const menuItem = (
                        <IconButton
                            key={`mobileMenuItem_${index}`}
                            aria-label={item.label}
                            color={item?.link === activeMenuURL ? undefined : 'inherit'}
                        >
                            <Badge badgeContent={item.badgeContent} color="secondary">
                                {item.icon}
                            </Badge>
                        </IconButton>
                    )

                    return item.link ? (
                        <NavLink
                            className={styleMui.iconButtonNavLink}
                            to={{ pathname: item.link }}
                            key={`mobileMenuItem_${index}`}
                            onClick={() => {
                                if (item.link) {
                                    history.push(item.link)
                                    setActiveMenuURL(item.link)
                                }
                            }}
                        >
                            {menuItem}
                        </NavLink>
                    ) : (
                        menuItem
                    )
                })}
            </Box>

            <Box className={styleMui.sectionMobile}>
                <MaterialMenu
                    id={mobileMenuId}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    menuItems={mobileMenuItems}
                >
                    <IconButton
                        aria-label="show more"
                        aria-controls={mobileMenuId}
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <MoreVertIcon />
                    </IconButton>
                </MaterialMenu>
            </Box>

            <MaterialMenu
                id={menuId}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                menuItems={menuItems}
            >
                <Box className={styleMui.avatar} aria-controls={menuId} aria-haspopup="true">
                    <MaterialAvatar isOnline userImg={photoUrl} userName="" />
                </Box>
            </MaterialMenu>
        </>
    )
}

// Маппинг стейта в пропсы блока
const mapStateToProps = (state: AppStateType): TopMenuBlockTypes.MapStateToProps => ({
    activeMenuURL: state.menuStore?.activeMenuURL,
    photoUrl: state.authStore?.user?.photoUrl,
    unreadCount: state.notificationsStore?.unreadCount,
})

// Экспорт блока
export default connect(mapStateToProps, { setActiveMenuURL, getUnreadCount })(TopMenuBlock)
