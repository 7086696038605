// Node
import config from 'react-global-configuration'
// API
import axios from '@api/instance'
import { builder } from '@api/utils/builder'
// Types
import APITypes from '@api/types/api'
import NewsReducerTypes from '@redux/reducers/types/news-reducer'

// Экспорт API новостей
export const newsAPI = {
    getNews(page = config.get('api.news.pagination.currentPage')) {
        return axios[config.get('api.news.type') as APITypes.AxiosType]
            .get(builder('news').dataForGet(page))
            .then((response: APITypes.AxiosResponse) => {
                // Возврат ответа API
                return builder('news').dataMapping(builder('news').dataForThen(response, page))
            })
            .catch(() => {
                return null
            })
    },
    getNewsItem(page: NewsReducerTypes.NewsItem['id']) {
        return axios[config.get('api.newsItem.type') as APITypes.AxiosType]
            .get(builder('newsItem').dataForGet(page))
            .then((response: APITypes.AxiosResponse) => {
                // Возврат ответа API
                return builder('newsItem').dataMapping(response)
            })
            .catch(() => {
                return null
            })
    },
}
