import React from 'react'
// Node
import { Tooltip } from '@mui/material'
// Types
import TooltipFieldTypes from './types/TooltipField'

/*
 * Компонент "Поле со всплывающей подсказкой"
 */

const TooltipField: React.FC<TooltipFieldTypes.ComponentProps> = (props) => {
    // Преобразование пропсов в локальные константы
    const { title } = props

    const positionRef = React.useRef({ x: 0, y: -70 })
    const popperRef = React.useRef<any>(null)
    const areaRef = React.useRef<any>(null)

    // Обработчик движения мыши
    const handleMouseMove = (event: React.MouseEvent) => {
        positionRef.current = { x: event.clientX, y: event.clientY }

        if (popperRef.current != null) {
            popperRef.current.update()
        }
    }

    return (
        <Tooltip
            title={title}
            placement="top"
            arrow
            PopperProps={{
                popperRef,
                anchorEl: {
                    getBoundingClientRect: () => {
                        return new DOMRect(
                            positionRef.current.x,
                            areaRef.current.getBoundingClientRect().y,
                            0,
                            0,
                        )
                    },
                },
            }}
            onMouseMove={handleMouseMove}
            ref={areaRef}
        >
            {props?.children}
        </Tooltip>
    )
}

// Свойства по умолчанию
TooltipField.defaultProps = {}

// Экспорт компонента
export default TooltipField
