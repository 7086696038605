// Редьюсеры
import admissionReducer from './admission-reducer'
import admissionListReducer from './admission-list-reducer'
import admissionStatusReducer from './admission-status-reducer'
import accountsReducer from './accounts-reducer'
import authReducer from './auth-reducer'
import educationListReducer from './education-list-reducer'
import educationPlanReducer from './education-plan-reducer'
import educationPortfolioReducer from './education-portfolio-reducer'
import educationSessionReducer from './education-session-reducer'
import loadReducer from './load-reducer'
import menuReducer from './menu-reducer'
import newsReducer from './news-reducer'
import paginationReducer from './pagination-reducer'
import windowParamsReducer from './window-params-reducer'
import notificationsReducer from './notifications-reducer'

// Корневой редьюсер
const rootReducer = {
    admissionStore: admissionReducer,
    admissionListStore: admissionListReducer,
    admissionStatusStore: admissionStatusReducer,
    accountsStore: accountsReducer,
    authStore: authReducer,
    educationListStore: educationListReducer,
    educationPlanStore: educationPlanReducer,
    educationPortfolioStore: educationPortfolioReducer,
    educationSessionStore: educationSessionReducer,
    loadStore: loadReducer,
    menuStore: menuReducer,
    newsStore: newsReducer,
    paginationStore: paginationReducer,
    windowParamsStore: windowParamsReducer,
    notificationsStore: notificationsReducer,
}

// Экспорт корневого редьюсера
export default rootReducer
