import React from 'react'
// Node
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'
import _ from 'lodash-contrib'
import { useTheme } from '@mui/material/styles'
import { Box, Grid, Typography } from '@mui/material'
// Components
import MaterialList from '@materialComponents/MaterialList'
import MaterialTabs from '@materialComponents/MaterialTabs'
// Redux
import { getAccounts } from '@redux/reducers/accounts-reducer'
import { getAuthData } from '@redux/reducers/auth-reducer'
// Types
import { AppStateType } from '@redux/store'
import AboutPageTypes from './types/AboutPage'
import MaterialListTypes from '@materialComponents/MaterialList/types/MaterialList'
import MaterialTabsTypes from '@materialComponents/MaterialTabs/types/MaterialTabs'
// Own
import { useListItems } from './data/data.list_items'
import { useWorkspace } from './data/data.workspace/data.workspace'
import PhotoModalBlock from './blocks/PhotoModalBlock'
import PasswordModalBlock from './blocks/PasswordModalBlock'
import BrowserModalBlock from './blocks/BrowserModalBlock'
import AboutPageSkeleton from './skeleton'

/*
 * Страница "Обо мне"
 */

const AboutPage: React.FC<AboutPageTypes.MapStateToProps & AboutPageTypes.MapDispatchToProps> = (
    props,
) => {
    // Преобразование пропсов в локальные константы
    const {
        isAuth,
        user,
        loadingAuthComplete,
        getAuthData,
        accounts,
        loadingAccountsComplete,
        getAccounts,
    } = props
    
    // Тема MUI
    const theme = useTheme()
    
    // Получение контента для рабочего пространства
    const workspace: MaterialTabsTypes.ComponentProps['tabs'] = useWorkspace({
        accounts,
        loadingAccountsComplete,
    })

    // Получение элементов списка "Обо мне" из полученных данных пользователя
    const listItems: MaterialListTypes.ComponentProps['listItems'] = useListItems({
        user,
        loadingAuthComplete,
    })

    // Хук эффекта
    React.useEffect(() => {
        // Если пользователь аутентифицирован
        if (isAuth) {
            // Получить аутентификационные данные
            getAuthData()
            // Получить аккаунты
            getAccounts()
        }
    }, [])
    
    // Ширина контента
    const contentWidth = useSelector((state: AppStateType) => state.windowParamsStore?.contentWidth)

    // Маркер того, является ли разрешение экрана больше брекпоинта 'sm'
    const isUpSM = useSelector((state: AppStateType) => state.windowParamsStore?.isUpSM)
    
    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        {loadingAuthComplete
                            ? `${user?.surname} ${user?.name} ${user?.patronymic}`
                            : AboutPageSkeleton.userName}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        alignItems="flex-end"
                        sx={{ pr: { md: 6 } }}
                    >
                        <Grid item xs={12} sm={6} md={12}>
                            {loadingAuthComplete ? (
                                <Box
                                    {...{
                                        component: 'img',
                                        src: user?.photoDisplayUrl,
                                    }}
                                    sx={{ my: 2 }}
                                />
                            ) : (
                                AboutPageSkeleton.userPhoto
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    {loadingAuthComplete ? (
                                        <PhotoModalBlock user={user} />
                                    ) : (
                                        AboutPageSkeleton.userButton
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    {loadingAuthComplete ? (
                                        <PasswordModalBlock />
                                    ) : (
                                        AboutPageSkeleton.userButton
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    {loadingAuthComplete ? (
                                        <BrowserModalBlock />
                                    ) : (
                                        AboutPageSkeleton.userButton
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <MaterialList
                        listItems={listItems?.filter((item, i: number) => i < 5)}
                        maxWidth="100%"
                        listSX={{ pb: {xs: 0, sm: theme.spacing(1)} }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <MaterialList
                        listItems={listItems?.filter((item, i: number) => i >= 5)}
                        maxWidth="100%"
                        listSX={{ pt: {xs: 0, sm: theme.spacing(1)} }}
                    />
                </Grid>
                {!(_.isEmpty(accounts) && loadingAuthComplete) ? (
                    <>
                        <Grid item>
                            <Typography variant="h6">Реквизиты доступа</Typography>
                        </Grid>
                        <Grid item>
                            <MaterialTabs
                                tabs={workspace}
                                orientation={'horizontal'}
                                tabsWidth={contentWidth}
                                variant={'scrollable'}
                                tabStyle={{minWidth: 'fit-content'}}
                                tabsListStyle={isUpSM ? {} : {marginLeft: '-15px'}}
                                tabBoxStyle={isUpSM ? {} : {'h6': {fontSize: '1.1rem', lineHeight: '1.3'}}}
                            />
                        </Grid>
                    </>
                ) : (
                    []
                )}
            </Grid>
        </div>
    )
}

// Маппинг стейта в пропсы страницы
const mapStateToProps = (state: AppStateType): AboutPageTypes.MapStateToProps => ({
    user: state.authStore?.user,
    isAuth: state.authStore?.isAuth,
    loadingAuthComplete: state.loadStore.loadingComplete?.auth,
    accounts: state.accountsStore?.accounts,
    loadingAccountsComplete: state.loadStore.loadingComplete?.accounts,
})

// Экспорт страницы
export default connect(mapStateToProps, { getAuthData, getAccounts })(AboutPage)
