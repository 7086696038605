// Node
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// API
import { educationAPI } from '@api/endpoints/educationAPI'
// Types
import EducationPortfolioReducerTypes from '@redux/reducers/types/education-portfolio-reducer'
import EducationListReducerTypes from '@redux/reducers/types/education-list-reducer'
import { AppDispatchType } from '@redux/store'
import APITypes from '@api/types/api'
// Создатели действий загрузки
import { setLoading, setLoaded } from './load-reducer'

// Начальное состояние
const initialState: EducationPortfolioReducerTypes.InitialState = {
    // Список портфолио
    educationPortfolio: [],
}

// Слайс портфолио
const educationPortfolioSlice = createSlice({
    name: 'educationPortfolio',
    initialState,
    reducers: {
        // Установить список портфолио
        setEducationPortfolio(
            state: EducationPortfolioReducerTypes.InitialState,
            action: PayloadAction<
                EducationPortfolioReducerTypes.InitialState['educationPortfolio']
            >,
        ) {
            state.educationPortfolio = action.payload
        },
        // Установить комментарии для элемента портфолио
        setEducationPortfolioComments(
            state: EducationPortfolioReducerTypes.InitialState,
            action: PayloadAction<EducationPortfolioReducerTypes.PortfolioItem['comments']>,
        ) {
            Object.assign(state, {
                ...state,
                educationPortfolio: state.educationPortfolio.map(
                    (portfolioItem: EducationPortfolioReducerTypes.PortfolioItem) => {
                        // Берем idPortfolioItem из первого в массиве комментария
                        const idPortfolioItem = action.payload
                            ? action.payload[0].idPortfolioItem
                            : 0
                        return portfolioItem.idPortfolioItem === idPortfolioItem
                            ? { ...portfolioItem, comments: action.payload }
                            : portfolioItem
                    },
                ),
            })
        },
    },
})

// Экспорт создателей действий
export const { setEducationPortfolio, setEducationPortfolioComments } =
    educationPortfolioSlice.actions
// Экспорт редьюсера
export default educationPortfolioSlice.reducer

//THUNK CREATORS

// Получение данных списка портфолио
export const getEducationPortfolio = (
    idStudent: EducationListReducerTypes.EducationItem['educationId'],
) => {
    return (dispatch: AppDispatchType) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading('educationPortfolio'))
        // Получение данных через API плана обучения
        educationAPI.getEducationPortfolio(idStudent).then((response: APITypes.AxiosResponse) => {
            // Установить данные списка портфолио
            dispatch(setEducationPortfolio(response))

            // Ассинхронная установка свойства "comments" для уже полученных портфолио
            response?.forEach((portfolio: EducationPortfolioReducerTypes.PortfolioItem) => {
                // Если комментарии разрешено показывать и их количество больше "0"
                if (portfolio?.showComment && portfolio?.commentsNumber) {
                    // Получение данных через API плана обучения
                    educationAPI
                        .getEducationPortfolioComments(portfolio?.idPortfolioItem)
                        .then((commentsResponse: APITypes.AxiosResponse) => {
                            // Установить комментарии для элемента портфолио
                            dispatch(setEducationPortfolioComments(commentsResponse))
                        })
                }
            })

            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded('educationPortfolio'))
        })
    }
}
