import React from 'react'
// Node
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'
import { IconButton, Typography } from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'
// Assets
import logoImg from '@src/assets/images/logo_eagle_little.svg'
// Layouts
import { MainLayoutContext } from '@layouts/MainLayout'
// Types
import MainLayoutTypes from '@layouts/MainLayout/types/MainLayout'
// Own
import useStyleMui from './styles/style.mui'

/*
 * Блок "Лого"
 */

const LogoBlock = () => {
    const styleMui = useStyleMui()

    // Получение данных об открытии/закрытии панели меню из контекста MainLayoutContext
    const useMainLayoutContext = (): MainLayoutTypes.MainLayoutContext =>
        React.useContext(MainLayoutContext) as MainLayoutTypes.MainLayoutContext
    const { panelOpenContext, setPanelOpenContext } = useMainLayoutContext()

    return (
        <>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => setPanelOpenContext(true)}
                edge="start"
                className={clsx(styleMui.menuButton, {
                    [styleMui.hide]: panelOpenContext,
                })}
            >
                <MenuIcon />
            </IconButton>

            <div className={styleMui.logo}>
                <NavLink to="/">
                    <img src={logoImg} alt="" />
                </NavLink>
            </div>
            <Typography className={styleMui.header} variant="h6" noWrap sx={{fontSize: {xs: '16px', md: '20px'} }}>
                Личный кабинет
            </Typography>
        </>
    )
}

// Экспорт блока
export default LogoBlock
