// Types
import StrapModalTypes from '@strapComponents/StrapModal/types/StrapModal'

// Экспорт кнопок модального окна
export const useModalButtons = (): StrapModalTypes.ComponentProps['modalButtons'] => [
    {
        color: 'danger',
        outline: true,
        text: 'Применить',
        onClick: () => console.log('Password setting...'),
    },
    { color: 'secondary', outline: true, text: 'Отмена', isCloseBtn: true },
]
