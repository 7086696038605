import React from 'react'
// Node
import _ from 'lodash-contrib'
import LazyLoad from 'react-lazyload'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
// Components
import { LinkField } from '@commonComponents/DataFields'
// Types
import EducationPlanSemesterTypes from './types/EducationPlanSemester'
// Own
import EducationDiscipline from '../EducationDiscipline'
import useStyleMui from './styles/style.mui'

/*
 * Компонент "Семестр"
 */

// Кешированный компонент "Дисциплины"
const MemoEducationDiscipline = React.memo(EducationDiscipline)

const EducationSemester: React.FC<EducationPlanSemesterTypes.ComponentProps> = (props) => {
    // Преобразование пропсов в локальные константы
    const { semester, filteredItems, disciplineType } = props

    // Стиль компонента
    const styleMui = useStyleMui()

    return (
        <>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    className={styleMui.accordionSummary}
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant={'h6'} className={styleMui.semesterName} >
                        {semester?.name}
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    {semester?.disciplines?.map((discipline: EducationPlanSemesterTypes.Discipline, indexA: number) => {
                        if (!_.isEmpty(filteredItems[indexA])) {
                            const documentsArray = discipline?.items 
                                ? discipline.items?.documents
                                : discipline?.documents
                            const documents =
                                documentsArray?.map((document: EducationPlanSemesterTypes.AttachedDocuments, indexB: number) => (
                                    <Box className={styleMui.document} key={`document_${indexA}_${indexB}`}>
                                        <LinkField
                                            fieldId={`document_${indexA}_${indexB}`}
                                            linkText={document?.filename}
                                            linkTextPosition={'after'}
                                            linkURL={document?.downloadUrl}
                                            linkTarget={'_blank'}
                                            linkAlt={document?.sizeDisplay}
                                            linkSX={{ display: 'block', width: 'fit-content' }}
                                            image={document?.iconUrl}
                                        />
                                    </Box>
                                ))


                            const EducationDisciplineContent = (
                                <MemoEducationDiscipline
                                    discipline={discipline}
                                    filteredItems={filteredItems[indexA]}
                                    disciplineType={disciplineType}
                                    documents={documents}
                                    key={`eduPlanDiscipline_${indexA}`}
                                />
                            )

                            return (
                                <>
                                    {filteredItems[indexA][0]?.isLazy ? (
                                        <LazyLoad height={50} offset={400} once>
                                            {EducationDisciplineContent}
                                        </LazyLoad>
                                    ) : (
                                        <>{EducationDisciplineContent}</>
                                    )}
                                </>
                            )
                        }
                    })}
                </AccordionDetails>
                <Box sx={{ mb: 2 }}> </Box>
            </Accordion>
        </>
    )
}

// Экспорт компонента
export default EducationSemester
