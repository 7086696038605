import React from 'react'
// Node
import { connect } from 'react-redux'
import { useWindowResize } from 'beautiful-react-hooks'
import { useTheme } from '@mui/material/styles'
// Layouts
import { MainLayoutContext } from '@layouts/MainLayout'
// Redux
import { setWindowWidth, setContentWidth, setIsUpSM } from '@redux/reducers/window-params-reducer'
// Types
import { AppStateType } from '@redux/store'
import WindowParamsTypes from './types/WindowParams'

/*
 * Компонент "Установщик параметров окна приложения"
 */

const WindowParamsSetter: React.FC<WindowParamsTypes.MapStateToProps & WindowParamsTypes.MapDispatchToProps> = (
    props,
) => {
    // Преобразование пропсов в локальные константы
    const { windowWidth, setWindowWidth, setContentWidth, setIsUpSM, } = props
    
    // Тема MUI
    const theme = useTheme()   

    // Получение данных об открытии/закрытии панели меню из контекста MainLayoutContext
    const useMainLayoutContext = (): MainLayoutTypes.MainLayoutContext =>
        React.useContext(MainLayoutContext) as MainLayoutTypes.MainLayoutContext
    const { panelOpenContext } = useMainLayoutContext()

    // Метод определения ширины (девайса / окна браузера)
    const getWindowWidth = () => screen.width - window.innerWidth > 50 ? window.innerWidth : screen.width

    // Маркер того, является ли разрешение экрана больше брекпоинта 'sm'
    const isUpSM = windowWidth >= Number(theme.breakpoints.values['sm'])
    
    // Хук изменения размера окна браузера
    useWindowResize()(() => {
        setIsUpSM(isUpSM)
        setWindowWidth(getWindowWidth())
    })
    
    // Хук эффекта
    React.useEffect(() => {
        setIsUpSM(isUpSM)        
        setWindowWidth(getWindowWidth())
    }, [])
    
    // Хук эффекта
    React.useEffect(() => {        
        setContentWidth(
            windowWidth - // ширина окна браузера
            (isUpSM ? (panelOpenContext ? 300 : 70) : 70) - // ширина левого меню
            (isUpSM ? Number(theme.spacing(6).replace('px', '')) : 0 ) - // отступ, заданный в теме MUI
            20 // погрешность в 20px
        )
    }, [windowWidth])  

    return (
        <></>
    )
}

// Маппинг стейта в пропсы страницы
const mapStateToProps = (state: AppStateType): WindowParamsTypes.MapStateToProps => ({
    windowWidth: state.windowParamsStore?.windowWidth,
})

// Экспорт компонента
export default connect(mapStateToProps, { setWindowWidth, setContentWidth, setIsUpSM })(WindowParamsSetter)
