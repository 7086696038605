import React from 'react'
// Node
import { connect } from 'react-redux'
import { Button } from '@mui/material'
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material'
// Components
import MaterialMenu from '@materialComponents/MaterialMenu'
// Redux
import { getEducationList } from '@redux/reducers/education-list-reducer'
// Types
import { AppStateType } from '@redux/store'
import EducationPageTypes from './types/EducationPage'
// Own
import { GetEducationMenuItems } from './data/data.menu_items'
import EducationTabsBlock from './blocks/EducationTabsBlock'
import EducationListSkeleton from './skeleton'

/*
 * Страница "Мое обучение"
 */

const EducationPage: React.FC<
    EducationPageTypes.MapStateToProps & EducationPageTypes.MapDispatchToProps
> = (props) => {
    // Преобразование пропсов в локальные константы
    const { currentEducation, educations, loadingEducationListComplete, getEducationList } = props

    // Установка локального значения переменной activeTabIndex
    const [activeTabIndex, setActiveTabIndex] = React.useState(0)

    // Хук эффекта
    React.useEffect(() => {
        // Получение данных списка обучений
        getEducationList()
    }, [])

    // Элементы меню выпадающего списка с обучениями
    const educationMenuItems = GetEducationMenuItems({
        currentEducation,
        educations,
        setActiveTabIndex,
    })

    return (
        <>  
            { loadingEducationListComplete
                ? <MaterialMenu
                    id={'education-list-menu'}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    menuItems={educationMenuItems}
                    sx={{'button': {
                        textAlign: {xs: 'left', sm: 'center'},
                        fontSize: {xs: '0.75rem', sm: '0.875rem'},
                        lineHeight: {xs: '1.5', sm: '1.75'},
                    }}}
                >
                    <Button
                        aria-controls={'education-list-menu'}
                        aria-haspopup={true}
                        variant={'outlined'}
                        disableElevation
                        endIcon={<KeyboardArrowDownIcon />}
                        sx={{ mb: 2 }}
                    >
                        {currentEducation
                            ? educations?.find(
                                (educationItem) =>
                                    educationItem.educationId === currentEducation.educationId,
                            )?.display
                            : 'Список обучений'}
                    </Button>
                </MaterialMenu>
                : EducationListSkeleton?.listItem
            }
            <EducationTabsBlock
                currentEducation={currentEducation}
                activeTabIndex={activeTabIndex}
                setActiveTabIndex={setActiveTabIndex}
            />
        </>
    )
}

// Маппинг стейта в пропсы страницы
const mapStateToProps = (state: AppStateType): EducationPageTypes.MapStateToProps => ({
    educations: state.educationListStore?.educations,
    currentEducation: state.educationListStore?.currentEducation,
    loadingEducationListComplete: state.loadStore.loadingComplete?.educationList,
})

// Экспорт страницы
export default connect(mapStateToProps, { getEducationList })(EducationPage)
