// Node
import { useDispatch } from 'react-redux'
// Redux
import { setСurrentSemester, setCurrentLessonType } from '@redux/reducers/education-plan-reducer'

// Элементы меню выпадающего списка с обучениями
export const GetSemesterMenuItems = (props) => {
    // Преобразование пропсов в локальные константы
    const { plan_semestersList, plan_currentSemester } = props

    // Определение метода dispatch
    const dispatch = useDispatch()

    // Проход по всем семестрам
    return plan_semestersList?.map((semesterItem) => ({
        name: semesterItem?.name,
        selected: plan_currentSemester?.number === semesterItem?.number,
        onClick: () => {
            dispatch(setСurrentSemester(semesterItem))
        },
    }))
}

// Элементы меню выпадающего списка с видами занятий
export const GetLessonTypeMenuItems = (props) => {
    // Преобразование пропсов в локальные константы
    const { plan_lessonTypesList, plan_currentLessonType } = props

    // Определение метода dispatch
    const dispatch = useDispatch()

    // Проход по всем видам занятий
    return plan_lessonTypesList?.map((lessonTypeItem) => ({
        name: lessonTypeItem?.name,
        selected: plan_currentLessonType?.number === lessonTypeItem?.number,
        onClick: () => {
            dispatch(setCurrentLessonType(lessonTypeItem))
        },
    }))
}
