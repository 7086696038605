import React from 'react'
// Node
import { Link as RouterLink } from 'react-router-dom'
import { Icon } from '@iconify/react'
import eyeFill from '@iconify/icons-eva/eye-fill'
import shareFill from '@iconify/icons-eva/share-fill'
import messageCircleFill from '@iconify/icons-eva/message-circle-fill'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
// Types
import NewsCardTypes from './types/NewsCard'
// Utils
import { fDate } from '@utils/formatTime'
import { fShortenNumber } from '@utils/formatNumber'
// Own
import {
    AvatarStyle,
    CardMediaStyle,
    CoverImgStyle,
    InfoStyle,
    SvgIconStyle,
    TitleStyle,
} from './components'
import shapeAvatar from './src/icons/shape-avatar.svg'
import defaultAvatar from './src/avatars/default.jpg'
import defaultCover from './src/covers/default.jpg'

/*
 * Компонент "Новостная карточка"
 */

export const NewsCard: React.FC<NewsCardTypes.ComponentProps> = (props) => {
    // Преобразование пропсов в локальные константы
    const { cover, title, view, comment, share, author, createdAt } = props.data
    const { latestCardLarge, latestCard, hideCover, hideAvatar, onCardClick, onTitleClick } = props

    // Данные инфо-иконок
    const infoIconsData = [
        { number: comment, icon: messageCircleFill },
        { number: view, icon: eyeFill },
        { number: share, icon: shareFill },
    ]

    return (
        <Grid
            item
            xs={12}
            sm={latestCardLarge ? 12 : 6}
            md={latestCardLarge ? 8 : 4}
            xl={latestCardLarge ? 6 : 3}
        >
            <Card
                sx={{
                    position: 'relative',
                    borderRadius: '16px',
                    '&:hover': {
                        ...(onCardClick && {
                            cursor: 'pointer',
                            opacity: '0.8',
                        }),
                    },
                }}
                onClick={() => onCardClick && onCardClick()}
            >
                {!hideCover && (
                    <CardMediaStyle
                        sx={{
                            ...((latestCardLarge || latestCard) && {
                                pt: {
                                    xs: 'calc(70% * 4 / 3)',
                                    sm: 'calc(100% * 4 / 3)',
                                },
                                '&:after': {
                                    top: 0,
                                    content: "''",
                                    width: '100%',
                                    height: '100%',
                                    position: 'absolute',
                                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                                },
                            }),
                            ...(latestCardLarge && {
                                pt: {
                                    xs: 'calc(70% * 4 / 3)',
                                    sm: 'calc(100% * 3 / 4.66)',
                                },
                            }),
                        }}
                    >
                        {!hideAvatar && (
                            <>
                                <SvgIconStyle
                                    color="paper"
                                    src={shapeAvatar}
                                    sx={{
                                        width: 80,
                                        height: 36,
                                        zIndex: 9,
                                        bottom: -15,
                                        position: 'absolute',
                                        ...((latestCardLarge || latestCard) && { display: 'none' }),
                                    }}
                                />
                                <AvatarStyle
                                    alt={author?.name}
                                    src={author?.avatarUrl || defaultAvatar}
                                    sx={{
                                        ...((latestCardLarge || latestCard) && {
                                            zIndex: 9,
                                            top: 24,
                                            left: 24,
                                            width: 40,
                                            height: 40,
                                        }),
                                    }}
                                />
                            </>
                        )}
                        <CoverImgStyle alt={title} src={cover || defaultCover} />
                    </CardMediaStyle>
                )}

                <CardContent
                    sx={{
                        pt: 4,
                        ...((latestCardLarge || latestCard) && {
                            bottom: 0,
                            width: '100%',
                            position: 'absolute',
                            ...(hideCover && { position: 'relative' }),
                        }),
                    }}
                >
                    <Typography
                        gutterBottom
                        variant="caption"
                        sx={{ color: 'text.disabled', display: 'block' }}
                    >
                        {fDate(createdAt)}
                    </Typography>

                    <TitleStyle
                        to="#"
                        color="inherit"
                        variant="subtitle2"
                        underline="hover"
                        component={RouterLink}
                        sx={{
                            '&:hover': {
                                color: 'inherit',
                                ...(!onTitleClick && {
                                    textDecoration: 'none',
                                }),
                                ...(!onTitleClick &&
                                    !onCardClick && {
                                        cursor: 'default',
                                    }),
                            },
                            ...(latestCardLarge && { typography: 'h5', height: 60 }),
                            ...((latestCardLarge || latestCard) && {
                                color: 'common.white',
                                ...(hideCover && { color: 'inherit' }),
                                '&:hover': {
                                    color: 'common.white',
                                    ...(hideCover && { color: 'inherit' }),
                                    ...(!onTitleClick && {
                                        textDecoration: 'none',
                                    }),
                                    ...(!onTitleClick &&
                                        !onCardClick && {
                                            cursor: 'default',
                                        }),
                                },
                            }),
                        }}
                        onClick={() => onTitleClick && onTitleClick()}
                    >
                        {title}
                    </TitleStyle>

                    <InfoStyle>
                        {infoIconsData.map(
                            (info, index) =>
                                info.number && (
                                    <Box
                                        key={`infoStyleBox_${index}`}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            ml: index === 0 ? 0 : 1.5,
                                            ...((latestCardLarge || latestCard) && {
                                                color: 'grey.500',
                                            }),
                                        }}
                                    >
                                        <Box
                                            component={Icon}
                                            icon={info.icon}
                                            sx={{ width: 16, height: 16, mr: 0.5 }}
                                        />
                                        <Typography variant="caption">
                                            {fShortenNumber(info.number)}
                                        </Typography>
                                    </Box>
                                ),
                        )}
                    </InfoStyle>
                </CardContent>
            </Card>
        </Grid>
    )
}

// Свойства по умолчанию
NewsCard.defaultProps = {
    hideCover: false,
    hideAvatar: false,
    onCardClick: null,
    onTitleClick: null,
}

// Экспорт компонента
export default NewsCard
