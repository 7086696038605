import React from 'react'
// Node
import fileDownload from 'js-file-download'
import { Link } from '@mui/material'
// API
import axios from '@api/instance'
// Types
import APITypes from '@api/types/api'
import FileFieldTypes from './types/FileField'

/*
 * Компонент "Поле скачивания файла"
 */

const FileField: React.FC<FileFieldTypes.ComponentProps> = (props) => {
    // Преобразование пропсов в локальные константы
    const {
        fieldId,
        axiosType,
        fileURL,
        fileName,
        fileExtension,
        fileSizeDisplay,
        linkText,
        linkSX,
    } = props

    // Текст ссылки
    let linkTextDisplay = linkText
    if (!linkText) {
        linkTextDisplay = `${fileName}(${fileSizeDisplay})`
    }

    // Обработка клика по ссылке загрузки файла
    const handleFileDownloadClick = (fileURL: string, fileName: string) => {
        axios[axiosType as APITypes.AxiosType]
            .get(fileURL, {
                responseType: 'blob',
            })
            .then((res) => {
                fileDownload(res.data, fileName)
            })
    }

    return (
        <Link
            key={`fileField_${fieldId}`}
            sx={linkSX}
            onClick={() => {
                handleFileDownloadClick(fileURL, `${fileName}.${fileExtension}`)
            }}
        >
            {linkTextDisplay}
        </Link>
    )
}

// Свойства по умолчанию
FileField.defaultProps = {
    axiosType: 'original',
    linkSX: { cursor: 'pointer' },
}

// Экспорт компонента
export default FileField
