import React from 'react'
// Node
import { IconButton } from '@mui/material'
import {
    AttachFile as AttachFileIcon,
    SentimentVeryDissatisfied as SentimentVeryDissatisfiedIcon,
    SentimentDissatisfied as SentimentDissatisfiedIcon,
    SentimentSatisfied as SentimentSatisfiedIcon,
    SentimentSatisfiedAltOutlined as SentimentSatisfiedAltIcon,
    SentimentVerySatisfied as SentimentVerySatisfiedIcon,
} from '@mui/icons-material'
// Own
import { StyledRating } from './styles/style.mui'
import { useStyledInput } from '../../styles/style.mui'

/*
 * Блок "Образование"
 */

// Кастомизированные иконки
const customIcons: {
    [iconIndex: number]: { icon: React.ReactNode; label: string }
} = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon />,
        label: 'Очень плохое',
    },
    2: {
        icon: <SentimentDissatisfiedIcon />,
        label: 'Плохое',
    },
    3: {
        icon: <SentimentSatisfiedIcon />,
        label: 'Нормальное',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon />,
        label: 'Хорошее',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon />,
        label: 'Отличное',
    },
}

// Контейнер с иконками для компонента рейтинга
const IconContainer: React.FC<{ value: number }> = (props) => {
    const { value, ...other } = props
    return <span {...other}>{customIcons[value].icon}</span>
}

const EducationBlock: React.FC = () => {
    // Скрытый инпут для загрузки фото
    const Input = useStyledInput()

    return (
        <>
            <i>Укажите свой текущий уровень образования и подтверждающий документ.</i>
            <br />
            <label htmlFor="icon-button-file">
                Указать
                <Input accept="image/*" id="icon-button-file" type="file" />
                <IconButton color="primary" aria-label="upload picture" component="span">
                    <AttachFileIcon />
                </IconButton>
            </label>
            <br /> <br />
            <i>Знание иностранного языка: </i>
            <br />
            <StyledRating
                name="highlight-selected-only"
                defaultValue={2}
                IconContainerComponent={IconContainer}
                highlightSelectedOnly
            />
        </>
    )
}

// Экспорт блока
export default EducationBlock
