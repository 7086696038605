import React from 'react'
// Node
import clsx from 'clsx'
import _ from 'lodash-contrib'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'
import {
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material'
import {
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material'
// Layouts
import { MainLayoutContext } from '@layouts/MainLayout'
// Redux
import { setActiveMenuURL, getMenuItems } from '@redux/reducers/menu-reducer'
// Types
import { AppStateType } from '@redux/store'
import LeftMenuAreaTypes from './types/LeftMenuArea'
import MainLayoutTypes from '@layouts/MainLayout/types/MainLayout'
// Own
import useStyleMui from './styles/style.mui'
import { getPanelListItems } from './data/data'

/*
 * Область "Левое меню"
 */

const LeftMenuArea: React.FC<
    { drawerWidth: number } & LeftMenuAreaTypes.MapStateToProps &
    LeftMenuAreaTypes.MapDispatchToProps
> = (props) => {
    // Преобразование пропсов в локальные константы
    const { activeMenuURL, setActiveMenuURL, menuItems, getMenuItems, loadingMenuComplete } = props

    // Тема MUI
    const theme = useTheme()
    const styleMui = useStyleMui(props)

    // Браузерная история
    const history = useHistory()

    // Получение данных об открытии/закрытии панели меню из контекста MainLayoutContext
    const useMainLayoutContext = (): MainLayoutTypes.MainLayoutContext =>
        React.useContext(MainLayoutContext) as MainLayoutTypes.MainLayoutContext
    const { panelOpenContext, setPanelOpenContext } = useMainLayoutContext()

    // Хук эффекта
    React.useEffect(() => {
        // Получить пункты меню
        getMenuItems()
    }, [])

    // Получение элементов списка панели меню из полученных пунктов меню
    const panelListItems = getPanelListItems({
        menuItems: menuItems,
        loadingMenuComplete: loadingMenuComplete,
    })

    return (
        <Drawer
            variant="permanent"
            className={clsx(styleMui.drawer, {
                [styleMui.drawerOpen]: panelOpenContext,
                [styleMui.drawerClose]: !panelOpenContext,
            })}
            classes={{
                paper: clsx({
                    [styleMui.drawerOpen]: panelOpenContext,
                    [styleMui.drawerClose]: !panelOpenContext,
                }),
            }}
        >
            <div className={styleMui.toolbar}>
                <IconButton onClick={() => setPanelOpenContext(false)}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </div>
            <List className={styleMui.list}>
                {panelListItems?.map((item: LeftMenuAreaTypes.OnePanelGroupItem, index) => (
                    <div key={`panellistItem_${index}`}>
                        <>
                            <Divider />
                            {item?.name && (
                                <ListItem className={clsx(styleMui.listItem, 'captionItem')}>
                                    {panelOpenContext ? (
                                        <Typography variant="body1">{item?.name}</Typography>
                                    ) : (
                                        <Tooltip
                                            placement="right"
                                            arrow
                                            title={
                                                <span className={styleMui.tooltip}>
                                                    {item?.name}
                                                </span>
                                            }
                                        >
                                            <ListItemIcon>
                                                <KeyboardArrowDownIcon color="primary" />
                                            </ListItemIcon>
                                        </Tooltip>
                                    )}
                                </ListItem>
                            )}
                        </>
                        {item?.items?.map(
                            (sub: LeftMenuAreaTypes.OnePanelListItem, index: number) => {
                                // Пропуск пустого элемента
                                if(_.isEmpty(sub)) return

                                const listItemIcon = <ListItemIcon>{sub.icon}</ListItemIcon>

                                const listItem = (
                                    <ListItem
                                        className={styleMui.listItem}
                                        button
                                        key={`panelListItem_${index}`}
                                        selected={sub.link === activeMenuURL}
                                    >
                                        {panelOpenContext ? (
                                            listItemIcon
                                        ) : (
                                            <Tooltip
                                                placement="right"
                                                arrow
                                                title={
                                                    <span className={styleMui.tooltip}>
                                                        {sub.text}
                                                    </span>
                                                }
                                            >
                                                {listItemIcon}
                                            </Tooltip>
                                        )}
                                        {sub.header && sub.header}
                                        {sub.text && <ListItemText primary={sub.text} />}
                                    </ListItem>
                                )

                                return sub.link || sub.extLink ? (
                                    <NavLink
                                        to={
                                            sub.extLink
                                                ? { pathname: sub.extLink }
                                                : { pathname: sub.link }
                                        }
                                        target={sub.extLink ? '_blank' : '_self'}
                                        key={`panelNavLink_${index}`}
                                        className={styleMui.link}
                                        onClick={() => {
                                            if (sub.link) {
                                                history.push(sub.link)
                                                setActiveMenuURL(sub.link)
                                            }
                                        }}
                                    >
                                        {listItem}
                                    </NavLink>
                                ) : (
                                    listItem
                                )
                            },
                        )}
                    </div>
                ))}
            </List>
        </Drawer>
    )
}

// Маппинг стейта в пропсы области
const mapStateToProps = (state: AppStateType): LeftMenuAreaTypes.MapStateToProps => ({
    activeMenuURL: state.menuStore?.activeMenuURL,
    menuItems: state.menuStore?.menuItems,
    loadingMenuComplete: state.loadStore.loadingComplete?.menu,
})

// Экспорт области
export default connect(mapStateToProps, { setActiveMenuURL, getMenuItems })(LeftMenuArea)
