import React from 'react'
// Node
import { Badge, Box, Divider, IconButton, MenuItem } from '@mui/material'
import { useHistory } from 'react-router-dom'
// Types
import MaterialMenuTypes from './types/MaterialMenu'
// Own
import useStyleMui, { StyledMenu } from './styles/style.mui'

/*
 * Компонент "Меню" (обертка над MaterialUI)
 */

// Экспорт компонента
export const MaterialMenu: React.FC<MaterialMenuTypes.ComponentProps> = (props) => {
    // Преобразование пропсов в локальные константы
    const { id, sx, anchorOrigin, menuItems } = props

    // Установка якоря на элемент, вызывающий меню
    const [anchorEl, setAnchorEl] =
        React.useState<MaterialMenuTypes.ComponentProps['anchorEl']>(null)

    // Метод открытия меню
    const handleMenuOpen = (event: React.SyntheticEvent) => {
        setAnchorEl(event.currentTarget as MaterialMenuTypes.ComponentProps['anchorEl'])
    }

    // Метод закрытия меню
    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    // Браузерная история
    const history = useHistory()

    // Стиль компонента
    const styleMui = useStyleMui()

    return (
        <Box sx={{ width: 'fit-content', ...sx, }}>
            <Box onClick={handleMenuOpen}>
                {
                    // Элемент при клике на который открывается меню
                    // Этот элемент должен быть обернут в MaterialMenu
                    props.children
                }
            </Box>
            <StyledMenu
                id={id}
                anchorEl={anchorEl}
                anchorOrigin={anchorOrigin}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                {
                    // Маппинг массива элементов меню
                    menuItems.map((item) =>
                        item.type === 'divider' ? (
                            <Divider key={item.name} sx={{ my: 0.5 }} />
                        ) : (
                            <MenuItem
                                key={item.name}
                                onClick={() => {
                                    item.onClick
                                        ? item.onClick()
                                        : item.link && history.push(item.link)
                                    handleMenuClose()
                                }}
                                selected={item.selected}
                            >
                                {item.icon && item.badgeContent ? (
                                    <IconButton aria-label={item.label} color="inherit">
                                        <Badge
                                            className={styleMui.badge}
                                            badgeContent={item.badgeContent}
                                            color="secondary"
                                        >
                                            {item.icon}
                                        </Badge>
                                    </IconButton>
                                ) : (
                                    item.icon
                                )}
                                {item.name}
                            </MenuItem>
                        ),
                    )
                }
            </StyledMenu>
        </Box>
    )
}

// Свойства по умолчанию
MaterialMenu.defaultProps = {
    anchorOrigin: { vertical: 'top', horizontal: 'right' },
    menuItems: [],
}

// Экспорт компонента
export default MaterialMenu
