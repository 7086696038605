import React from 'react'
// Node
import { Skeleton } from '@mui/material'
import config from 'react-global-configuration'
import _ from 'lodash-contrib'

/*
 * Скелетон текста в компоненте "Элемент одного уведомленя"
 */

// Компонент скелетона
const NotificationsItemSkeleton: React.FC<{ width?: string; shift?: number; count?: number }> = (
    props,
) => {
    // Преобразование пропсов в локальные константы
    const { width, shift, count } = props

    // Анимация скелетона
    const animation = config.get('skeleton.animation')
    // Контент скелетона
    const content = []

    for (let i = 0; i < (count ? count : 1); i++) {
        content.push(
            <Skeleton
                variant="text"
                width={`calc(${width} - ${_.random(shift ? shift : '0') + 'px'})`}
                //width={ `calc(100% - 500px)` }
                //style = {{width: 'calc (100% - 700px)'}}
                //width={width}
                animation={animation}
                key={`NotificationsItemSkeleton_${i}`}
            />,
        )
    }

    return <> {content} </>
}

// Экспорт скелетона
export default NotificationsItemSkeleton
