// Node
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// Types
import LoadReducerTypes from './types/load-reducer'

// Начальное состояние
const initialState: LoadReducerTypes.InitialState = {
    loadingComplete: {
        app: false,
        auth: false,
        menu: false,
        news: false,
        newsItem: false,
        admissionList: false,
        admissionStatus: false,
        educationList: false,
        educationItem: false,
        educationPlan: false,
        educationSession: false,
        educationPortfolio: false,
        accounts: false,
        notificationsUnreadCount: false,
        notificationsStructure: false,
        notificationsGroup: false,
        notificationsItem: false,
        notificationsMarkGroupAsRead: false,
        notificationsMarkGroupAsUnread: false,
    },
}

// Слайс загрузки
const loadSlice = createSlice({
    name: 'load',
    initialState,
    reducers: {
        // Установить состояние загрузки в "Загружено"
        setLoaded(
            state: LoadReducerTypes.InitialState,
            action: PayloadAction<LoadReducerTypes.LoaderTypes>,
        ) {
            state.loadingComplete[action.payload as LoadReducerTypes.LoaderTypes] = true
        },
        // Установить состояние загрузки в "Загружается"
        setLoading(
            state: LoadReducerTypes.InitialState,
            action: PayloadAction<LoadReducerTypes.LoaderTypes>,
        ) {
            state.loadingComplete[action.payload as LoadReducerTypes.LoaderTypes] = false
        },
    },
})

// Экспорт создателей действий
export const { setLoaded, setLoading } = loadSlice.actions
// Экспорт редьюсера
export default loadSlice.reducer
