import React from 'react'
// Node
import { CircularProgress } from '@mui/material'
import { Notifications as NotificationsIcon } from '@mui/icons-material'
import { useKeycloak } from '@react-keycloak/web'
// API
import axios from '@api/instance'
// Types
import MaterialMenuTypes from '@materialComponents/MaterialMenu/types/MaterialMenu'
import TopMenuBlockTypes from '@blocks/TopMenuBlock/types/TopMenuBlock'

export const useMenuItems = (): MaterialMenuTypes.ComponentProps['menuItems'] => {
    const { keycloak } = useKeycloak()
    return [
        { name: 'Профиль', link: '/profile' },        
        { name: 'Выход', onClick: () => {
            // Установка заголовка Authorization для экземпляра axios
            axios.setAuthHeader('')
            // Выход из сеанса keycloak
            keycloak.logout() 
        }},
    ]
}

export const useMobileMenuItems = (props: TopMenuBlockTypes.MapStateToProps): MaterialMenuTypes.ComponentProps['menuItems'] => { 
    // Преобразование пропсов в локальные константы
    const { unreadCount } = props
    
    return [
        {
            icon: <NotificationsIcon />,
            name: 'Уведомления',
            badgeContent: unreadCount !== null ? unreadCount : <CircularProgress color="inherit" size={10} />,
            label: `посмотреть ${ unreadCount } новых уведомлений`,
            link: '/notifications',
        },
    ]
}
