// Node
import _ from 'lodash-contrib'
// Types
import APITypes from '@api/types/api'

// Маппинг свойств, полученных из API в свойства InitialState
const APIToInitialStatePropsMapping = {
    idInformation: 'id',
    pictureUrl: 'cover',
    pubdate: 'createdAt',
    description: 'text',
    content: 'text',
}

export const newsItemBuilderMapper = {
    dataMapping(response: APITypes.AxiosResponse) {
        // Маппинг свойств, полученных из API в свойства InitialState
        return _.renameKeys(response.data, APIToInitialStatePropsMapping)
    },
}
