// Node
import { useDispatch } from 'react-redux'
// Redux
import { setCurrentEducation } from '@redux/reducers/education-list-reducer'

// Элементы меню выпадающего списка с обучениями
export const GetEducationMenuItems = (props) => {
    // Преобразование пропсов в локальные константы
    const { currentEducation, educations, setActiveTabIndex } = props

    // Определение метода dispatch
    const dispatch = useDispatch()

    // Проход по всем обучениям
    return educations?.map((educationItem) => ({
        name: educationItem?.display,
        selected: currentEducation?.educationId === educationItem?.educationId,
        onClick: () => {
            dispatch(setCurrentEducation(educationItem))
            setActiveTabIndex(0)
        },
    }))
}
