import React from 'react'
// Node
import { Box, Skeleton } from '@mui/material'
import config from 'react-global-configuration'
// Types
import MaterialListTypes from '@materialComponents/MaterialList/types/MaterialList'

/*
 * Скелетон учебного плана страницы "Мое обучение"
 */

// Количество элементов списка
const listItemCount = 6

// Анимация скелетона
const animation = config.get('skeleton.animation')

// Массив данных для отображения скелетона
const EducationItemSkeleton = {
    specName: 
        <Box sx={{ mt: 1 }} >
            <Skeleton variant="text" width={215} animation={animation} />
        </Box>,   
    documents: 
        <Box sx={{ mt: 1 }} >    
            <Skeleton variant="text" sx={{ mt: 1 }} height={24} width={100} animation={animation} />
            <Skeleton variant="text" sx={{ mt: 1 }} height={24} width={50} animation={animation} />
            <Skeleton variant="text" sx={{ mt: 1 }} height={24} width={150} animation={animation} />
            <Skeleton variant="text" sx={{ mt: 1 }} height={24} width={80} animation={animation} />
        </Box>,
    listItems: [] as MaterialListTypes.ComponentProps['listItems'],
}

// Формирование массивов данных для отображения скелетона
// Проход по элементам списка
for (let i = 0; i < listItemCount; i++) {
    EducationItemSkeleton.listItems?.push({
        primary: <Skeleton variant="text" width={160} animation={animation} />,
        secondary: <Skeleton variant="text" width={70} animation={animation} />,
        avatar: <Skeleton variant="circular" width={40} height={40} animation={animation} />,
    })
}

// Экспорт скелетона
export default EducationItemSkeleton
