// Node
import { useTheme } from '@mui/material'
// Types
import NotificationsGroupPageTypes from '../components/NotificationsGroupPage/types/NotificationsGroupPage'

type TagStyle = NotificationsGroupPageTypes.Tag['style'] | string

// Получить цвет бэйджа
export const getBadgeColor = (style: TagStyle) => {  
    // Тема MUI
    const theme = useTheme()    
    
    const colors: {[style: TagStyle]: string} = {
        'PRIMARY':          theme.palette.primary.main,
        'SECONDARY':        theme.palette.secondary.main,
        'VERY_IMPORTANT':   '#de3163',
        'IMPORTANT':        '#ff595e',
        'WARNING':          '#edc949',
        'ADVERT':           '#4e79a7',
        'SUCCESS':          '#99d98c',
        'INFO':             '#76b7b2',
        'EXPIRED':          '#90a955',
        'GRAY_LIGHT':       '#c9d4da',
        'GRAY_MEDIUM':      '#bab0ab',
        'GRAY_DARK':        '#909090',
        'BLUE':             '#66ccff',
        'INDIGO':           '#4b0082',
        'PURPLE':           '#af7aa1',
        'PINK':             '#ff9da7',
        'RED':              '#e15759',
        'ORANGE':           '#f28e2c',
        'YELLOW':           '#ffc300',
        'GREEN':            '#2dc26b',
        'TEAL':             '#2ec4b6',
        'CYAN':             '#48d1cc',
    }
    
    return colors[style]    
}