// Node
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// API
import { admissionAPI } from '@api/endpoints/admissionAPI'
// Types
import AdmissionListReducerTypes from '@redux/reducers/types/admission-list-reducer'
import { AppDispatchType } from '@redux/store'
import APITypes from '@api/types/api'
// Создатели действий загрузки
import { setLoading, setLoaded } from './load-reducer'

// Начальное состояние
const initialState: AdmissionListReducerTypes.InitialState = {
    // Список поступлений
    admissions: [],
    // Текст кнопки заполнения заявлений
    submitButtonText: '',
    // Сообщение-подсказка
    helpMessage: '',
    // Доп сообщение
    helpMessageExtended: null,
}

// Слайс списка поступлений
const admissionListSlice = createSlice({
    name: 'admissionList',
    initialState,
    reducers: {
        // Установить данные списка поступлений
        setAdmissionList(
            state: AdmissionListReducerTypes.InitialState,
            action: PayloadAction<AdmissionListReducerTypes.InitialState>,
        ) {
            Object.assign(state, action.payload)
        },
    },
})

// Экспорт создателей действий
export const { setAdmissionList } = admissionListSlice.actions
// Экспорт редьюсера
export default admissionListSlice.reducer

//THUNK CREATORS

// Получение данных списка поступлений
export const getAdmissionList = () => {
    return (dispatch: AppDispatchType) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading('admissionList'))
        // Получение данных через API списка поступлений
        admissionAPI.getAdmissionList().then((response: APITypes.AxiosResponse) => {
            // Установить данные списка поступлений
            dispatch(setAdmissionList(response))
            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded('admissionList'))
        })
    }
}
