import React from 'react'
// Node
import { ClickAwayListener, Link, Tooltip } from '@mui/material'
// Types
import SecretFieldTypes from './types/SecretField'

/*
 * Компонент "Секретное поле"
 */

const SecretField: React.FC<SecretFieldTypes.ComponentProps> = (props) => {
    // Преобразование пропсов в локальные константы
    const { fieldId, linkText, linkSX, linkUnderline, secretValue, onTooltipOpen, onTooltipClose } =
        props

    // Локальное значение маркера открытия тултипа
    const [tooltipOpen, setTooltipOpen] = React.useState(false)

    // Обработка закрытия тултипа
    const handleTooltipClose = () => {
        onTooltipClose && onTooltipClose()
        setTooltipOpen(false)
    }

    // Обработка открытия тултипа
    const handleTooltipOpen = () => {
        setTooltipOpen(true)
    }

    return (
        <ClickAwayListener key={`secretField_${fieldId}`} onClickAway={handleTooltipClose}>
            <Tooltip
                PopperProps={{
                    disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={tooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={`${secretValue}`}
                arrow
            >
                <Link
                    onClick={() => {
                        onTooltipOpen && onTooltipOpen()
                        handleTooltipOpen()
                    }}
                    sx={linkSX}
                    underline={linkUnderline}
                >
                    {linkText}
                </Link>
            </Tooltip>
        </ClickAwayListener>
    )
}

// Свойства по умолчанию
SecretField.defaultProps = {
    linkText: 'показать',
    linkSX: { cursor: 'pointer' },
    linkUnderline: 'hover',
}

// Экспорт компонента
export default SecretField
