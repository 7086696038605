// Node
import config from 'react-global-configuration'
// Types
import ConfigTypes from '../types/Config'
// Own
import { mockEndpoints } from './endpoints/mock'
import { realEndpoints } from './endpoints/real'

/*
 * Настройки приложения "Личный кабинет"
 */

// Объект с эндпоинтами из API
const endpointsObject: {[param: string]: ConfigTypes.EndpointRecord} = 
    {'mock': mockEndpoints, 'real': realEndpoints}

// Тип данных API
const backendApiDataType = 
    ['mock', 'real'].includes(`${process.env.BACKEND_API_DATA_TYPE}`)
    ? `${process.env.BACKEND_API_DATA_TYPE}`
    : 'real'

// Тип запуска приложения
const appStartType = 
    ['console', 'docker'].includes(`${process.env.APP_START_TYPE}`)
    ? `${process.env.APP_START_TYPE}`
    : 'docker'

// Объект конфигурации
const configObject: ConfigTypes.Config = {
    // Настройки Keycloak
    keycloak: {
        realm: process.env.KEYCLOAK_REALM,
        clientId: process.env.KEYCLOAK_CLIENT_ID,
        url: process.env.KEYCLOAK_URL,
    },
    // Настройки API сервера
    api: {
        baseURL: 
            appStartType === 'docker' || backendApiDataType === 'mock'
            ? 'api/'
            : `${process.env.BACKEND_API_URL}`,
        ...endpointsObject[backendApiDataType],
        interceptors: {
            response: {
                retryCount: 6,
                maxBackoff: 64000,
            },
        },
    },
    skeleton: {
        animation: 'pulse',
    },
}
// Глобальные настройки (общие для всех режимов)
config.set(configObject, { freeze: false })

// Настройки режима "Разработка"
config.set({}, { environment: 'development' })

// Настройки режима "Продакшен"
config.set({}, { environment: 'production' })
