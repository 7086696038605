import React from 'react'
// Node
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
// Types
import MaterialListTypes from './types/MaterialList'
// Own
import useStyleMui from './styles/style.mui'

/*
 * Компонент "Список" (обертка над MaterialUI)
 */

// Экспорт компонента
export const MaterialList: React.FC<MaterialListTypes.ComponentProps> = (props) => {
    // Преобразование пропсов в локальные константы
    const { listItems, listSX } = props
    const styleMui = useStyleMui(props)

    return (
        <List sx={listSX} className={styleMui.root}>
            {
                // Маппинг массива элементов списка
                listItems?.map((item, index: number) => (
                    <ListItem key={`muiListItem_${index}`}>
                        {item.avatar && (
                            <ListItemAvatar>
                                <Avatar>{item.avatar}</Avatar>
                            </ListItemAvatar>
                        )}
                        <ListItemText primary={item.primary} secondary={item.secondary} />
                    </ListItem>
                ))
            }
        </List>
    )
}

// Свойства по умолчанию
MaterialList.defaultProps = {
    listItems: [],
    listSX: {},
}

// Экспорт компонента
export default MaterialList
