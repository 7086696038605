import React from 'react'
// Node
import { connect } from 'react-redux'
import _ from 'lodash-contrib'
import config from 'react-global-configuration'
import { Box, Button, CircularProgress, Pagination, Tooltip } from '@mui/material'
import { Chat as ReadIcon, MarkUnreadChatAlt as UnreadIcon } from '@mui/icons-material'
// Redux
import { getNotificationsGroup, markNotificationsGroupAsRead, markNotificationsGroupAsUnread 
} from '@redux/reducers/notifications-reducer'
// Types
import { AppStateType } from '@redux/store'
import NotificationsGroupTypes from './types/NotificationsGroup'
// Own
import { NotificationsGroupPage } from '../../components'
import useStyleMui from './styles/style.mui'
import NotificationsGroupSkeleton from './skeleton'

/*
 * Компонент "Элемент группы уведомлений"
 */

const NotificationsGroup: React.FC<
    NotificationsGroupTypes.ComponentProps 
    & NotificationsGroupTypes.MapStateToProps 
    & NotificationsGroupTypes.MapDispatchToProps> = (
    props,
) => {
    // Преобразование пропсов в локальные константы
    const { 
        groupId, notificationsPage, getNotificationsGroup, 
        markNotificationsGroupAsRead, markNotificationsGroupAsUnread, 
        paginationStore, loadStore 
    } = props        
    
    const styleMui = useStyleMui()
    
    // Загрузчик группы уведомлений
    const groupLoadingComplete = loadStore.loadingComplete[`notificationsGroup${groupId}`]    

    // Пагинация группы уведомлений
    const groupPagination = paginationStore[`notificationsGroup${groupId}`]

    // Количество страниц пагинации
    const pagesCount = !_.isEmpty(groupPagination) 
        ? Math.ceil(groupPagination.totalItems / groupPagination.itemsPerPage)
        : 0
    
    // Событие изменения страницы
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {        
        // Получить одну группу уведомлений в списке с номером страницы
        getNotificationsGroup(groupId, value)
    }

    // Установка локального значения маркера "прочитано"
    const [isMarkAsReadCompleted, setIsMarkAsReadCompleted] = React.useState(true)
    
    // Событие клика по "Отметить все прочитанным"
    const handleMarkGroupAsRead = () => {
        setIsMarkAsReadCompleted(false)
        markNotificationsGroupAsRead(groupId, () => setIsMarkAsReadCompleted(true))
    }

    // Установка локального значения маркера "не прочитано"
    const [isMarkAsUnreadCompleted, setIsMarkAsUnreadCompleted] = React.useState(true)
    
    // Событие клика по "Отметить все непрочитанным"
    const handleMarkGroupAsUnread = () => {
        setIsMarkAsUnreadCompleted(false)
        markNotificationsGroupAsUnread(groupId, () => setIsMarkAsUnreadCompleted(true))
    }
    
    return (
        <>
            { pagesCount > 1
                ? <Pagination
                    className={styleMui.pagination}
                    sx={{ mx: 'auto', mb: '1rem' }}
                    count={pagesCount}
                    page={!_.isEmpty(groupPagination) 
                        ? groupPagination.currentPage 
                        : config.get('api.notificationsGroup.pagination.currentPage')
                    }
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton                
                /> : []
            }
            { groupLoadingComplete
                ? <>
                    <Box sx={{textAlign: 'right', width: '100%'}}>
                        <Tooltip placement="top" arrow title={'Отметить все прочитанным'}>
                            <Button variant="outlined" color={'primary'} sx={{mr: 2}}        
                                onClick={handleMarkGroupAsRead}
                                disabled={!isMarkAsReadCompleted || !isMarkAsUnreadCompleted}
                            >
                                { isMarkAsReadCompleted 
                                    ? <ReadIcon />
                                    : <CircularProgress size={24} />
                                }                         
                            </Button>
                        </Tooltip>
                        <Tooltip placement="top" arrow title={'Отметить все непрочитанным'}>
                            <Button variant="outlined" color={'primary'}                      
                                onClick={handleMarkGroupAsUnread}
                                disabled={!isMarkAsReadCompleted || !isMarkAsUnreadCompleted}                        
                            >
                                { isMarkAsUnreadCompleted 
                                    ? <UnreadIcon />
                                    : <CircularProgress size={24} />
                                }
                            </Button>
                        </Tooltip>
                    </Box>
                    
                    { notificationsPage?.content?.map(
                        (content: NotificationsGroupTypes.NotificationListItem, index: number) => (                    
                            <NotificationsGroupPage
                                key={`notificationsPageContent${content?.id}`}
                                content={content}
                                isLastItem={index === notificationsPage?.content?.length - 1}                        
                            />
                    )) }
                </>
                : <NotificationsGroupSkeleton 
                    itemsCount={!_.isEmpty(groupPagination)
                        ? groupPagination.itemsPerPage
                        : config.get('api.notificationsGroup.pagination.itemsPerPage')
                    } 
                />
            }
            { pagesCount > 1
                ? <Pagination
                    className={styleMui.pagination}                
                    sx={{ mx: 'auto', mt: '1rem' }}
                    count={pagesCount}
                    page={!_.isEmpty(groupPagination) 
                        ? groupPagination.currentPage 
                        : config.get('api.notificationsGroup.pagination.currentPage')
                    }
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton                
                /> : []
            }
        </>
    )
}

// Маппинг стейта в пропсы страницы
const mapStateToProps = (state: AppStateType): NotificationsGroupTypes.MapStateToProps => ({
    paginationStore: state.paginationStore,
    loadStore: state.loadStore,    
})

// Экспорт компонента
export default connect(mapStateToProps, { 
    getNotificationsGroup, markNotificationsGroupAsRead, markNotificationsGroupAsUnread 
})(NotificationsGroup)
