// Node
import config from 'react-global-configuration'
// API
import axios from '@api/instance'
// Types
import { AuthClientTokens } from '@react-keycloak/core/lib/types'
import APITypes from '@api/types/api'

// Экспорт API авторизации
export const authAPI = {
    handleTokens(tokens: AuthClientTokens, keycloak: APITypes.KeycloakType) {
        window.keycloak = keycloak
        // Если провайдер вернул токен
        if (tokens?.token) {
            // Установка заголовка Authorization для экземпляра axios
            axios.setAuthHeader(tokens.token)

            // Время жизни токена(мс)
            const tokenLiveTime: number = Math.round(
                (((keycloak.tokenParsed!.exp as number) + keycloak.timeSkew!) as number) * 1000 - // Время окончания жизни токена(мс)
                    new Date().getTime(), // Текущее время(мс)
            )
            // Обновить токен раньше окончания времени жизни(мс)
            const updateBeforeTime = 10000

            // Выполнить обновление токена через заданное время
            setTimeout(() => {
                // Обновление токена
                keycloak.updateToken(-1)
            }, tokenLiveTime - updateBeforeTime)

            return true
        }
        // Если провайдер НЕ вернул токен
        else {
            // Установка заголовка Authorization для экземпляра axios
            axios.setAuthHeader('')
            // Вызов аутентификации
            keycloak.login()
            return false
        }
    },
    getAuthData() {
        return axios[config.get('api.auth.type') as APITypes.AxiosType]
            .get(config.get('api.auth.url'))
            .then((response: APITypes.AxiosResponse) => {
                return response.data
            })
            .catch(() => {
                return null
            })
    },
    getAccounts() {
        return axios[config.get('api.accounts.type') as APITypes.AxiosType]
            .get(config.get('api.accounts.url'))
            .then((response: APITypes.AxiosResponse) => {
                return response.data
            })
            .catch(() => {
                return null
            })
    },
}
