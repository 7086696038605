import React from 'react'
// Node
import { Grid, Skeleton } from '@mui/material'
import config from 'react-global-configuration'

/*
 * Скелетон страницы "Новости"
 */

// Массив данных для отображения скелетона
const NewsPageSkeleton: React.FC<{ latestCard?: boolean; latestCardLarge?: boolean }> = (props) => {
    // Преобразование пропсов в локальные константы
    const { latestCard, latestCardLarge } = props

    // Анимация скелетона
    const animation = config.get('skeleton.animation')

    return (
        <Grid item xs={12} sm={latestCardLarge ? 12 : 6} md={latestCardLarge ? 6 : 3}>
            <Skeleton
                variant="rectangular"
                width={'100%'}
                height={latestCardLarge || latestCard ? 310 : 365}
                animation={animation}
                sx={{ borderRadius: '16px' }}
            />
        </Grid>
    )
}

// Экспорт скелетона
export default NewsPageSkeleton
