import React from 'react'
// Node
import { Typography } from '@mui/material'
// Types
import AboutPageTypes from '../../types/AboutPage'
import AuthAPITypes from '@api/endpoints/authAPI/types/authAPI'
import MaterialTabsTypes from '@materialComponents/MaterialTabs/types/MaterialTabs'
// Own
import AboutPageSkeleton from '../../skeleton'
import { AccountAttributeContainer } from './components/AccountAttribute'

// Экспорт данныех рабочего пространства
export const useWorkspace = (
    props: AboutPageTypes.MapStateToProps,
): MaterialTabsTypes.ComponentProps['tabs'] => {
    // Преобразование пропсов в локальные константы
    const { accounts, loadingAccountsComplete } = props

    // Массив аккаунтов
    const accountsList: MaterialTabsTypes.ComponentProps['tabs'] = []

    // Количество вкладок аккаунтов
    const accountsTabsCount = 8

    // Если загрузка не завершена
    if (!loadingAccountsComplete) {
        // Проход по вкладкам аккаунтов
        for (let i = 0; i < accountsTabsCount; i++) {
            // Формирование элемента списка
            accountsList.push({
                // Заголовок вкладки
                label: AboutPageSkeleton?.accountsTabs?.label,
                // Содержимое вкладки
                content: AboutPageSkeleton?.accountsTabs?.content,
            })
        }
    } else {
        Array.isArray(accounts) &&
        accounts?.forEach((accountItem: AuthAPITypes.AccountDto) => {
            const accountAttributes: Array<React.ReactNode> = []
            accountItem?.attributes?.forEach(
                (accountAttribute: AuthAPITypes.AccountAttribute, index: number) => {
                    accountAttributes.push(
                        <AccountAttributeContainer
                            key={`accountAttributeContainer_${index}`}
                            object={accountAttribute}
                        />,
                    )
                },
            )

            // Формирование элемента списка
            accountsList.push({
                // Заголовок вкладки
                label: accountItem?.account?.name,
                // Содержимое вкладки
                content: (
                    <>
                        <Typography variant="h6">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: accountItem?.account?.description,
                                }}
                            />
                        </Typography>
                        <Typography>
                            <span
                                dangerouslySetInnerHTML={{ __html: accountItem?.account?.manual }}
                            />
                        </Typography>
                        {accountAttributes}
                    </>
                ),
            })
        })
    }

    // Возврат сформированного массива аккаунтов
    return accountsList
}
