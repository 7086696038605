import React from 'react'
// Node
import { Grid, Skeleton } from '@mui/material'
import config from 'react-global-configuration'
import _ from 'lodash-contrib'

/*
 * Скелетон компонента "Элемент группы уведомлений"
 */

// Массив данных для отображения скелетона
const NotificationsGroupSkeleton: React.FC<{ itemsCount: number }> = (props) => {
    // Преобразование пропсов в локальные константы
    const { itemsCount } = props

    // Анимация скелетона
    const animation = config.get('skeleton.animation')

    return (
        <>
            { Array.from({length: itemsCount}, (item: typeof Array, index: number) => (           
                <Grid 
                    key={`NotificationsGroupSkeleton_${index}`} 
                    sx={{ pt: '20px', pb: '3px' }}
                    container
                >
                    <Grid item xs={12}>
                        <Skeleton                        
                            variant="text"
                            width={`calc(90% - ${_.random(100) + 'px'})`}                            
                            animation={animation}                            
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Skeleton                        
                            variant="text"
                            width={`calc(90% - ${_.random(100) + 'px'})`}                            
                            animation={animation}
                            sx={{ pt: '4px' }}
                        />
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={2}>
                        <Skeleton                        
                            variant="text"
                            width={`calc(100% - ${_.random(50) + 'px'})`}                            
                            animation={animation}
                            sx={{ float: 'right', pt: '4px' }}
                        />
                    </Grid>
                    { index !== itemsCount - 1
                        ? <Grid item xs={12}>
                            <Skeleton                        
                                variant="text"
                                width={'100%'}
                                height={5}
                                animation={animation}
                                sx={{ mt: '22px' }}                            
                            />
                        </Grid>
                        : []
                    }
                </Grid>
            ))}
        </>
    )
}

// Экспорт скелетона
export default NotificationsGroupSkeleton
