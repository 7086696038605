// Node
import config from 'react-global-configuration'
// Types
import NewsAPITypes from '@api/endpoints/newsAPI/types/newsAPI'
// Own
import { newsItemBuilderMapper } from './mapper'

export const newsItemBuilderOriginal = {
    dataForGet(page: number) {
        return config.get('api.newsItem.url') + `/${page}` + '.json'
    },
    dataMapping(response: NewsAPITypes.NewsListItem) {
        return newsItemBuilderMapper.dataMapping(response)
    },
}
