// Node
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// API
import { menuAPI } from '@api/endpoints/menuAPI'
// Types
import MenuReducerTypes from '@redux/reducers/types/menu-reducer'
import { AppDispatchType } from '@redux/store'
import APITypes from '@api/types/api'
// Создатели действий загрузки
import { setLoading, setLoaded } from './load-reducer'

// Начальное состояние
const initialState: MenuReducerTypes.InitialState = {
    // УРЛ активного пункта меню
    activeMenuURL: '/profile',
    // Элементы(пункты) меню
    menuItems: [],
}

// Слайс меню
const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        // Установить УРЛ активного пункта меню
        setActiveMenuURL(
            state: MenuReducerTypes.InitialState,
            action: PayloadAction<MenuReducerTypes.InitialState['activeMenuURL']>,
        ) {
            state.activeMenuURL = action.payload
        },
        // Установить элементы меню
        setMenuItems(
            state: MenuReducerTypes.InitialState,
            action: PayloadAction<MenuReducerTypes.InitialState['menuItems']>,
        ) {
            state.menuItems = action.payload
        },
    },
})

// Экспорт создателей действий
export const { setActiveMenuURL, setMenuItems } = menuSlice.actions
// Экспорт редьюсера
export default menuSlice.reducer

//THUNK CREATORS

// Получение элементов меню
export const getMenuItems = () => {
    return (dispatch: AppDispatchType) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading('menu'))
        // Получение данных через API меню
        menuAPI.getMenuItems().then((response: APITypes.AxiosResponse) => {
            // Установить элементы меню
            dispatch(setMenuItems(response))
            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded('menu'))
        })
    }
}
