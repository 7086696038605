// Node
import { makeStyles } from '@mui/styles'
import { Theme, useTheme } from '@mui/material/styles'

// Конструктор стилей
const useStyleMui = () => {
    // Использовать текущую тему
    const theme: Theme = useTheme()
    return makeStyles(() => ({
        hide: {
            display: 'none',
        },
        header: {
            flexGrow: 1,
        },
        logo: {
            marginRight: '12px',
        },
        menuButton: {
            marginRight: theme.spacing(2),
            padding: `calc(${theme.spacing(1)} * 1.5)`,
        },
    }))()
}

// Экспорт конструктора стилей
export default useStyleMui
