import React from 'react'
// Node
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
// Types
import MaterialCardTypes from './types/MaterialCard'
// Own
import useStyleMui from './styles/style.mui'

/*
 * Компонент "Карточка" (обертка над MaterialUI)
 */

// Экспорт компонента
export const MaterialCard: React.FC<MaterialCardTypes.ComponentProps> = ({
    cardImage,
    cardImageAlt,
    cardImageStyle,
    cardHeader,
    cardText,
    cardButtons,
    className,
}) => {
    const styleMui = useStyleMui({ cardImageStyle })

    return (
        <Card className={className} {...{ sx: { maxWidth: 345 } }}>
            {cardImage && (
                <CardMedia
                    component="img"
                    alt={cardImageAlt}
                    image={cardImage}
                    className={styleMui.cardMedia}
                />
            )}
            <CardContent>
                {cardHeader && (
                    <Typography gutterBottom variant="h5" component="div">
                        {cardHeader}
                    </Typography>
                )}
                {cardText && (
                    <Typography variant="body2" color="secondary">
                        {cardText}
                    </Typography>
                )}
            </CardContent>
            {cardButtons && (
                <CardActions>
                    {cardButtons.map((button, i) => (
                        <Button
                            key={`cardButton_${i}`}
                            size={(button.size as MaterialCardTypes.ButtonSize) ?? 'small'}
                        >
                            {button.text}
                        </Button>
                    ))}
                </CardActions>
            )}
        </Card>
    )
}

// Свойства по умолчанию
MaterialCard.defaultProps = {}

// Экспорт компонента
export default MaterialCard
