// Node
import _ from 'lodash-contrib'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// API
import { educationAPI } from '@api/endpoints/educationAPI'
// Types
import EducationListReducerTypes from '@redux/reducers/types/education-list-reducer'
import { AppDispatchType } from '@redux/store'
import APITypes from '@api/types/api'
// Создатели действий загрузки
import { setLoading, setLoaded } from './load-reducer'

// Начальное состояние
const initialState: EducationListReducerTypes.InitialState = {
    // Текущее обучение
    currentEducation: null,
    // План обучения
    educationItem: null,
    // Список обучений
    educations: [],
}

// Слайс списка обучений
const educationListSlice = createSlice({
    name: 'educationList',
    initialState,
    reducers: {
        // Установить данные списка обучений
        setEducationList(
            state: EducationListReducerTypes.InitialState,
            action: PayloadAction<EducationListReducerTypes.InitialState['educations']>,
        ) {
            state.educations = action.payload
        },
        // Установить текущее обучение
        setCurrentEducation(
            state: EducationListReducerTypes.InitialState,
            action: PayloadAction<EducationListReducerTypes.InitialState['currentEducation']>,
        ) {
            state.currentEducation = action.payload
        },
        // Установить план обучения
        setEducationItem(
            state: EducationListReducerTypes.InitialState,
            action: PayloadAction<EducationListReducerTypes.InitialState['educationItem']>,
        ) {
            state.educationItem = action.payload
        },
    },
})

// Экспорт создателей действий
export const { setEducationList, setCurrentEducation, setEducationItem } =
    educationListSlice.actions
// Экспорт редьюсера
export default educationListSlice.reducer

//THUNK CREATORS

// Получение данных списка обучений
export const getEducationList = () => {
    return (dispatch: AppDispatchType) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading('educationList'))
        // Получение данных через API списка обучений
        educationAPI.getEducationList().then((response: APITypes.AxiosResponse) => {
            // Установить данные списка обучений
            dispatch(setEducationList(response))
            if (!_.isEmpty(response) && !_.isEmpty(response[0])) {
                // Установить текущее обучение
                dispatch(setCurrentEducation(response[0]))
            }
            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded('educationList'))
        })
    }
}

// Получение данных плана обучения
export const getEducationItem = (
    idStudentStart: EducationListReducerTypes.EducationListItem['educationId'],
) => {
    return (dispatch: AppDispatchType) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading('educationItem'))
        // Получение данных через API плана обучения
        educationAPI.getEducationItem(idStudentStart).then((response: APITypes.AxiosResponse) => {
            // Установить данные плана обучения
            dispatch(setEducationItem(response))
            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded('educationItem'))
        })
    }
}
