import React from 'react'
// Node
import { LabelImportant as LabelImportantIcon } from '@mui/icons-material'
// Types
import AboutPageTypes from '../types/AboutPage'
import MaterialListTypes from '@materialComponents/MaterialList/types/MaterialList'
// Own
import AboutPageSkeleton from '../skeleton'

// Экспорт элементов списка
export const useListItems = (
    props: AboutPageTypes.MapStateToProps,
): MaterialListTypes.ComponentProps['listItems'] => {
    // Если загрузка не завершена
    if (!props.loadingAuthComplete) {
        // Возврат массив элементов списка из скелетонов
        return AboutPageSkeleton.listItems
    }
    // Поля профиля пользователя, которые попадут в список
    const usersFields: AboutPageTypes.UsersFields = {
        idE: { title: 'Табельный номер' },
        sex: { title: 'Пол', value: { 'MALE': 'мужской', 'FEMALE': 'женский' } },
        birthDateDisplay: { title: 'Дата рождения' },
        age: { title: 'Возраст' },
        birthplace: { title: 'Место рождения' },
        citizenshipDisplay: { title: 'Гражданство' },
        insurancePension: { title: 'СНИЛС' },
        inn: { title: 'ИНН' },
        divisionName: { title: 'Подразделение' },
        post: { title: 'Должность' },
    }

    // Массив элементов списка
    const listItems: MaterialListTypes.ComponentProps['listItems'] = []
    // Если пользователь - не пустой объект
    if (props?.user) {
        // Проход по всем полям профиля пользователя, которые попадут в список
        for (const key in usersFields) {
            // Формирование элемента списка
            listItems.push({
                // Заголовок поля профиля
                primary: usersFields[key].title,
                // Значение поля профиля
                secondary: usersFields[key].value
                    ? usersFields[key].value![props?.user[key]]
                    : props?.user[key],
                // Иконка элемента списка
                avatar: <LabelImportantIcon />,
            })
        }
    }

    // Возврат сформированного массива элементов списка
    return listItems
}
