import React from 'react'
// Node
import { Box, Dialog, AppBar, Toolbar, IconButton, Typography, Slide, Grid } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { TransitionProps } from '@mui/material/transitions'
// Types
import MaterialDialogTypes from './types/MaterialDialog'

/*
 * Компонент "Диалог" (обертка над MaterialUI)
 */

// Компонент css-перехода, отвечающий за анимацию появления диалога
const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...(props as any)} />
})

// Экспорт компонента
export const MaterialDialog: React.FC<MaterialDialogTypes.ComponentProps> = (props) => {
    // Преобразование пропсов в локальные константы
    const { showModal, title, contentCaption, content, onClose, 
        titleSX, contentSX, sx, } = props

    return (
        <Dialog
            fullScreen
            open={showModal}
            onClose={() => onClose && onClose()}
            TransitionComponent={Transition}
            sx={sx}
        >
            <AppBar sx={{ position: 'sticky' }}>
                <Toolbar>
                    <Typography sx={titleSX} variant="h6" component="div">
                        {title}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => onClose && onClose()}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Grid
                container                
                sx={{ margin: '0px auto 0px auto', maxWidth: { md: '60%' }, px: '16px' }}
            >
                <Grid item xs={12}>
                    <Box sx={contentSX}>
                        {contentCaption}
                        <Box>
                            {typeof content === 'string' ? (
                                <div dangerouslySetInnerHTML={{ __html: content || '' }} />
                            ) : (
                                content
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Dialog>
    )
}

// Свойства по умолчанию
MaterialDialog.defaultProps = {
    showModal: false,
    titleSX: { ml: 2, mr: 2, flex: 1 },
    contentSX: { ml: 5, mr: 5, pt: 2, flex: 1 },
}

// Экспорт компонента
export default MaterialDialog
