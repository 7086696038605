import React from 'react'
// Node
import clsx from 'clsx'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
// Own
import styleMod from './styles/style.module.css'
import useStyleMui from './styles/style.mui'
import StrapModalTypes from './types/StrapModal'

/*
 * Компонент "Модальное окно" (обертка над ReactStrap)
 */

const StrapModal: React.FC<StrapModalTypes.ComponentProps> = ({
    className,
    modalHeader,
    modalContent,
    showModal,
    modalButtons,
    backdropClassName,
    zIndex,
    windowHeader,
    headerIcon,
    onClose,    
}) => {
    const styleMui = useStyleMui()

    return (
        <div>
            <Modal
                isOpen={showModal}
                className={clsx(styleMod.modal, styleMui.modal, className)}
                backdropClassName={backdropClassName}
                zIndex={zIndex}
            >
                {windowHeader && <h2 className="authWindowHeader">{windowHeader}</h2>}
                {modalHeader && (
                    <ModalHeader>
                        <div className={styleMod.modalTitleText}>{modalHeader}</div>
                        <div className={styleMod.modalTitleIcon}>{headerIcon && headerIcon}</div>
                    </ModalHeader>
                )}
                {modalContent && <ModalBody>{modalContent}</ModalBody>}
                {modalButtons && (
                    <ModalFooter>
                        {modalButtons.map((button, i) => (
                            <Button
                                color={button.color}
                                outline={button.outline}
                                onClick={() => {
                                    button.isCloseBtn
                                        ? // Если кнопка является кнопкой закрытия диалога
                                          onClose && onClose()
                                        : // Если кнопка НЕ является кнопкой закрытия диалога
                                          // и задан колбэк для клика по кнопке
                                          button.onClick && button.onClick()
                                }}
                                key={'modalbutton_' + i}
                            >
                                {button.text}
                            </Button>
                        ))}
                    </ModalFooter>
                )}
            </Modal>
        </div>
    )
}

// Свойства по умолчанию
StrapModal.defaultProps = {
    showModal: false,
    zIndex: 1200,
}

// Экспорт компонента
export default StrapModal
