import React from 'react'
// Node
import { Skeleton } from '@mui/material'
import config from 'react-global-configuration'

/*
 * Скелетон блока "Мое поступление"
 */

// Компонент скелетона
const AdmissionListBlockSkeleton: React.FC = () => {
    // Анимация скелетона
    const animation = config.get('skeleton.animation')

    return (
        <>
            <Skeleton variant="text" width={'70%'} animation={animation} />
            <Skeleton variant="text" width={'30%'} animation={animation} />
            <Skeleton variant="text" width={'50%'} animation={animation} />
            <br />
            <Skeleton variant="rectangular" width={'35%'} height={36} animation={animation} />
            <br />
            <Skeleton variant="rectangular" width={'100%'} height={190} animation={animation} />
        </>
    )
}

// Экспорт скелетона
export default AdmissionListBlockSkeleton
