import React from 'react'
// Node
import { Skeleton } from '@mui/material'
import config from 'react-global-configuration'
// Types
import LeftMenuAreaTypes from '../types/LeftMenuArea'

/*
 * Скелетон области "Левое меню"
 */

// Массив данных для отображения скелетона
const LeftMenuAreaSkeleton: LeftMenuAreaTypes.PanelListItems = []

// Количество групп элементов(пунктов) меню
const groupItemCount = 1
// Количество элементов(пунктов) меню в группе
const listItemCount = 10
// Анимация скелетона
const animation = config.get('skeleton.animation')

// Формирование массива данных для отображения скелетона
// Проход по группам элементов(пунктов) меню
for (let i = 0; i < groupItemCount; i++) {
    const listItemsData = []
    // Проход по элементам(пунктам) меню в группе
    for (let j = 0; j < listItemCount; j++) {
        listItemsData.push({
            text: <Skeleton variant="text" width={195} animation={animation} />,
            icon: <Skeleton variant="rectangular" width={25} animation={animation} />,
        })
    }

    LeftMenuAreaSkeleton.push({
        name: <Skeleton variant="text" width={250} animation={animation} />,
        items: listItemsData,
    })
}

// Экспорт скелетона
export default LeftMenuAreaSkeleton
