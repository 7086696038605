// Node
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// API
import { authAPI } from '@api/endpoints/authAPI'
// Types
import AuthAPITypes from '@api/endpoints/authAPI/types/authAPI'
import AccountsReducerTypes from './types/accounts-reducer'
import { AppGetStateType, AppDispatchType } from '@redux/store'
import APITypes from '@api/types/api'
// Создатели действий загрузки
import { setLoading, setLoaded } from './load-reducer'

// Начальное состояние
const initialState: AccountsReducerTypes.InitialState = {
    currentPasswordAttributeValue: null,
    accounts: [],
}

// Слайс аккаунтов
const accountsSlice = createSlice({
    name: 'accounts',
    initialState,
    reducers: {
        // Записать текущее значение атрибута "Пароль"
        setCurrentPasswordAttributeValue(
            state: AccountsReducerTypes.InitialState,
            action: PayloadAction<AuthAPITypes.AccountAttribute['theValue']>,
        ) {
            state.currentPasswordAttributeValue = action.payload
        },
        // Записать данные пользователя
        setAccounts(
            state: AccountsReducerTypes.InitialState,
            action: PayloadAction<AccountsReducerTypes.InitialState['accounts']>,
        ) {
            state.accounts = action.payload
        },
    },
})

// Экспорт создателей действий
export const { setAccounts, setCurrentPasswordAttributeValue } = accountsSlice.actions
// Экспорт редьюсера аккаунтов
export default accountsSlice.reducer

//THUNK CREATORS

// Получение аккаунтов
export const getAccounts = () => {
    return (dispatch: AppDispatchType) => {
        // Установить состояние загрузки данных об аккаунтах в "Загружается"
        dispatch(setLoading('accounts'))
        // Получение данных через API аутентификации
        authAPI.getAccounts().then((response: APITypes.AxiosResponse) => {
            // Записать данные об аккаунтах
            dispatch(setAccounts(response))
            // Установить состояние загрузки данных об аккаунтах в "Загружено"
            dispatch(setLoaded('accounts'))
        })
    }
}

// Получение текущего значения атрибута "Пароль"
export const getAccountPassword = (
    idAccountAttribute: AuthAPITypes.AccountAttribute['idAccountAttribute'],
) => {
    return (dispatch: AppDispatchType, getState: AppGetStateType) => {
        // Список аккаунтов из стейта
        const accounts = getState().accountsStore.accounts
        // Проход по всем аккаунтам
        accounts?.forEach((accountItem) => {
            // Проход по всем атрибутам аккаунта
            accountItem?.attributes?.forEach((attribute) => {
                // Если айди атрибута совпадает с айди атрибута в параметрах
                if (attribute?.idAccountAttribute === idAccountAttribute) {
                    // Записать текущее значение атрибута "Пароль"
                    dispatch(setCurrentPasswordAttributeValue(attribute?.theValue))
                }
            })
        })
    }
}
