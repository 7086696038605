// Node
import config from 'react-global-configuration'
import axios from 'axios'
// API
import { requestInterceptor } from './interceptors/request-interceptor'
import { responseInterceptor } from './interceptors/response-interceptor'
// Types
import APITypes from './types/api'

//=== НАСТРОЙКА ЭКЗЕМПЛЯРА AXIOS ===

// Создание экземпляра axios с необходимыми настройками
const instance: APITypes.AxiosWrapper['instance'] = axios.create({
    baseURL: config.get('api.baseURL'),
    withCredentials: false,
    headers: {},
})

// Объект-обертка для экземпляра axios
const axiosWrapper: APITypes.AxiosWrapper = {
    // Исходный axios без настроек
    original: axios,
    // Экземпляр axios(настроенный)
    instance,
    // Получить значение заголовка Authorization
    getAuthHeader() {
        // Получить данные из cookie
        const accessTokenCookie = document.cookie.match(/access-token=(.+?)(;|$)/)
        const accessToken = accessTokenCookie && accessTokenCookie[1] ? accessTokenCookie[1] : ''
        return `Bearer ${accessToken}`
    },
    // Задать значение заголовка Authorization
    setAuthHeader(accessToken) {
        // Если cookie нужно удалить
        if(accessToken === '') {
            document.cookie = `access-token=;max-age=-1`
        }
        // Если cookie нужно задать
        else {
            document.cookie = `access-token=${accessToken}`
        }
    },
}
// Инициализация перехватчика запросов axios к серверу
requestInterceptor(axiosWrapper)
// Инициализация перехватчика ответов сервера для axios
responseInterceptor(axiosWrapper)

// Экспорт объекта-обертки для экземпляра axios
export default axiosWrapper
