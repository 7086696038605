import React from 'react'
// Node
import ReactDOM from 'react-dom'
import { Provider, connect } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { createTheme, ThemeProvider } from '@mui/material/styles'
// Config
import './config'
// Theme
import theme from './theme'
// API
import keycloak from '@api/keycloak'
// Redux
import store from '@redux/store'
import { handleTokens } from '@redux/reducers/auth-reducer'
// Types
import AppTypes from './types/App'
// Own
import App from './App'
import reportWebVitals from '@src/reportWebVitals'
import './index.css'
import 'bootstrap/dist/css/bootstrap.css'

// Компонент-обертка над приложением App
const AppWrapper: React.FC<AppTypes.AppWrapperProps> = (props) => {
    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            onTokens={(tokens) => {
                props.handleTokens(tokens, keycloak)
            }}
            initOptions={{ pkceMethod: 'S256', checkLoginIframe: false }}
        >
            <App />
        </ReactKeycloakProvider>
    )
}

// Контейнер компонента-обертки над приложением App
const AppWrapperContainer = connect(null, { handleTokens })(AppWrapper)

// Рендеринг приложения в див с id="root"
ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <ThemeProvider theme={createTheme(theme)}>
                    <AppWrapperContainer />
                </ThemeProvider>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,

    document.querySelector('#root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
