import React from 'react'
// Node
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material'
// Types
import MaterialTableTypes from './types/MaterialTable'
// Own
import MaterialRow from './components/MaterialRow'

/*
 * Компонент "Таблица" (обертка над MaterialUI)
 */

// Экспорт компонента
export const MaterialTable: React.FC<MaterialTableTypes.ComponentProps> = (props) => {
    // Преобразование пропсов в локальные константы
    const { rows, headers, mainHeader, slaveRowTitle, size, headersSX, mainHeaderSX } = props

    // Массив заголовков главных строк таблицы
    const headersContent = []
    for (const key in headers) {
        if (headers[key]?.main) {
            headersContent.push(
                <TableCell sx={headersSX} key={`tableCellMainHeader_${key}`}>
                    {headers[key]?.title}
                </TableCell>,
            )
        }
    }

    // Главный заголовок таблицы
    const mainHeaderContent = []
    if (mainHeader) {
        mainHeaderContent.push(
            <TableHead>
                <TableRow>
                    <TableCell sx={mainHeaderSX} colSpan={headersContent?.length}>
                        {mainHeader}
                    </TableCell>
                </TableRow>
            </TableHead>,
        )
    }

    // Содержит ли таблица второстепенные строки
    let tableHasSlaveRows = false
    rows?.forEach((row) => {
        if (row?.slaveRows?.length) {
            tableHasSlaveRows = true
        }
    })

    return (
        <TableContainer component={Paper}>
            <Table size={size} aria-label="collapsible table">
                {mainHeaderContent}
                <TableHead>
                    {headersContent.length ? (
                        <TableRow>
                            {tableHasSlaveRows ? <TableCell /> : []}
                            {headersContent}
                        </TableRow>
                    ) : (
                        []
                    )}
                </TableHead>
                <TableBody>
                    {rows?.map((row, index) => (
                        <MaterialRow
                            key={`materialRow_${index}`}
                            row={row}
                            headers={headers}
                            tableHasSlaveRows={tableHasSlaveRows}
                            slaveRowTitle={slaveRowTitle}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

// Свойства по умолчанию
MaterialTable.defaultProps = {}

// Экспорт компонента
export default MaterialTable
