import React from 'react'
// Node
import { useTheme } from '@mui/material/styles'
import { Box, Button, MobileStepper, Paper, Typography } from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
// Types
import MaterialSliderTypes from './types/MaterialSlider'
// Own
import useStyleMui from "./styles/style.mui"

/*
 * Компонент "Слайдер" (обертка над MaterialUI)
 */

// Экспорт компонента
export const MaterialSlider: React.FC<MaterialSliderTypes.ComponentProps> = (props) => {
    // Преобразование пропсов в локальные константы
    const { sx, slides, showTitle, showNavButtons, navButtonsMaxWidth,
        prevButtonText, nextButtonText, enableAutoPlay, variant, } = props

    // Стили
    const styleMui = useStyleMui()

    const theme = useTheme()
    const [activeStep, setActiveStep] = React.useState(0)
    const maxSteps = slides ? slides?.length : 0

    const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleStepChange = (step: number) => {
        if (enableAutoPlay) setActiveStep(step)
    }

    return (
        <Box sx={{ maxWidth: '100%', flexGrow: 1, ...sx }}>
            {showTitle
                ? <Paper
                    square
                    elevation={0}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 50,
                        pl: 2,
                        bgcolor: 'background.default',
                    }}
                >
                    <Typography>{slides && slides[activeStep] ? slides[activeStep].label : ''}</Typography>
                </Paper>
                : []
            }
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents={false}
            >
                {slides?.map((slide: MaterialSliderTypes.Slide, index: number) => (
                    <div key={slide.label}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <Box
                                sx={{
                                    height: 'auto',
                                    display: 'block',
                                    maxWidth: '100%',
                                    overflow: 'hidden',
                                    width: '100%',
                                }}
                            >{slide.src}</Box>
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            {showNavButtons && maxSteps > 1
                ? <Box sx={{ maxWidth: navButtonsMaxWidth }}>
                    <MobileStepper
                        sx={showNavButtons ? {} : { margin: 'auto', width: 'fit-content' }}
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        variant={variant}
                        classes={{
                            dot: styleMui.dot,
                            dotActive: styleMui.dotActive,
                        }}
                        nextButton={
                            showNavButtons
                                ? <Button
                                    className={styleMui.button}
                                    size="small"
                                    onClick={handleNext}
                                    disabled={activeStep === maxSteps - 1}
                                >
                                    {nextButtonText}
                                    {theme.direction === 'rtl' ? (
                                        <KeyboardArrowLeft />
                                    ) : (
                                        <KeyboardArrowRight />
                                    )}
                                </Button>
                                : []
                        }
                        backButton={
                            showNavButtons
                                ? <Button
                                    className={styleMui.button}
                                    size="small"
                                    onClick={handleBack}
                                    disabled={activeStep === 0}
                                >
                                    {theme.direction === 'rtl' ? (
                                        <KeyboardArrowRight />
                                    ) : (
                                        <KeyboardArrowLeft />
                                    )}
                                    {prevButtonText}
                                </Button>
                                : []
                        }
                    />
                </Box>
                : []
            }
        </Box >
    )
}

// Свойства по умолчанию
MaterialSlider.defaultProps = {
    slides: [],
    showTitle: false,
    showNavButtons: false,
    navButtonsMaxWidth: 'unset',
    nextButtonText: 'След.',
    prevButtonText: 'Пред.',
    enableAutoPlay: true,
    variant: 'dots',
}

// Экспорт компонента
export default MaterialSlider