import React from 'react'
// Pages
import MockPage from '@pages/AuthPage/MockPage'

/*
 * Область "Аутентификация"
 */

const AuthArea = () => {
    return (
        <>
            <MockPage />
        </>
    )
}

// Экспорт области
export default AuthArea
