// Node
import { styled } from '@mui/system'
import { makeStyles } from '@mui/styles'

// Экспорт конструктора стилизованного инпута
export const useStyledInput = () => {
    return styled('input')({
        display: 'none',
    })
}

// Конструктор стилей
const useStyleMui = () => {
    return makeStyles(() => ({
        materialCard: {
            margin: 'auto',
        },
    }))()
}

// Экспорт конструктора стилей
export default useStyleMui
