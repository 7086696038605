import React from 'react'
// Node
import { IconButton } from '@mui/material'
import { AttachFile as AttachFileIcon } from '@mui/icons-material'
// Own
import { useStyledInput } from '../../styles/style.mui'

/*
 * Блок "Адрес"
 */

const AddressBlock: React.FC = () => {
    // Скрытый инпут для загрузки фото
    const Input = useStyledInput()

    return (
        <>
            <i>Укажите свой адрес регистрации (прописки).</i>
            <br />
            <i>
                Дополнительно Вы можете указать адрес проживания, если он отличается от адреса
                регистрации.
            </i>
            <br />
            <label htmlFor="icon-button-file">
                Указать адрес регистрации
                <Input accept="image/*" id="icon-button-file" type="file" />
                <IconButton color="primary" aria-label="upload picture" component="span">
                    <AttachFileIcon />
                </IconButton>
            </label>
            <br />
            <label htmlFor="icon-button-file">
                Указать фактический адрес
                <Input accept="image/*" id="icon-button-file" type="file" />
                <IconButton color="primary" aria-label="upload picture" component="span">
                    <AttachFileIcon />
                </IconButton>
            </label>
        </>
    )
}

// Экспорт блока
export default AddressBlock
