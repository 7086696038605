// Node
import { makeStyles } from '@mui/styles'
import { Theme, useTheme } from '@mui/material/styles'

// Конструктор стилей
const useStyleMui = () => {
    // Использовать текущую тему
    const theme: Theme = useTheme()

    return makeStyles(() => ({
        dot: {
            cursor: 'pointer',
            height: '10px',
            margin: '8px 6px',
            width: '10px',
        },
        dotActive: {
            backgroundColor: theme.palette.primary.main,
        },
        button: {
            lineHeight: 1,
        },
    }))()
}

// Экспорт конструктора стилей
export default useStyleMui
