import React from 'react'
// Node
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'
import { Button, Grid } from '@mui/material'
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material'
// Blocks
import FillStatusBlock from './blocks/FillStatusBlock'
import PassportBlock from './blocks/PassportBlock'
import EducationBlock from './blocks/EducationBlock'
import AddressBlock from './blocks/AddressBlock'
import ParentsBlock from './blocks/ParentsBlock'
import EntranceTestsBlock from './blocks/EntranceTestsBlock'
import SpecialtyBlock from './blocks/SpecialtyBlock'
import PrivilegesBlock from './blocks/PrivilegesBlock'
import AchievementsBlock from './blocks/AchievementsBlock'
import AttachmentsBlock from './blocks/AttachmentsBlock'
// Components
import MaterialTabs from '@materialComponents/MaterialTabs'
// Redux
import { setShowMode } from '@redux/reducers/admission-reducer'
// Types
import AdmissionStatusBlockTypes from './types/AdmissionStatusBlock'
import MaterialTabsTypes from '@materialComponents/MaterialTabs/types/MaterialTabs'
import { AppStateType } from '@redux/store'

/*
 * Блок "Статус поступления"
 */

// Кеширование блоков
const MemoPassportBlock = React.memo(PassportBlock)
const MemoEducationBlock = React.memo(EducationBlock)
const MemoAddressBlock = React.memo(AddressBlock)
const MemoParentsBlock = React.memo(ParentsBlock)
const MemoEntranceTestsBlock = React.memo(EntranceTestsBlock)
const MemoSpecialtyBlock = React.memo(SpecialtyBlock)
const MemoPrivilegesBlock = React.memo(PrivilegesBlock)
const MemoAchievementsBlock = React.memo(AchievementsBlock)
const MemoAttachmentsBlock = React.memo(AttachmentsBlock)

const AdmissionStatusBlock: React.FC<
    AdmissionStatusBlockTypes.MapDispatchToProps & {
        orientation: MaterialTabsTypes.ComponentProps['orientation']
    }
> = (props) => {
    // Преобразование пропсов в локальные константы
    const { setShowMode, orientation } = props

    const [activeTabIndex, setActiveTabIndex] = React.useState(0)
    
    // Ширина контента
    const contentWidth = useSelector((state: AppStateType) => state.windowParamsStore?.contentWidth)

    // Маркер того, является ли разрешение экрана больше брекпоинта 'sm'
    const isUpSM = useSelector((state: AppStateType) => state.windowParamsStore?.isUpSM)
    
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={7} md={6} lg={5}>
                    <Button
                        variant="outlined"
                        startIcon={<ArrowBackIcon />}
                        onClick={() => {
                            setShowMode('list')
                        }}
                    >
                        Вернуться к списку заявлений
                    </Button>
                </Grid>
                <Grid item xs={12} sm={5} md={6} lg={7}>
                    {activeTabIndex ? (
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setActiveTabIndex(0)
                            }}
                        >
                            Статус заполнения
                        </Button>
                    ) : (
                        []
                    )}
                </Grid>
            </Grid>
            <Grid container sx={{ my: { xs: 0, sm: 3 } }}>
                <Grid item>            
                    <MaterialTabs
                        tabs={[
                            {
                                label: 'Статус заполнения',
                                content: <FillStatusBlock onClick={setActiveTabIndex} />,
                            },
                            { label: 'Паспорт', content: <MemoPassportBlock /> },
                            { label: 'Образование', content: <MemoEducationBlock /> },
                            { label: 'Адрес', content: <MemoAddressBlock /> },
                            { label: 'Родители', content: <MemoParentsBlock /> },
                            { label: 'Вступительные испытания', content: <MemoEntranceTestsBlock /> },
                            { label: 'Специальности', content: <MemoSpecialtyBlock /> },
                            { label: 'Льготы', content: <MemoPrivilegesBlock /> },
                            { label: 'Достижения', content: <MemoAchievementsBlock /> },
                            { label: 'Прикрепление документов', content: <MemoAttachmentsBlock /> },
                        ]}
                        orientation={orientation}
                        tabsWidth={contentWidth}
                        activeTabIndex={activeTabIndex}
                        onClick={setActiveTabIndex}
                        tabsMinHeight={500}
                        tabsMinWidth={162}
                        sticky={true}
                        variant={'scrollable'}
                        tabStyle={{minWidth: 'fit-content'}}
                        tabsListStyle={isUpSM ? {} : {marginLeft: '-15px'}}
                        tabBoxStyle={isUpSM ? {} : {'h6': {fontSize: '1.1rem', lineHeight: '1.3'}}}
                    />
                </Grid>
            </Grid>
        </>
    )
}

// Экспорт блока
export default connect(null, { setShowMode })(AdmissionStatusBlock)
