import React from 'react'
// Node
import { connect } from 'react-redux'
import _ from 'lodash-contrib'
import { Box, Typography } from '@mui/material'
// Components
import NewsCard from '@styledComponents/NewsCard'
import { AvatarStyle } from '@styledComponents/NewsCard/components'
import MaterialDialog from '@materialComponents/MaterialDialog'
// Redux
import { getNewsItem } from '@redux/reducers/news-reducer'
// Types
import NewsCardTypes from '@styledComponents/NewsCard/types/NewsCard'
import { AppStateType } from '@redux/store'
import NewsCardBlockTypes from './types/NewsCardBlock'
// Utils
import { fDate } from '@utils/formatTime'
// Own
import defaultAvatar from '@styledComponents/NewsCard/src/avatars/default.jpg'
import NewsCardBlockSkeleton from './skeleton'

/*
 * Блок "Карточка новости с модальным окном"
 */

export const NewsCardBlock: React.FC<
    NewsCardTypes.ComponentProps &
        NewsCardBlockTypes.MapStateToProps &
        NewsCardBlockTypes.MapDispatchToProps & {
            index: number
            data: { id?: number | null; text?: string }
        }
> = ({
    index,
    data,
    latestCard,
    latestCardLarge,
    hideCover,
    hideAvatar,
    news,
    loadingOnePeaceOfNewsComplete,
    getNewsItem,
}) => {
    // Преобразование пропсов в локальные константы
    const { id, title, text, author, createdAt } = data

    // Установка маркера показывать ли модальное окно
    const [showModal, setShowModal] = React.useState(false)

    return (
        <>
            <NewsCard
                key={`newsCard_${index}`}
                data={_.find(news, { id: id })}
                latestCard={latestCard}
                latestCardLarge={latestCardLarge}
                hideCover={hideCover}
                hideAvatar={hideAvatar}
                onCardClick={() => {
                    getNewsItem(id)
                    setShowModal(true)
                }}
            />

            <MaterialDialog
                title={title}
                contentCaption={
                    <Box sx={{ color: 'text.disabled', display: 'inline-block' }}>
                        <Box sx={{ float: 'left' }}>
                            <AvatarStyle
                                alt={author?.name || 'Администратор'}
                                src={author?.avatarUrl || defaultAvatar}
                                sx={{
                                    position: 'relative',
                                    bottom: 'unset',
                                    left: 0,
                                    width: 40,
                                    height: 40,
                                }}
                            />
                        </Box>
                        <Typography
                            sx={{
                                float: 'left',
                                paddingLeft: '5px',
                                paddingTop: '1rem',
                                color: 'primary.main',
                            }}
                        >
                            {author?.name || 'Администратор'}
                        </Typography>
                        <Typography sx={{ float: 'left', paddingLeft: '15px', paddingTop: '1rem' }}>
                            {fDate(createdAt)}
                        </Typography>
                    </Box>
                }
                content={
                    loadingOnePeaceOfNewsComplete ? (
                        text
                    ) : (
                        <NewsCardBlockSkeleton width={'100%'} shift={100} count={20} />
                    )
                }
                titleSX={{ 
                    mr: 2, flex: 1, py: 1,
                    ml: {xs: 1, sm: 0, md: 2},
                    fontSize: {xs: '1rem', sm: '1.25rem'}, 
                    lineHeight: {xs: '1.4', sm: '1.6'},                      
                }}
                contentSX={{ ml: 1, mr: 1, pt: 2, flex: 1 }}
                showModal={showModal}
                onClose={() => setShowModal(false)}
            />
        </>
    )
}

// Маппинг стейта в пропсы блока
const mapStateToProps = (state: AppStateType): NewsCardBlockTypes.MapStateToProps => ({
    news: state.newsStore?.news,
    loadingOnePeaceOfNewsComplete: state.loadStore.loadingComplete?.newsItem,
})

// Экспорт блока
export default connect(mapStateToProps, { getNewsItem })(NewsCardBlock)
