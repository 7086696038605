// Node
import { makeStyles } from '@mui/styles'
import { Theme, useTheme } from '@mui/material/styles'

// Конструктор стилей
const useStyleMui = () => {
    // Использовать текущую тему
    const theme: Theme = useTheme()
    return makeStyles(() => ({
        avatar: {
            cursor: 'pointer',
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        iconButtonNavLink: {
            '&, &:hover': {
                color: 'inherit',
            },
            '&.active': {         
                color: theme.palette.common.white,
                '& .MuiIconButton-root' : {                    
                    background: theme.palette.grey[400],                                                   
                },
                '& .MuiBadge-root': {
                    color: '#000',
                },                 
            },            
            padding: `calc(${theme.spacing(1)} / 2)`,
        },
    }))()
}

// Экспорт конструктора стилей
export default useStyleMui
