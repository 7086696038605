//Node
import { styled } from '@mui/material/styles'
import { Avatar } from '@mui/material'

/*
 * Стилизованный компонент "Аватар"
 */

const AvatarStyle = styled(Avatar)(({ theme }) => ({
    zIndex: 9,
    width: 32,
    height: 32,
    position: 'absolute',
    left: theme.spacing(3),
    bottom: theme.spacing(-2),
}))

// Экспорт компонента
export default AvatarStyle
