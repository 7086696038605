// Node
import { configureStore } from '@reduxjs/toolkit'
// Reducers
import rootReducer from './reducers/'

// Хранилище
const store = configureStore({
    reducer: rootReducer,
})

// Тип стейта приложения
export type AppStateType = ReturnType<typeof store.getState>
// Тип метода, возвращающего стейт приложения
export type AppGetStateType = () => AppStateType
// Тип диспатча приложения
export type AppDispatchType = typeof store.dispatch

// Экспорт хранилища
export default store
