// Node
import { makeStyles } from '@mui/styles'
import { Theme, useTheme } from '@mui/material/styles'

// Конструктор стилей
const useStyleMui = (props: { drawerWidth: number }) => {
    // Использовать текущую тему
    const theme: Theme = useTheme()
    return makeStyles(() => ({
        drawer: {
            width: props.drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: props.drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            [theme.breakpoints.down('sm')]: {            
                position: 'fixed',
                zIndex: 1200,
            }
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: `calc(${theme.spacing(7)} + 1px)`,
            [theme.breakpoints.up('sm')]: {
                width: `calc(${theme.spacing(9)} + 1px)`,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1.5),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        tooltip: {
            fontSize: '16px',
            lineHeight: '24px',
        },
        link: {
            '&.active': {         
                color: theme.palette.common.white,
                '& .MuiListItem-root' : {                    
                    background: theme.palette.grey[400],                                                   
                },                
            },
            '&:hover': {
                textDecoration: 'none',
            },
            '& .MuiListItem-root' : {
                '&.Mui-selected' : {
                    background: 'transparent',                
                },               
            },
        },
        list: {
            paddingTop: '0px',
        },
        listItem: {
            paddingBottom: `calc(${theme.spacing(1)} / 4)`,
            paddingTop: `calc(${theme.spacing(1)} / 4)`,
            '&.captionItem': {
                paddingBottom: theme.spacing(1),
                paddingTop: theme.spacing(1),
            },
            paddingLeft: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(2),
            },
        },
    }))()
}

// Экспорт конструктора стилей
export default useStyleMui
