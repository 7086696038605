import React from 'react'
// Node
import { Box, Link, Typography } from '@mui/material'
// Components
import { TooltipField } from '@commonComponents/DataFields'
// Types
import LinkFieldTypes from './types/LinkField'

/*
 * Компонент "Поле со ссылкой"
 */

const LinkField: React.FC<LinkFieldTypes.ComponentProps> = (props) => {
    // Преобразование пропсов в локальные константы
    const {
        fieldId,
        withoutLinkText,
        withoutLinkTextPosition,
        linkText,
        linkTextPosition,
        linkURL,
        linkTarget,
        linkAlt,
        linkUnderline,
        linkSX,
        image,
        imageMaxHeight,
        imageSX,
    } = props

    // Контент компонента ссылки
    const linkContent = <>
        {withoutLinkTextPosition === 'before' ? withoutLinkText : []}
        <Link
            href={linkURL}
            target={linkTarget}
            sx={linkSX}
            underline={linkUnderline}
            key={`linkField${fieldId}`}
        >
            {linkTextPosition === 'before' ? <Typography component={'span'}>{linkText}</Typography> : []}
            <Box
                sx={{
                    maxHeight: imageMaxHeight,
                    ml: linkTextPosition === 'before' ? 1 : 0,
                    mr: linkTextPosition === 'after' ? 1 : 0,
                    mt: '-3px',
                    ...imageSX,
                }}
                component="img"
                src={image ? image : ''}
            />
            {linkTextPosition === 'after' ? <Typography component={'span'}>{linkText}</Typography> : []}
        </Link>
        {withoutLinkTextPosition === 'after' ? withoutLinkText : []}
    </>

    return (
        <>
            {
                linkAlt
                    ? <TooltipField title={linkAlt}>
                        {linkContent}
                    </TooltipField>
                    : linkContent
            }
        </>
    )
}

// Свойства по умолчанию
LinkField.defaultProps = {
    withoutLinkTextPosition: 'after',
    linkTextPosition: 'before',
    linkTarget: '_self',
    linkUnderline: 'hover',
    imageMaxHeight: '70px',
}

// Экспорт компонента
export default LinkField
