// Node
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// API
import { admissionAPI } from '@api/endpoints/admissionAPI'
// Types
import AdmissionStatusReducerTypes from '@redux/reducers/types/admission-status-reducer'
import { AppDispatchType } from '@redux/store'
import APITypes from '@api/types/api'
// Создатели действий загрузки
import { setLoading, setLoaded } from './load-reducer'

// Начальное состояние
const initialState: AdmissionStatusReducerTypes.InitialState = {
    // Список элементов чек-листа
    checklist: [],
    // Сообщение-подсказка
    helpMessage: '',
    // Cтатус абитуриента
    status: null,
}

// Слайс статуса поступления
const admissionStatusSlice = createSlice({
    name: 'admissionStatus',
    initialState,
    reducers: {
        // Установить данные статуса поступления
        setAdmissionStatus(
            state: AdmissionStatusReducerTypes.InitialState,
            action: PayloadAction<AdmissionStatusReducerTypes.InitialState>,
        ) {
            Object.assign(state, action.payload)
        },
    },
})

// Экспорт создателей действий
export const { setAdmissionStatus } = admissionStatusSlice.actions
// Экспорт редьюсера
export default admissionStatusSlice.reducer

//THUNK CREATORS

// Получение данных статуса поступления
export const getAdmissionStatus = () => {
    return (dispatch: AppDispatchType) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading('admissionStatus'))
        // Получение данных через API статуса поступления
        admissionAPI.getAdmissionStatus().then((response: APITypes.AxiosResponse) => {
            // Установить данные статуса поступления
            dispatch(setAdmissionStatus(response))
            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded('admissionStatus'))
        })
    }
}
