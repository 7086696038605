import React from 'react'
// Node
import { Button, Grid } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'
// Components
import StrapModal from '@strapComponents/StrapModal'
import MaterialPassword from '@materialComponents/MaterialPassword'
// Own
import { useModalButtons } from './data/data.modal_buttons'

/*
 * Блок "Кнопка с модальным окном 'Изменить пароль' "
 */

const PasswordModalBlock = () => {
    // Получение модальных кнопок
    const modalButtons = useModalButtons()
    // Установка маркера показывать ли модальное окно
    const [showModal, setShowModal] = React.useState(false)

    return (
        <>
            <Button
                variant="outlined"
                endIcon={<EditIcon />}
                onClick={() => setShowModal(true)}
                fullWidth={true}
            >
                Изменить пароль
            </Button>
            <StrapModal
                modalHeader={'Изменение пароля'}
                modalContent={
                    <Grid container>
                        <Grid item xs={12}>
                            <MaterialPassword label={'Старый пароль'} />
                        </Grid>
                        <Grid item xs={12}>
                            <MaterialPassword label={'Новый пароль'} />
                        </Grid>
                        <Grid item xs={12}>
                            <MaterialPassword label={'Повтор нового пароля'} />
                        </Grid>
                    </Grid>
                }
                modalButtons={modalButtons}
                showModal={showModal}
                onClose={() => setShowModal(false)}
            />
        </>
    )
}

// Экспорт блока
export default PasswordModalBlock
