import React from 'react'
// Node
import { IconButton } from '@mui/material'
import { AttachFile as AttachFileIcon } from '@mui/icons-material'
// Own
import { useStyledInput } from '../../styles/style.mui'

/*
 * Блок "Прикрепление документов"
 */

const AttachmentsBlock: React.FC = () => {
    // Скрытый инпут для загрузки фото
    const Input = useStyledInput()

    return (
        <>
            <i>
                Прикрепите изображения документов в соответствие с инструкциями предоставленными для
                каждого документа
            </i>
            <br />
            <label htmlFor="icon-button-file">
                Пожалуйста, приложите документ
                <Input accept="image/*" id="icon-button-file" type="file" />
                <IconButton color="primary" aria-label="upload picture" component="span">
                    <AttachFileIcon />
                </IconButton>
            </label>
        </>
    )
}

// Экспорт блока
export default AttachmentsBlock
