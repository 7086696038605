import React from 'react'

/*
 * Блок "Льготы"
 */

const PrivilegesBlock: React.FC = () => {
    return (
        <>
            <h5>Набор доступных льгот зависит от выбранных специальностей.</h5>
            <h5>В данный момент доступных льгот нет.</h5>
        </>
    )
}

// Экспорт блока
export default PrivilegesBlock
