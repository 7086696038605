import React from 'react'
// Node
import { Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
// Components
import MaterialList from '@materialComponents/MaterialList'
import { LinkField } from '@commonComponents/DataFields'
// Types
import EducationItemTypes from './types/EducationItem'
import MaterialListTypes from '@materialComponents/MaterialList/types/MaterialList'
// Own
import { useListItems } from './data/data.list_items'
import EducationItemSkeleton from './skeleton'

/*
 * Компонент "Учебный план"
 */

const EducationItem: React.FC<EducationItemTypes.ComponentProps> = (props) => {
    // Преобразование пропсов в локальные константы
    const { educationItem, loadingEducationItemComplete } = props
    
    // Тема MUI
    const theme = useTheme()
    
    // Получение элементов списка "Учебного плана" из полей обучения
    const listItems: MaterialListTypes.ComponentProps['listItems'] = useListItems({
        educationItem, loadingEducationItemComplete, 
    })

    return (
        <>
            { loadingEducationItemComplete
                ? <Typography variant={'h6'}>{educationItem?.specName}</Typography>
                : EducationItemSkeleton.specName
            }
            { loadingEducationItemComplete
                ? educationItem?.documents?.map((document: any, index: number) => {
                    switch (document?.type) {
                        case 'PDF':
                            return (
                                <LinkField
                                    fieldId={`${document?.idStudentStart}_${index}`}
                                    linkText={document?.filename}
                                    linkURL={document?.downloadUrl}
                                    linkTarget={'_blank'}
                                    linkAlt={document?.sizeDisplay}
                                    linkSX={{ display: 'block', width: 'fit-content' }}
                                    image={document?.iconUrl}
                                />
                            )
                            break
                        default:
                            return (
                                <LinkField
                                    fieldId={`${document?.idStudentStart}_${index}`}
                                    linkText={document?.filename}
                                    linkURL={document?.downloadUrl}
                                    linkTarget={'_blank'}
                                    linkSX={{ display: 'block', width: 'fit-content' }}
                                    image={document?.iconUrl}
                                />
                            )
                            break
                    }
                })
                : EducationItemSkeleton.documents
            }
            <Grid container>
                <Grid item xs={12} sm={4}>
                    <MaterialList
                        listItems={listItems?.filter((item, i: number) => i < 3)}
                        maxWidth="100%"
                        listSX={{ pb: {xs: 0, sm: theme.spacing(1)} }}
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <MaterialList
                        listItems={listItems?.filter((item, i: number) => i >= 3)}
                        maxWidth="100%"
                        listSX={{ pt: {xs: 0, sm: theme.spacing(1)} }}
                    />
                </Grid>
            </Grid>
        </>
    )
}

// Экспорт компонента
export default EducationItem
