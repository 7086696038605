// Node
import { format, formatDistanceToNow } from 'date-fns'
import ruLocale from 'date-fns/locale/ru'

/*
 * Методы работы со временем
 */

// Комментарии для параметра date = 2022-01-26T12:30:00

// 26 января 2022
export const fDate = (date?: string, locale = ruLocale) => {
    return date ? format(new Date(date), 'dd MMMM yyyy', { locale: locale }) : ''
}
// 12:30
export const fTime = (date?: string, locale = ruLocale) => {
    return date ? format(new Date(date), 'HH:mm', { locale: locale }) : ''
}
// 26 янв. 2022 12:30
export const fDateTime = (date?: string, locale = ruLocale) => {
    return date ? format(new Date(date), 'dd MMM yyyy HH:mm', { locale: locale }) : ''
}
// 26/01/2022 12:30
export const fDateTimeSuffix = (date?: string, locale = ruLocale) => {
    return date ? format(new Date(date), 'dd/MM/yyyy hh:mm p', { locale: locale }) : ''
}
// 27 дней назад
export const fToNow = (date?: string, locale = ruLocale) => {
    return date
        ? formatDistanceToNow(new Date(date), {
              addSuffix: true,
              locale: locale,
          })
        : ''
}
