// Types
import APITypes from '@api/types/api'

// Экспорт типа экземпляра axios(настроенного)
export const AxiosInstanceType: APITypes.AxiosType = 'instance'

// Объект с эндпоинтами, отображающими реальные данные
export const realEndpoints: ConfigTypes.EndpointRecord = {       
    // MENU
    menu: {
        url: 'private/menu?profile=cabinet', method: 'get', type: AxiosInstanceType,
    },
    // ABOUT
    auth: {
        url: 'private/profile', method: 'get', type: AxiosInstanceType,
    },    
    accounts: {
        url: 'private/accounts', method: 'get', type: AxiosInstanceType,
    },
    // NEWS
    news: {
        url: 'private/news', method: 'get', type: AxiosInstanceType,
        pagination: {
            currentPage: 1,
            itemsPerPage: 11,
            totalItems: 23,
        },        
    },
    newsItem: {
        url: 'private/news', method: 'get', type: AxiosInstanceType,
    },
    // ADMISSION
    admissionList: {
        url: 'private/admission/list', method: 'get', type: AxiosInstanceType,
    },
    admissionStatus: {
        url: 'private/admission/status', method: 'get', type: AxiosInstanceType,
    },
    // EDUCATION
    educationList: {
        url: 'private/education/student', method: 'get', type: AxiosInstanceType,
    },
    educationItem: {
        url: 'private/education/student/{idStudentStart}', method: 'get', type: AxiosInstanceType,
    },
    educationPlan: {
        url: 'private/education/plan/{idEduPlan}', method: 'get', type: AxiosInstanceType,
    },
    educationSession: {
        url: 'private/education/session/{idStudent}', method: 'get', type: AxiosInstanceType,
    },
    educationPortfolio: {
        url: 'private/education/portfolio?id_student={idStudent}', method: 'get', type: AxiosInstanceType,
    },
    educationPortfolioComments: {
        url: 'private/education/portfolio/{idPortfolioItem}/comments', method: 'get', type: AxiosInstanceType,
    },
    // NOTIFICATIONS
    notificationsStructure: {
        url: 'private/notifications', method: 'get', type: AxiosInstanceType,        
    },
    notificationsGroup: {
        url: 'private/notifications/groups/{groupId}', method: 'get', type: AxiosInstanceType,
        pagination: {
            currentPage: 1,
            itemsPerPage: 11,
            totalItems: 23,
        },        
    },
    notificationsItem: {
        url: 'private/notifications/items/{notificationId}', method: 'get', type: AxiosInstanceType,         
    },        
    notificationsUnreadCount: {
        url: 'private/notifications/countUnread', method: 'get', type: AxiosInstanceType,        
    },
    notificationsMarkItemAsRead: {
        url: 'private/notifications/items/{notificationId}/markRead',
        method: 'post', 
        type: AxiosInstanceType,         
    },
    notificationsMarkGroupAsRead: {
        url: 'private/notifications/markRead',
        method: 'post', 
        type: AxiosInstanceType,         
    },
    notificationsMarkGroupAsUnread: {
        url: 'private/notifications/markUnread',
        method: 'post', 
        type: AxiosInstanceType,         
    },            
}
