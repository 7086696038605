// Node
import _ from 'lodash-contrib'
// Types
import APITypes from '@api/types/api'
import AdmissionStatusReducerTypes from '@redux/reducers/types/admission-status-reducer'

// Маппинг свойств, полученных из API в свойства InitialState
const APIToInitialStatePropsMapping = {
    name: 'label',
    statusText: 'description',
    status: 'active',
}

export const admissionStatusBuilderMapper = {
    dataMapping(response: APITypes.AxiosResponse) {
        // Проход по массиву элементов чек-листа и преобразование свойств
        // к пригодному для бизнес-логики приложения виду
        response.checklist = response?.checklist.map(
            (item: AdmissionStatusReducerTypes.InitialState) => {
                // Маппинг свойств, полученных из API в свойства InitialState
                const tempItem = _.renameKeys(item, APIToInitialStatePropsMapping)
                // Подстановка значений свойств
                return _.mapValues(tempItem, (value: any, key: string) => {
                    if (key == 'active') {
                        switch (value) {
                            case 'FAILED':
                                return false
                            case 'IN_PROGRESS':
                                return false
                            case 'PASSED':
                                return true
                            default:
                                return false
                        }
                    }
                    return value
                })
            },
        )
        return response
    },
}
