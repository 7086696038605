import React from 'react'
// Node
import { IconButton } from '@mui/material'
import { AttachFile as AttachFileIcon } from '@mui/icons-material'
// Own
import { useStyledInput } from '../../styles/style.mui'

/*
 * Блок "Родители"
 */

const ParentsBlock: React.FC = () => {
    // Скрытый инпут для загрузки фото
    const Input = useStyledInput()

    return (
        <>
            <i>Введите данные плательщика.</i>
            <br />
            <i>
                Только если Вы поступаете на платную основу обучения и будете не самостоятельно
                оплачивать обучение.
            </i>
            <br />
            <i>Во всех остальных случаях ввод не требуется.</i>
            <br />
            <label htmlFor="icon-button-file">
                Указать
                <Input accept="image/*" id="icon-button-file" type="file" />
                <IconButton color="primary" aria-label="upload picture" component="span">
                    <AttachFileIcon />
                </IconButton>
            </label>
        </>
    )
}

// Экспорт блока
export default ParentsBlock
