import React from 'react'
// Node
import _ from 'lodash-contrib'
import { Box, Stepper, Step, StepLabel, StepContent, Typography } from '@mui/material'
import {
    CheckCircle as CheckCircleIcon,
    RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material'
// Types
import MaterialStepperTypes from './types/MaterialStepper'
// Own
import useStyleMui from './styles/style.mui'

/*
 * Компонент "Пошаговый прогресс" (обертка над MaterialUI)
 */

// Компонент иконки для шага
const StepCircleIcon: React.FC<{ active: boolean }> = (props) => {
    return <>{props?.active ? <CheckCircleIcon color="primary" /> : <RadioButtonUncheckedIcon />}</>
}

// Экспорт компонента
export const MaterialStepper: React.FC<MaterialStepperTypes.ComponentProps> = (props) => {
    // Преобразование пропсов в локальные константы
    const { steps, maxWidth } = props

    const styleMui = useStyleMui()

    return (
        <Box sx={{ maxWidth: maxWidth }} mt={2}>
            <Stepper nonLinear={true} orientation="vertical">
                {steps?.map((step, index) => (
                    <Step
                        onClick={() => step.onClick && step.onClick()}
                        className={styleMui.stepperStep}
                        active={step.active}
                        completed={step.active}
                        expanded={!_.isEmpty(step?.description)}
                        key={`stepperStep_${index}`}
                    >
                        <StepLabel StepIconComponent={StepCircleIcon}>
                            <Typography color={step.active ? 'primary' : ''}>
                                {step?.label}
                            </Typography>
                        </StepLabel>
                        <StepContent>
                            <Typography color={step.active ? 'primary' : ''}>
                                {step?.description}
                            </Typography>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </Box>
    )
}

// Свойства по умолчанию
MaterialStepper.defaultProps = {}

// Экспорт компонента
export default MaterialStepper
