import React from 'react'
// Node
import { Button } from '@mui/material'
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material'
import { NavLink } from 'react-router-dom'

/*
 * Блок "Кнопка 'Узнать информацию о браузере' "
 */

const BrowserModalBlock = () => {
    return (       
        <NavLink
            to={{ pathname: 'https://tendence.ru/tools/whois'}}                            
            target={'_blank'}
        >
            <Button
                variant="outlined"
                endIcon={<InfoOutlinedIcon />}                
                fullWidth={true}
            >
                Узнать Ваш браузер
            </Button>
        </NavLink>            
    )
}

// Экспорт блока
export default BrowserModalBlock
