import React from 'react'

/*
 * Блок "Достижения"
 */

const AchievementsBlock: React.FC = () => {
    return (
        <>
            <h5>Набор доступных достижений зависит от выбранных специальностей.</h5>
            <h5>В данный момент доступных достижений нет.</h5>
        </>
    )
}

// Экспорт блока
export default AchievementsBlock
