import React from 'react'
// Node
import { connect } from 'react-redux'
import { useMediaQuery, useTheme } from '@mui/material'
// Types
import { AppStateType } from '@redux/store'
import AdmissionPageTypes from './types/AdmissionPage'
// Own
import AdmissionListBlock from './blocks/AdmissionListBlock'
import AdmissionStatusBlock from './blocks/AdmissionStatusBlock'

/*
 * Страница "Мое поступление"
 */

const AdmissionPage: React.FC<AdmissionPageTypes.MapStateToProps> = (props) => {
    // Преобразование пропсов в локальные константы
    const { showMode } = props

    // Тема MUI
    const theme = useTheme()

    // Разрешение экрана выше брекпоинта 'md'
    const isScreenUpMdBreakpoint = useMediaQuery(theme.breakpoints.up('md'))

    const getAdmissionBlock = (showMode: AdmissionPageTypes.MapStateToProps['showMode']) => {
        switch (showMode) {
            case 'list':
                return <AdmissionListBlock />
                break
            case 'status':
                return (
                    <AdmissionStatusBlock
                        orientation={isScreenUpMdBreakpoint ? 'vertical' : 'horizontal'}
                    />
                )
                break
        }
    }

    return <> {getAdmissionBlock(showMode)} </>
}

// Маппинг стейта в пропсы страницы
const mapStateToProps = (state: AppStateType): AdmissionPageTypes.MapStateToProps => ({
    showMode: state.admissionStore?.showMode,
})

// Экспорт страницы
export default connect(mapStateToProps, {})(AdmissionPage)
