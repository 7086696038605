import React from 'react'
// Node
import { IconButton } from '@mui/material'
import { AttachFile as AttachFileIcon } from '@mui/icons-material'
// Own
import { useStyledInput } from '../../styles/style.mui'

/*
 * Блок "Специальности"
 */

const SpecialtyBlock: React.FC = () => {
    // Скрытый инпут для загрузки фото
    const Input = useStyledInput()

    return (
        <>
            <i>
                Пожалуйста, выберите специальности для подачи заявления или внесите изменения в Ваш
                выбор.
            </i>
            <br />
            <i>Список доступных специальностей зависит от Вашего текущего образования.</i>
            <br />
            <label htmlFor="icon-button-file">
                Добавить специальность
                <Input accept="image/*" id="icon-button-file" type="file" />
                <IconButton color="primary" aria-label="upload picture" component="span">
                    <AttachFileIcon />
                </IconButton>
            </label>
        </>
    )
}

// Экспорт блока
export default SpecialtyBlock
