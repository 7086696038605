import React from 'react'
// Node
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'
import { Box, Button, Typography } from '@mui/material'
import { Check as CheckIcon } from '@mui/icons-material'
// Components
import MaterialTable from '@materialComponents/MaterialTable'
// Redux
import { getAdmissionList } from '@redux/reducers/admission-list-reducer'
import { setShowMode } from '@redux/reducers/admission-reducer'
// Types
import { AppStateType } from '@redux/store'
import AdmissionListBlockTypes from './types/AdmissionListBlock'
import MaterialTableTypes from '@materialComponents/MaterialTable/types/MaterialTable'
// Own
import { useTableData } from './data/data.table_rows'
import AdmissionListBlockSkeleton from './skeleton'

/*
 * Блок "Список поступлений"
 */

const AdmissionListBlock: React.FC<
    AdmissionListBlockTypes.MapStateToProps & AdmissionListBlockTypes.MapDispatchToProps
> = (props) => {
    // Преобразование пропсов в локальные константы
    const {
        admissions,
        submitButtonText,
        helpMessage,
        loadingAadmissionListComplete,
        getAdmissionList,
        setShowMode,
    } = props

    // Хук эффекта
    React.useEffect(() => {
        // Получение данных списка поступлений
        getAdmissionList()
    }, [])

    // Получение элементов списка "Обо мне" из полученных данных пользователя
    const tableData: MaterialTableTypes.ComponentProps = useTableData({ admissions })
    
    // Ширина контента
    const contentWidth = useSelector((state: AppStateType) => state.windowParamsStore?.contentWidth)
    
    return (
        <>
            {loadingAadmissionListComplete ? (
                <>
                    <Typography paragraph>
                        {typeof helpMessage === 'string' ? (
                            <span dangerouslySetInnerHTML={{ __html: helpMessage || '' }} />
                        ) : (
                            helpMessage
                        )}
                    </Typography>
                    <Button
                        variant="outlined"
                        endIcon={<CheckIcon />}
                        onClick={() => {
                            setShowMode('status')
                        }}
                    >
                        {submitButtonText}
                    </Button>
                    {tableData.rows?.length
                        ? <Box sx={{ mt: 3, maxWidth: contentWidth ? contentWidth - 20 : contentWidth }}>
                            <MaterialTable
                                rows={tableData.rows}
                                headers={tableData.headers}
                                slaveRowTitle={'Детали поступления'}
                            />
                        </Box>
                        : []
                    }
                </>
            ) : (
                <AdmissionListBlockSkeleton />
            )}
        </>
    )
}

// Маппинг стейта в пропсы блока
const mapStateToProps = (state: AppStateType): AdmissionListBlockTypes.MapStateToProps => ({
    admissions: state.admissionListStore?.admissions,
    submitButtonText: state.admissionListStore?.submitButtonText,
    helpMessage: state.admissionListStore?.helpMessage,
    loadingAadmissionListComplete: state.loadStore.loadingComplete?.admissionList,
})

// Экспорт блока
export default connect(mapStateToProps, { getAdmissionList, setShowMode })(AdmissionListBlock)
