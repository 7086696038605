import React from 'react'
// Node
import { connect } from 'react-redux'
import { compose } from 'redux'
// Components
import MaterialProgress from '@materialComponents/MaterialProgress'
// Layouts
import AuthLayout from '@layouts/AuthLayout'
import MainLayout from '@layouts/MainLayout'
// Types
import { AppStateType } from '@redux/store'
import AppTypes from './types/App'
// Own
import './App.css'

/*
 * Приложение "Личный кабинет"
 */

export const App: React.FC<AppTypes.MapStateToProps> = (props) => {
    return (
        <div>
            {
                // Если пользователь аутентифицирован, вывод главного шаблона
                props.isAuth ? (
                    <>
                        <MaterialProgress variant="indeterminate" color="primary" />
                        <MainLayout />
                    </>
                ) : (
                    // ... в противном случае, вывод шаблона аутентификации
                    <>
                        <MaterialProgress variant="indeterminate" color="secondary" />
                        <AuthLayout />
                    </>
                )
            }
        </div>
    )
}

// Маппинг стейта в пропсы приложения
const mapStateToProps = (state: AppStateType): AppTypes.MapStateToProps => ({
    isAuth: state.authStore.isAuth,
})

// Экспорт контейнера приложения
export default compose(connect(mapStateToProps, {}))(App)
