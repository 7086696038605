// Node
import config from 'react-global-configuration'
// Types
import APITypes from '@api/types/api'
import NewsAPITypes from '@api/endpoints/newsAPI/types/newsAPI'
// Own
import { newsBuilderMapper } from './mapper'

export const newsBuilderInstance = {
    dataForGet(page: number) {
        return config.get('api.news.url') + `?page=${--page}`
    },
    dataForThen(response: APITypes.AxiosResponse, page: number): NewsAPITypes.NewsList {
        page // - для EsLint 'no-unused-vars'
        response = response.data
        // Инкремент номера страницы пагинации(т.к. 1-я страница в API имеет индекс = 0)
        response.number++

        return response
    },
    dataMapping(response: NewsAPITypes.NewsList) {
        return newsBuilderMapper.dataMapping(response)
    },
}
