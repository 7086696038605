// Types
import AdmissionListBlockTypes from '../types/AdmissionListBlock'
import MaterialTableTypes from '@materialComponents/MaterialTable/types/MaterialTable'

// Экспорт строк таблицы
export const useTableData = (props: {
    admissions: AdmissionListBlockTypes.MapStateToProps['admissions']
}) => {
    // Преобразование пропсов в локальные константы
    const { admissions } = props

    // Поля элемента списка поступления, которые станут строками таблицы
    const tableHeaders: MaterialTableTypes.ComponentProps['headers'] = {
        specQualifier: { title: 'Код', main: true },
        specName: { title: 'Специальность', main: true },
        status: { title: 'Статус зачисления', main: true },
        onDate: { title: 'Дата зачисления', main: true },
        specializationName: { title: 'Специализация', main: false },
        ktName: { title: 'Форма обучения', main: false },
        kafedra: { title: 'Кафедра', main: false },
    }

    // Массив строк таблицы
    const tableRows: MaterialTableTypes.ComponentProps['rows'] = []
    // Если список поступлений - не пустой массив
    if (admissions) {
        admissions.forEach((oneAdmission: AdmissionListBlockTypes.admissionItem) => {
            const admissionObject: MaterialTableTypes.row = { slaveRows: [] }
            const admissionSlaveRowsObject: MaterialTableTypes.slaveRow = {}
            // Проход по всем полям элемента списка поступления, которые станут строками таблицы
            for (const key in tableHeaders) {
                // Если будет формироваться главная строка таблицы
                if (tableHeaders[key].main) {
                    admissionObject[key] = oneAdmission[key]
                }
                // Если будет формироваться второстепенная строка таблицы
                if (!tableHeaders[key].main) {
                    admissionSlaveRowsObject[key] = oneAdmission[key]
                }
            }
            admissionObject.slaveRows?.push(admissionSlaveRowsObject)
            // Формирование элемента массива строк таблицы
            tableRows.push(admissionObject)
        })
    }

    // Возврат сформированных данных таблицы
    return { headers: tableHeaders, rows: tableRows }
}
