import React from 'react'
// Node
import {
    Announcement as AnnouncementIcon,
    Assignment as AssignmentIcon,
    BeachAccess as BeachAccessIcon,
    CreditCard as CreditCardIcon,
    Dvr as DvrIcon,
    Favorite as FavoriteIcon,
    Folder as FolderIcon,
    LibraryBooks as LibraryBooksIcon,
    LocalHospital as LocalHospitalIcon,
    Mail as MailIcon,
    Message as MessageIcon,
    People as PeopleIcon,
    Person as PersonIcon,
    School as SchoolIcon,
    LocalLibrary as LocalLibraryIcon,
    FactCheck as FactCheckIcon,
} from '@mui/icons-material'
// Types
import LeftMenuAreaTypes from '@areas/LeftMenuArea/types/LeftMenuArea'
import MenuReducerTypes from '@redux/reducers/types/menu-reducer'
// Own
import LeftMenuAreaSkeleton from '../skeleton'

// Экспорт метода получения элементов списка панели меню
export const getPanelListItems = (props: LeftMenuAreaTypes.MapStateToProps) => {
    // Преобразование пропсов в локальные константы
    const { menuItems, loadingMenuComplete } = props

    // Маппинг поля "flowId" на элемент меню
    const mapFlowIdToMenuItem: LeftMenuAreaTypes.MenuMapper = {
        // Персональная информация
        'info-personal-news': { icon: <AnnouncementIcon />, url: 'news', enable: true }, // Объявления
        'communication': { icon: <MessageIcon />, enable: false }, // Взаимодействие
        'info': { icon: <PersonIcon />, url: 'profile', enable: true }, // Обо мне
        'contracts': { icon: <FactCheckIcon />, enable: false }, // Мои договоры
        'vacation': { icon: <BeachAccessIcon />, enable: false }, // Мои отпуска
        'salary': { icon: <CreditCardIcon />, enable: false }, // Моя зарплата
        'orders': { icon: <AssignmentIcon />, enable: false }, // Мои приказы
        'admission': { icon: <SchoolIcon />, url: 'admission', enable: true }, // Моё поступление
        'clinicattachment': { icon: <LocalHospitalIcon />, enable: false }, // Поликлиника
        'education': { icon: <LocalLibraryIcon />, url: 'education', enable: true }, // Мое обучение
    }

    // Маппинг поля "name" на элемент меню
    // (временный способ маппинга, т.к. поле "name" может изменяться)
    const mapNameToMenuItem: LeftMenuAreaTypes.MenuMapper = {
        // Beehive
        'Электронная почта': { icon: <MailIcon />, enable: true },
        'Общие документы': { icon: <LibraryBooksIcon />, enable: true },
        'Рабочие пространства': { icon: <DvrIcon />, enable: true },
        'Конференции': { icon: <PeopleIcon />, enable: true },
        'Предпочтения': { icon: <FavoriteIcon />, enable: true },
    }

    // Массив элементов списка панели меню
    const panelListItems =
        // Если загрузка завершена
        loadingMenuComplete && Array.isArray(menuItems)
            ? // Сформировать массив элементов списка из загруженных данных
            menuItems?.map(
                (item: MenuReducerTypes.GroupMenuItem): LeftMenuAreaTypes.OnePanelGroupItem => ({
                    name: item?.name,
                    items: item?.items?.map((sub: MenuReducerTypes.MenuItem) => {
                        // Если пункт меню разрешен к отображению
                        if (mapFlowIdToMenuItem[sub.flowId!]?.enable || mapNameToMenuItem[sub.name!]?.enable) {
                            return {
                                // Текст
                                text: sub.name,
                                // Иконка
                                icon: mapFlowIdToMenuItem[sub.flowId!] ? ( // Если есть привязка иконки к полю "flowId"
                                    mapFlowIdToMenuItem[sub.flowId!]?.icon // Вывод иконки, привязанной к полю "flowId"
                                ) : mapNameToMenuItem[sub.name!] ? ( // Если есть привязка иконки к полю "name"
                                    mapNameToMenuItem[sub.name!]?.icon // Вывод иконки, привязанной к полю "name"
                                ) : (
                                    <FolderIcon />
                                ), // Вывод иконки по умолчанию
                                // Ссылка
                                link: mapFlowIdToMenuItem[sub.flowId!]?.url ? `/${mapFlowIdToMenuItem[sub.flowId!]?.url}` : sub.flowId,
                                // Внешняя ссылка
                                extLink: sub.externalUrl,
                            }
                        } else {
                            return {}
                        }
                    }),
                }),
            )
            : // Сформировать массив элементов списка из скелетонов
            LeftMenuAreaSkeleton

    // Возврат сформированного массива элементов списка
    return panelListItems
}
