import React from 'react'
// Components
import StrapModal from '@strapComponents/StrapModal'

/*
 * Страница "Мок для страницы логина"
 */

const MockPage = () => {
    return <StrapModal className="authDialog" showModal={true} backdropClassName="authBackdrop" />
}

// Экспорт страницы
export default MockPage
