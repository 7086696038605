// Node
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// API
import { authAPI } from '@api/endpoints/authAPI'
// Types
import AuthReducerTypes from './types/auth-reducer'
import { AppGetStateType, AppDispatchType } from '@redux/store'
import { AuthClientTokens } from '@react-keycloak/core/lib/types'
import APITypes from '@api/types/api'
// Создатели действий загрузки
import { setLoading, setLoaded } from './load-reducer'

// Начальное состояние
const initialState: AuthReducerTypes.InitialState = {
    user: {
        idWebUser: null,
        surname: '',
        name: '',
        patronymic: '',
        photoDisplayUrl: '',
    },
    roles: [],
    isAuth: null,
}

// Слайс загрузки
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // Записать данные пользователя
        setAuthData(
            state: AuthReducerTypes.InitialState,
            action: PayloadAction<AuthReducerTypes.InitialState | null>,
        ) {
            state.user = action.payload?.user
            state.roles = action.payload?.roles
        },
        // Установить состояние аутентификации
        setIsAuth(state: AuthReducerTypes.InitialState, action: PayloadAction<boolean | null>) {
            state.isAuth = action.payload
        },
    },
})

// Экспорт создателей действий
export const { setAuthData, setIsAuth } = authSlice.actions
// Экспорт редьюсера аутентификации
export default authSlice.reducer

//THUNK CREATORS

// Обработка получения токенов
export const handleTokens = (tokens: AuthClientTokens, keycloak: APITypes.KeycloakType) => {
    return (dispatch: AppDispatchType, getState: AppGetStateType) => {
        // Аутентифицирован ли пользователь
        const isAuth = getState().authStore.isAuth
        // Установить состояние загрузки приложения в "Загружается"
        if (!isAuth) dispatch(setLoading('app'))
        // Если токены получены
        if (authAPI.handleTokens(tokens, keycloak)) {
            // Установить состояние аутентификации в true
            dispatch(setIsAuth(true))
            if (!isAuth) {
                // Записать данные пользователя из токена
                const tokenParsed = keycloak?.tokenParsed as AuthReducerTypes.User
                dispatch(
                    setAuthData({
                        user: {
                            idWebUser: tokenParsed?.id_web_user,
                            surname: tokenParsed?.family_name ?? '',
                            name: tokenParsed?.given_name ?? '',
                            patronymic: tokenParsed?.middle_name ?? '',
                            photoDisplayUrl: tokenParsed?.picture,
                            email: tokenParsed?.email ?? '',
                        },
                        roles: tokenParsed?.resource_access?.account?.roles,
                    }),
                )
                // Установить состояние загрузки приложения в "Загружено"
                dispatch(setLoaded('app'))
            }
        }
        // Если токены НЕ получены
        else {
            // Установить состояние аутентификации в false
            dispatch(setIsAuth(false))
        }
    }
}

// Получение данные аутентифицированного пользователя
export const getAuthData = () => {
    return (dispatch: AppDispatchType) => {
        // Установить состояние загрузки данных о профиле в "Загружается"
        dispatch(setLoading('auth'))
        // Получение данных через API аутентификации
        authAPI.getAuthData().then((response: APITypes.AxiosResponse) => {
            // Записать данные пользователя
            dispatch(setAuthData({ user: response }))
            // Установить состояние загрузки данных о профиле в "Загружено"
            dispatch(setLoaded('auth'))
        })
    }
}
