// Node
import { createSlice } from '@reduxjs/toolkit'
import config from 'react-global-configuration'
import _ from 'lodash-contrib'
// Types
import PaginationReducerTypes from './types/pagination-reducer'

// Начальное состояние
const initialState: PaginationReducerTypes.InitialState = {
    news: config.get('api.news.pagination'),
    notificationsGroup: config.get('api.notificationsGroup.pagination'),
}

// Слайс пагинации
const paginationSlice = createSlice({
    name: 'pagination',
    initialState,
    reducers: {
        // Установить текущую страницу пагинации
        setCurrentPage(state, action) {
            setPaginationProperty(state, action.payload[0], 'currentPage', action.payload[1])            
        },
        // Установить количество элементов на странице
        setItemsPerPage(state, action) {
            setPaginationProperty(state, action.payload[0], 'itemsPerPage', action.payload[1])            
        },
        // Установить общее количество элементов
        setTotalItems(state, action) {
            setPaginationProperty(state, action.payload[0], 'totalItems', action.payload[1])            
        },
    } as PaginationReducerTypes.SliceActions,
})

// Установить свойство объекта пагинации
const setPaginationProperty = (
    state: PaginationReducerTypes.InitialState, 
    paginationType: PaginationReducerTypes.PaginationTypes, 
    PaginationMethodName: PaginationReducerTypes.PaginationMethods, 
    PaginationMethodValue: number,
) => {                    
    // Если объект пагинации пуст           
    if(_.isEmpty(state[paginationType])) {
        for (const key in state) {
            if(paginationType.includes(key)) {                        
                // Взять значение из объекта пагинации, частично сходного по названию
                Object.assign(state, {...state, [paginationType]: {...state[key]} })                        
            }
        }
    }
    state[paginationType][PaginationMethodName] = PaginationMethodValue    
}

// Экспорт создателей действий
export const { setCurrentPage, setItemsPerPage, setTotalItems } = paginationSlice.actions
// Экспорт редьюсера
export default paginationSlice.reducer
