// Node
import config from 'react-global-configuration'
import Keycloak from 'keycloak-js'

//=== НАСТРОЙКА ЭКЗЕМПЛЯРА KEYCLOAK ===

const keycloak = Keycloak({
    realm: config.get('keycloak.realm'),
    clientId: config.get('keycloak.clientId'),
    url: config.get('keycloak.url'),
})

// Экспорт keycloak
export default keycloak
