import React from 'react'
// Node
import { Button, IconButton } from '@mui/material'
import { Edit as EditIcon, PhotoCamera as PhotoCameraIcon } from '@mui/icons-material'
// Components
import MaterialCard from '@materialComponents/MaterialCard'
import StrapModal from '@strapComponents/StrapModal'
// Types
import AboutPageTypes from '@pages/AboutPage/types/AboutPage'
// Own
import { useModalButtons } from './data/data.modal_buttons'
import useStyleMui, { useStyledInput } from './styles/style.mui'

/*
 * Блок "Кнопка с модальным окном 'Изменить фотографию' "
 */

const PhotoModalBlock: React.FC<AboutPageTypes.MapStateToProps> = ({ user }) => {
    const styleMui = useStyleMui()
    // Получение модальных кнопок
    const modalButtons = useModalButtons()
    // Установка маркера показывать ли модальное окно "Изменить фотографию"
    const [showModal, setShowModal] = React.useState(false)
    // Скрытый инпут для загрузки фото
    const Input = useStyledInput()

    return (
        <>
            <Button
                color="primary"
                variant="outlined"
                endIcon={<EditIcon />}
                onClick={() => setShowModal(true)}
                fullWidth={true}
            >
                Изменить фотографию
            </Button>
            <StrapModal
                modalHeader={'Изменение фотографии'}
                modalContent={
                    <MaterialCard
                        className={styleMui.materialCard}
                        cardImage={user?.photoDisplayUrl}
                        cardText={
                            <label htmlFor="icon-button-file">
                                Загрузить новое фото
                                <Input accept="image/*" id="icon-button-file" type="file" />
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                >
                                    <PhotoCameraIcon />
                                </IconButton>
                            </label>
                        }
                        cardImageStyle={{
                            width: 150,
                            height: 'auto',
                            margin: '10px auto 0px auto',
                        }}
                    />
                }
                modalButtons={modalButtons}
                showModal={showModal}
                onClose={() => setShowModal(false)}
            />
        </>
    )
}

// Экспорт блока
export default PhotoModalBlock
