// Node
import { makeStyles } from '@mui/styles'
import { Theme, useTheme } from '@mui/material/styles'
// Types
import MaterialListTypes from '../types/MaterialList'

// Конструктор стилей
const useStyleMui = (props: MaterialListTypes.ComponentProps) => {
    // Использовать текущую тему
    const theme: Theme = useTheme()
    return makeStyles(() => ({
        root: {
            width: '100%',
            maxWidth: props.maxWidth,
            backgroundColor: theme.palette?.background.paper,
        },
    }))()
}

// Экспорт конструктора стилей
export default useStyleMui
