// Node
import { grey, indigo, pink } from '@mui/material/colors'

// Настройки темы MUI
const theme = {
    // Брекпоинты
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
        },
    },
    // Палитра
    palette: {
        primary: {
            main: indigo['500'],
        },
        secondary: {
            main: pink['A400'],
        },
        text: {
            disabled: grey['500'],
        },
    },
}

export default theme
