// Node
import { makeStyles } from '@mui/styles'
import { Theme, useTheme } from '@mui/material/styles'

// Конструктор стилей
const useStyleMui = () => {
    // Использовать текущую тему
    const theme: Theme = useTheme()
    return makeStyles(() => ({
        content: {
            flexGrow: 1,
            overflow: 'auto',
            padding: theme.spacing(3),
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
    }))()
}

// Экспорт конструктора стилей
export default useStyleMui
