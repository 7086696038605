// Node
import _ from 'lodash-contrib'
// Types
import APITypes from '@api/types/api'
import NewsReducerTypes from '@redux/reducers/types/news-reducer'

// Маппинг свойств, полученных из API в свойства InitialState
const APIToInitialStatePropsMapping = {
    idInformation: 'id',
    pictureUrl: 'cover',
    pubdate: 'createdAt',
    description: 'text',
}

export const newsBuilderMapper = {
    dataMapping(response: APITypes.AxiosResponse) {
        // Проход по массиву новостей и преобразование свойств
        // к пригодному для бизнес-логики приложения виду
        response.content = response?.content.map((item: NewsReducerTypes.InitialState) =>
            // Маппинг свойств, полученных из API в свойства InitialState
            _.renameKeys(item, APIToInitialStatePropsMapping),
        )
        return response
    },
}
