import React from 'react'
// Node
import { Box, Button, Typography } from '@mui/material'
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material'
// Components
import MaterialMenu from '@materialComponents/MaterialMenu'
// Types
import MaterialMenuTypes from '@materialComponents/MaterialMenu/types/MaterialMenu'

/*
 * Компонент "Выпадающий список"
 */

// Экспорт компонента
export const Select: React.FC<{
    title: string, 
    items: MaterialMenuTypes.ComponentProps['menuItems'],
    value?: string,
}> = (props) => {
    // Преобразование пропсов в локальные константы
    const { title, items, value } = props
    
    const id = `list-menu-${new Date()}`
    
    return (
        <>
            <Box sx={{ float: 'left', mr: 2 }}>
                <Typography sx={{ lineHeight: '2.2rem' }}>{ title }</Typography>
            </Box>
            <MaterialMenu
                id={id}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                menuItems={ items }
                sx={{ float: 'left', mr: {xs: 0, sm: 2}, width: 'auto', }}
            >
                <Button
                    aria-controls={id}
                    aria-haspopup={true}
                    variant={'outlined'}
                    disableElevation
                    endIcon={<KeyboardArrowDownIcon />}
                    sx={{ mb: 2, width: '100%', overflowWrap: 'anywhere', }}
                >
                    <Box sx={{                                                                   
                        overflow: 'hidden',
                        display: '-webkit-box',
                        ['-webkit-line-clamp']: '2',
                        lineClamp: '2',
                        ['-webkit-box-orient']: 'vertical',                                                                    
                        textAlign: 'left',                                                             
                    }}>
                        { value }
                    </Box>
                </Button>
            </MaterialMenu>
        </>
    )
}

// Свойства по умолчанию
Select.defaultProps = {
    
}

// Экспорт компонента
export default Select
