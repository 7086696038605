import React from 'react'
// Node
import { Badge, Box, Chip, Divider, Typography } from '@mui/material'
// Types
import NotificationsGroupPageTypes from './types/NotificationsGroupPage'
// Utils
import { fDate, fTime } from '@utils/formatTime'
// Own
import { getBadgeColor } from '../../data/data.badge_color'
import { NotificationsItem } from '../../components'

/*
 * Компонент "Страница элемента группы уведомлений"
 */

const NotificationsGroupPage: React.FC<NotificationsGroupPageTypes.ComponentProps> = (props) => {
    // Преобразование пропсов в локальные константы
    const { content, isLastItem } = props

    // Установка маркера показывать ли модальное окно
    const [showModal, setShowModal] = React.useState(false)     
    
    return (
        <Box>                        
            <Badge                            
                sx={{
                    cursor: 'pointer',
                    display: 'grid',
                    padding: '20px 0px 20px 20px',
                    '&:hover': {
                        opacity: '0.6',
                    },
                    '& .MuiBadge-badge': {
                        left: '0px',
                        right: 'unset',
                        top: '31px',
                        display: content?.readDate ? 'none' : 'block',
                    },
                }} 
                color="secondary" variant="dot"
                onClick={ () => setShowModal(true) }
            >
                <Typography variant={'h6'}>
                    { content?.title }
                </Typography>
                <Typography sx={{ mt: '4px !important', color: '#9e9e9e' }} variant={'body2'}>
                    <Box sx={{ float: 'left'}}>
                        { `${fDate(content?.date)} | ${fTime(content?.date)} | ${content?.category?.title}` }
                    </Box>
                    <Box sx={{ float: 'right'}}>
                        { content?.tags
                            ?.filter((tag: NotificationsGroupPageTypes.Tag) => tag?.primary) 
                            ?.map((tag: NotificationsGroupPageTypes.Tag) => (
                                <Chip 
                                    key={`notificationsPageContentTag${tag?.name}`}
                                    label={tag?.title}
                                    sx={{ 
                                        backgroundColor: getBadgeColor(tag?.style),
                                        color: '#fff',
                                    }}
                                    size={'small'}
                                />
                            ))
                        }
                    </Box>
                </Typography>
            </Badge>
            
            <NotificationsItem
                content={content}
                showModal={showModal}
                setShowModal={setShowModal}
            />
            
            {!isLastItem
                ? <Divider sx={{ margin: '5px 0px 5px 0px'}} />
                : []
            }
        </Box>
    )
}

// Экспорт компонента
export default NotificationsGroupPage
