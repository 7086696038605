// Types
import APITypes from '../types/api'

// Экспорт перехватчика запросов axios к серверу
export const requestInterceptor = (axios: APITypes.AxiosWrapper) => {
    //=== ПЕРЕХВАТ ЗАПРОСОВ НА СЕРВЕР ===
    axios.instance.interceptors.request.use(
        // Успешный запрос на сервер
        (request: APITypes.AxiosRequest) => {            
            request.headers.Authorization = axios.getAuthHeader()
            return request
        },
        // Запрос на сервер с ошибкой
        (error: APITypes.AxiosError) => {
            return Promise.reject(error)
        },
    )
}
