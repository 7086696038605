import { newsBuilderOriginal } from '@api/endpoints/newsAPI/builders/news/original'
import { newsBuilderInstance } from '@api/endpoints/newsAPI/builders/news/instance'
import { newsItemBuilderOriginal } from '@api/endpoints/newsAPI/builders/newsItem/original'
import { newsItemBuilderInstance } from '@api/endpoints/newsAPI/builders/newsItem/instance'
import { educationListBuilderMapper } from '@api/endpoints/educationAPI/builders/educationList/mapper'
import { notificationsGroupBuilderOriginal } from '@api/endpoints/notificationsAPI/builders/notificationsGroup/original'
import { notificationsGroupBuilderInstance } from '@api/endpoints/notificationsAPI/builders/notificationsGroup/instance'

// Корневой построитель
const rootBuilder: {
    [param: string]: any
} = {
    newsBuilderOriginal,
    newsBuilderInstance,
    newsItemBuilderOriginal,
    newsItemBuilderInstance,
    educationListBuilderOriginal: educationListBuilderMapper,
    educationListBuilderInstance: educationListBuilderMapper,
    notificationsGroupBuilderOriginal,
    notificationsGroupBuilderInstance,
}

// Экспорт корневого построителя
export default rootBuilder
