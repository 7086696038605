import React from 'react'
// Node
import { makeStyles, withStyles } from '@mui/styles'
import { Theme, useTheme } from '@mui/material/styles'
import { Badge } from '@mui/material'
// Types
import MaterialAvatarTypes from '../types/MaterialAvatar'

// Экспорт конструктора компонента StyledBadge
export const useStyledBadge =
    // Пропсы, передаваемые в конструктор
    (props: MaterialAvatarTypes.ComponentProps): React.FC<MaterialAvatarTypes.StyledBadgeProps> =>
        // Немедленно вызываемая функция, возвращающая компонент StyledBadge
        ((props): any => {
            // Преобразование пропсов в локальные константы
            const { primary, secondary } = props

            // Цвет бейджа "онлайн"
            let badgeColor: MaterialAvatarTypes.BadgeColor = 'success'
            if (primary) badgeColor = 'primary'
            if (secondary) badgeColor = 'secondary'

            // Использовать текущую тему
            const theme: Theme = useTheme()
            // Компонент StyledBadge
            const StyledBadge: React.ComponentType = withStyles(() => ({
                badge: {
                    backgroundColor: theme.palette[badgeColor].light,
                    color: theme.palette[badgeColor].light,
                    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                    '&::after': {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        animation: '$ripple 1.2s infinite ease-in-out',
                        border: '1px solid currentColor',
                        content: '""',
                    },
                },
                '@keyframes ripple': {
                    '0%': {
                        transform: 'scale(.8)',
                        opacity: 1,
                    },
                    '100%': {
                        transform: 'scale(2.4)',
                        opacity: 0,
                    },
                },
            }))(Badge as React.FC)

            // Свойства по умолчанию компонента StyledBadge
            StyledBadge.defaultProps = {
                overlap: 'rectangular',
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                variant: 'standard',
            }

            // Возврат компонента StyledBadge
            return StyledBadge
        })(props)

// Конструктор стилей
const useStyleMui = () => {
    // Использовать текущую тему
    const theme: Theme = useTheme()
    return makeStyles(() => ({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
    }))()
}

// Экспорт конструктора стилей
export default useStyleMui
