import React from 'react'
// Node
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material'
// Types
import MaterialTableTypes from '@materialComponents/MaterialTable/types/MaterialTable'
import MaterialRowTypes from './types/MaterialRow'

/*
 * Компонент "Строка таблицы" (обертка над MaterialUI)
 */

// Экспорт компонента
export const MaterialRow: React.FC<MaterialRowTypes.ComponentProps> = (props) => {
    // Преобразование пропсов в локальные константы
    const { row, headers, tableHasSlaveRows, slaveRowTitle } = props

    const [open, setOpen] = React.useState(false)

    // Массив заголовков второстепенных строк таблицы
    const headersContent = []
    for (const key in headers) {
        if (!headers[key]?.main) {
            headersContent.push(
                <TableCell key={`tableCellSlaveHeader_${key}`}>{headers[key]?.title}</TableCell>,
            )
        }
    }
    // Массив главных строк таблицы
    const mainRowContent = []
    for (const key in row) {
        const rowSpan = headers ? headers[key]?.rowSpan : 0
        const colSpan = headers ? headers[key]?.colSpan : 0
        const sx = headers ? headers[key]?.sx : null
        if (!['slaveRows'].includes(key)) {
            mainRowContent.push(
                <TableCell
                    rowSpan={rowSpan}
                    colSpan={colSpan}
                    sx={sx}
                    key={`tableCellMainRow_${key}`}
                >
                    {row[key]}
                </TableCell>,
            )
        }
    }
    // Массив второстепенных строк таблицы
    const slaveRowContent: React.ReactNode[] = []
    row?.slaveRows?.forEach((item: MaterialTableTypes.slaveRow, index: number) => {
        const tableCellContent: React.ReactNode[] = []
        for (const key in item) {
            tableCellContent.push(
                <TableCell key={`tableCellSlaveRow_${index}_${key}`}>{item[key]}</TableCell>,
            )
        }
        slaveRowContent.push(
            <TableRow key={`tableRowSlaveRow_${index}`}>{tableCellContent}</TableRow>,
        )
    })

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                {tableHasSlaveRows ? (
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                ) : (
                    []
                )}

                {mainRowContent}
            </TableRow>
            {tableHasSlaveRows ? (
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    {slaveRowTitle}
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>{headersContent}</TableRow>
                                    </TableHead>
                                    <TableBody>{slaveRowContent}</TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            ) : (
                []
            )}
        </>
    )
}

// Свойства по умолчанию
MaterialRow.defaultProps = {}

// Экспорт компонента
export default MaterialRow
