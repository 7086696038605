//Node
import { styled } from '@mui/material/styles'

/*
 * Стилизованный компонент "Инфо"
 */

const InfoStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    color: theme.palette.text.disabled,
}))

// Экспорт компонента
export default InfoStyle
