// Экспорт валидатора
export const validator = {
    // Выполнить валидацию
    validate(data: any, endpointName: string, validatorTypeName: string) {
        try {
            let validatorModule
            // Попытка подключения модуля валидатора
            try {
                validatorModule = require(`@api/endpoints/${endpointName}/validators/${validatorTypeName}.d.validator.ts`)
            } catch (err: any) {
                // Не удалось подключить модуль валидатора
                console.warn(
                    'Предупреждение о валидации:',
                    `валидатор ${endpointName}/validators/${validatorTypeName}.d.validator.ts не сгенерирован`,
                )
            }
            // Если модуль валидатора существует
            if (validatorModule) {
                // Пропустить данные через валидатор
                return validatorModule.default(data)
            } else {
                // Вернуть данные в чистом виде
                return data
            }
        } catch (err: any) {
            console.error(
                'Ошибка валидации:',
                err.message.substr(0, err.message.lastIndexOf('\n\n')),
            )
        }
    },
}
