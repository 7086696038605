// Node
import _ from 'lodash-contrib'
// Types
import APITypes from '@api/types/api'
import EducationAPITypes from '@api/endpoints/educationAPI/types/educationAPI'

// Маппинг свойств, полученных из API в свойства InitialState
const APIToInitialStatePropsMapping = {
    idStudentStart: 'educationId',
}

export const educationListBuilderMapper = {
    dataMapping(response: APITypes.AxiosResponse) {
        // Проход по массиву обучений и преобразование свойств
        // к пригодному для бизнес-логики приложения виду
        response = response?.map((item: EducationAPITypes.EducationListItem) =>
            // Маппинг свойств, полученных из API в свойства InitialState
            _.renameKeys(item, APIToInitialStatePropsMapping),
        )
        return response
    },
}
