// Node
import config from 'react-global-configuration'
import rootBuilder from './builder'

// Экспорт построителя
export const builder = (type: string) => {
    // Тип API из конфигурации
    const apiType = config.get(`api.${type}.type`)

    return rootBuilder[
        // Пример: 'newsBuilderInstance' или 'newsBuilderOriginal'
        `${type}Builder${apiType.toUpperCase()[0] + apiType.slice(1)}`
    ]
}
