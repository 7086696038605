/* tslint:disable */
// generated by typescript-json-validator
import { inspect } from 'util'
import Ajv from 'ajv'
import EducationItem from './EducationItem.d'
export const ajv = new Ajv({
    allErrors: true,
    coerceTypes: false,
    format: 'fast',
    nullable: true,
    unicode: true,
    uniqueItems: true,
    useDefaults: true,
})

ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'))

export { EducationItem }
export const EducationItemSchema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    defaultProperties: [],
    definitions: {
        'EducationAPITypes.AttachedDocuments': {
            defaultProperties: [],
            properties: {
                downloadUrl: {
                    type: 'string',
                },
                filename: {
                    type: 'string',
                },
                iconUrl: {
                    type: ['null', 'string'],
                },
                sizeDisplay: {
                    type: ['null', 'string'],
                },
                type: {
                    type: ['null', 'string'],
                },
            },
            required: ['downloadUrl', 'filename', 'iconUrl', 'sizeDisplay', 'type'],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.AnchorHTMLAttributes<HTMLAnchorElement>,HTMLAnchorElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.AreaHTMLAttributes<HTMLAreaElement>,HTMLAreaElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.AudioHTMLAttributes<HTMLAudioElement>,HTMLAudioElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.BaseHTMLAttributes<HTMLBaseElement>,HTMLBaseElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.BlockquoteHTMLAttributes<HTMLElement>,HTMLElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.ButtonHTMLAttributes<HTMLButtonElement>,HTMLButtonElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.CanvasHTMLAttributes<HTMLCanvasElement>,HTMLCanvasElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.ColHTMLAttributes<HTMLTableColElement>,HTMLTableColElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.ColgroupHTMLAttributes<HTMLTableColElement>,HTMLTableColElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.DataHTMLAttributes<HTMLDataElement>,HTMLDataElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.DelHTMLAttributes<HTMLElement>,HTMLElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.DetailsHTMLAttributes<HTMLElement>,HTMLElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.DialogHTMLAttributes<HTMLDialogElement>,HTMLDialogElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.EmbedHTMLAttributes<HTMLEmbedElement>,HTMLEmbedElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.FieldsetHTMLAttributes<HTMLFieldSetElement>,HTMLFieldSetElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.FormHTMLAttributes<HTMLFormElement>,HTMLFormElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.HTMLAttributes<HTMLBRElement>,HTMLBRElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.HTMLAttributes<HTMLBodyElement>,HTMLBodyElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.HTMLAttributes<HTMLDListElement>,HTMLDListElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.HTMLAttributes<HTMLDataListElement>,HTMLDataListElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.HTMLAttributes<HTMLDivElement>,HTMLDivElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLHeadElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.HTMLAttributes<HTMLHRElement>,HTMLHRElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.HTMLAttributes<HTMLHeadingElement>,HTMLHeadingElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.HTMLAttributes<HTMLLegendElement>,HTMLLegendElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.HTMLAttributes<HTMLParagraphElement>,HTMLParagraphElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.HTMLAttributes<HTMLPreElement>,HTMLPreElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.HTMLAttributes<HTMLSpanElement>,HTMLSpanElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.HTMLAttributes<HTMLTableRowElement>,HTMLTableRowElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.HTMLAttributes<HTMLTableSectionElement>,HTMLTableSectionElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.HTMLAttributes<HTMLTemplateElement>,HTMLTemplateElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.HTMLAttributes<HTMLTitleElement>,HTMLTitleElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.HTMLAttributes<HTMLUListElement>,HTMLUListElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.HtmlHTMLAttributes<HTMLHtmlElement>,HTMLHtmlElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.IframeHTMLAttributes<HTMLIFrameElement>,HTMLIFrameElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.ImgHTMLAttributes<HTMLImageElement>,HTMLImageElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.InputHTMLAttributes<HTMLInputElement>,HTMLInputElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.InsHTMLAttributes<HTMLModElement>,HTMLModElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.KeygenHTMLAttributes<HTMLElement>,HTMLElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.LabelHTMLAttributes<HTMLLabelElement>,HTMLLabelElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.LiHTMLAttributes<HTMLLIElement>,HTMLLIElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.LinkHTMLAttributes<HTMLLinkElement>,HTMLLinkElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.MapHTMLAttributes<HTMLMapElement>,HTMLMapElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.MenuHTMLAttributes<HTMLElement>,HTMLElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.MetaHTMLAttributes<HTMLMetaElement>,HTMLMetaElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.MeterHTMLAttributes<HTMLElement>,HTMLElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.ObjectHTMLAttributes<HTMLObjectElement>,HTMLObjectElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.OlHTMLAttributes<HTMLOListElement>,HTMLOListElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.OptgroupHTMLAttributes<HTMLOptGroupElement>,HTMLOptGroupElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.OptionHTMLAttributes<HTMLOptionElement>,HTMLOptionElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.OutputHTMLAttributes<HTMLElement>,HTMLElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.ParamHTMLAttributes<HTMLParamElement>,HTMLParamElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.ProgressHTMLAttributes<HTMLProgressElement>,HTMLProgressElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.QuoteHTMLAttributes<HTMLQuoteElement>,HTMLQuoteElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.ScriptHTMLAttributes<HTMLScriptElement>,HTMLScriptElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.SelectHTMLAttributes<HTMLSelectElement>,HTMLSelectElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.SlotHTMLAttributes<HTMLSlotElement>,HTMLSlotElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.SourceHTMLAttributes<HTMLSourceElement>,HTMLSourceElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.StyleHTMLAttributes<HTMLStyleElement>,HTMLStyleElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.TableHTMLAttributes<HTMLTableElement>,HTMLTableElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.TdHTMLAttributes<HTMLTableDataCellElement>,HTMLTableDataCellElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.TextareaHTMLAttributes<HTMLTextAreaElement>,HTMLTextAreaElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.ThHTMLAttributes<HTMLTableHeaderCellElement>,HTMLTableHeaderCellElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.DetailedHTMLFactory<React.TimeHTMLAttributes<HTMLElement>,HTMLElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.TrackHTMLAttributes<HTMLTrackElement>,HTMLTrackElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.VideoHTMLAttributes<HTMLVideoElement>,HTMLVideoElement>': {
            defaultProperties: [],
            type: 'object',
        },
        'React.DetailedHTMLFactory<React.WebViewHTMLAttributes<HTMLWebViewElement>,HTMLWebViewElement>':
            {
                defaultProperties: [],
                type: 'object',
            },
        'React.ReactHTML': {
            defaultProperties: [],
            properties: {
                a: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.AnchorHTMLAttributes<HTMLAnchorElement>,HTMLAnchorElement>',
                },
                abbr: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                address: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                area: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.AreaHTMLAttributes<HTMLAreaElement>,HTMLAreaElement>',
                },
                article: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                aside: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                audio: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.AudioHTMLAttributes<HTMLAudioElement>,HTMLAudioElement>',
                },
                b: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                base: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.BaseHTMLAttributes<HTMLBaseElement>,HTMLBaseElement>',
                },
                bdi: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                bdo: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                big: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                blockquote: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.BlockquoteHTMLAttributes<HTMLElement>,HTMLElement>',
                },
                body: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLBodyElement>,HTMLBodyElement>',
                },
                br: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLBRElement>,HTMLBRElement>',
                },
                button: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.ButtonHTMLAttributes<HTMLButtonElement>,HTMLButtonElement>',
                },
                canvas: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.CanvasHTMLAttributes<HTMLCanvasElement>,HTMLCanvasElement>',
                },
                caption: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                cite: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                code: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                col: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.ColHTMLAttributes<HTMLTableColElement>,HTMLTableColElement>',
                },
                colgroup: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.ColgroupHTMLAttributes<HTMLTableColElement>,HTMLTableColElement>',
                },
                data: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.DataHTMLAttributes<HTMLDataElement>,HTMLDataElement>',
                },
                datalist: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLDataListElement>,HTMLDataListElement>',
                },
                dd: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                del: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.DelHTMLAttributes<HTMLElement>,HTMLElement>',
                },
                details: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.DetailsHTMLAttributes<HTMLElement>,HTMLElement>',
                },
                dfn: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                dialog: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.DialogHTMLAttributes<HTMLDialogElement>,HTMLDialogElement>',
                },
                div: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLDivElement>,HTMLDivElement>',
                },
                dl: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLDListElement>,HTMLDListElement>',
                },
                dt: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                em: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                embed: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.EmbedHTMLAttributes<HTMLEmbedElement>,HTMLEmbedElement>',
                },
                fieldset: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.FieldsetHTMLAttributes<HTMLFieldSetElement>,HTMLFieldSetElement>',
                },
                figcaption: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                figure: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                footer: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                form: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.FormHTMLAttributes<HTMLFormElement>,HTMLFormElement>',
                },
                h1: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLHeadingElement>,HTMLHeadingElement>',
                },
                h2: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLHeadingElement>,HTMLHeadingElement>',
                },
                h3: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLHeadingElement>,HTMLHeadingElement>',
                },
                h4: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLHeadingElement>,HTMLHeadingElement>',
                },
                h5: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLHeadingElement>,HTMLHeadingElement>',
                },
                h6: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLHeadingElement>,HTMLHeadingElement>',
                },
                head: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLHeadElement>',
                },
                header: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                hgroup: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                hr: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLHRElement>,HTMLHRElement>',
                },
                html: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HtmlHTMLAttributes<HTMLHtmlElement>,HTMLHtmlElement>',
                },
                i: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                iframe: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.IframeHTMLAttributes<HTMLIFrameElement>,HTMLIFrameElement>',
                },
                img: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.ImgHTMLAttributes<HTMLImageElement>,HTMLImageElement>',
                },
                input: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.InputHTMLAttributes<HTMLInputElement>,HTMLInputElement>',
                },
                ins: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.InsHTMLAttributes<HTMLModElement>,HTMLModElement>',
                },
                kbd: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                keygen: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.KeygenHTMLAttributes<HTMLElement>,HTMLElement>',
                },
                label: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.LabelHTMLAttributes<HTMLLabelElement>,HTMLLabelElement>',
                },
                legend: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLLegendElement>,HTMLLegendElement>',
                },
                li: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.LiHTMLAttributes<HTMLLIElement>,HTMLLIElement>',
                },
                link: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.LinkHTMLAttributes<HTMLLinkElement>,HTMLLinkElement>',
                },
                main: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                map: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.MapHTMLAttributes<HTMLMapElement>,HTMLMapElement>',
                },
                mark: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                menu: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.MenuHTMLAttributes<HTMLElement>,HTMLElement>',
                },
                menuitem: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                meta: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.MetaHTMLAttributes<HTMLMetaElement>,HTMLMetaElement>',
                },
                meter: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.MeterHTMLAttributes<HTMLElement>,HTMLElement>',
                },
                nav: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                noscript: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                object: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.ObjectHTMLAttributes<HTMLObjectElement>,HTMLObjectElement>',
                },
                ol: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.OlHTMLAttributes<HTMLOListElement>,HTMLOListElement>',
                },
                optgroup: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.OptgroupHTMLAttributes<HTMLOptGroupElement>,HTMLOptGroupElement>',
                },
                option: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.OptionHTMLAttributes<HTMLOptionElement>,HTMLOptionElement>',
                },
                output: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.OutputHTMLAttributes<HTMLElement>,HTMLElement>',
                },
                p: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLParagraphElement>,HTMLParagraphElement>',
                },
                param: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.ParamHTMLAttributes<HTMLParamElement>,HTMLParamElement>',
                },
                picture: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                pre: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLPreElement>,HTMLPreElement>',
                },
                progress: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.ProgressHTMLAttributes<HTMLProgressElement>,HTMLProgressElement>',
                },
                q: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.QuoteHTMLAttributes<HTMLQuoteElement>,HTMLQuoteElement>',
                },
                rp: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                rt: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                ruby: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                s: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                samp: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                script: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.ScriptHTMLAttributes<HTMLScriptElement>,HTMLScriptElement>',
                },
                section: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                select: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.SelectHTMLAttributes<HTMLSelectElement>,HTMLSelectElement>',
                },
                slot: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.SlotHTMLAttributes<HTMLSlotElement>,HTMLSlotElement>',
                },
                small: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                source: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.SourceHTMLAttributes<HTMLSourceElement>,HTMLSourceElement>',
                },
                span: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLSpanElement>,HTMLSpanElement>',
                },
                strong: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                style: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.StyleHTMLAttributes<HTMLStyleElement>,HTMLStyleElement>',
                },
                sub: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                summary: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                sup: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                table: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.TableHTMLAttributes<HTMLTableElement>,HTMLTableElement>',
                },
                tbody: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLTableSectionElement>,HTMLTableSectionElement>',
                },
                td: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.TdHTMLAttributes<HTMLTableDataCellElement>,HTMLTableDataCellElement>',
                },
                template: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLTemplateElement>,HTMLTemplateElement>',
                },
                textarea: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.TextareaHTMLAttributes<HTMLTextAreaElement>,HTMLTextAreaElement>',
                },
                tfoot: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLTableSectionElement>,HTMLTableSectionElement>',
                },
                th: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.ThHTMLAttributes<HTMLTableHeaderCellElement>,HTMLTableHeaderCellElement>',
                },
                thead: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLTableSectionElement>,HTMLTableSectionElement>',
                },
                time: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.TimeHTMLAttributes<HTMLElement>,HTMLElement>',
                },
                title: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLTitleElement>,HTMLTitleElement>',
                },
                tr: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLTableRowElement>,HTMLTableRowElement>',
                },
                track: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.TrackHTMLAttributes<HTMLTrackElement>,HTMLTrackElement>',
                },
                u: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                ul: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLUListElement>,HTMLUListElement>',
                },
                var: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                video: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.VideoHTMLAttributes<HTMLVideoElement>,HTMLVideoElement>',
                },
                wbr: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.HTMLAttributes<HTMLElement>,HTMLElement>',
                },
                webview: {
                    $ref: '#/definitions/React.DetailedHTMLFactory<React.WebViewHTMLAttributes<HTMLWebViewElement>,HTMLWebViewElement>',
                },
            },
            required: [
                'a',
                'abbr',
                'address',
                'area',
                'article',
                'aside',
                'audio',
                'b',
                'base',
                'bdi',
                'bdo',
                'big',
                'blockquote',
                'body',
                'br',
                'button',
                'canvas',
                'caption',
                'cite',
                'code',
                'col',
                'colgroup',
                'data',
                'datalist',
                'dd',
                'del',
                'details',
                'dfn',
                'dialog',
                'div',
                'dl',
                'dt',
                'em',
                'embed',
                'fieldset',
                'figcaption',
                'figure',
                'footer',
                'form',
                'h1',
                'h2',
                'h3',
                'h4',
                'h5',
                'h6',
                'head',
                'header',
                'hgroup',
                'hr',
                'html',
                'i',
                'iframe',
                'img',
                'input',
                'ins',
                'kbd',
                'keygen',
                'label',
                'legend',
                'li',
                'link',
                'main',
                'map',
                'mark',
                'menu',
                'menuitem',
                'meta',
                'meter',
                'nav',
                'noscript',
                'object',
                'ol',
                'optgroup',
                'option',
                'output',
                'p',
                'param',
                'picture',
                'pre',
                'progress',
                'q',
                'rp',
                'rt',
                'ruby',
                's',
                'samp',
                'script',
                'section',
                'select',
                'slot',
                'small',
                'source',
                'span',
                'strong',
                'style',
                'sub',
                'summary',
                'sup',
                'table',
                'tbody',
                'td',
                'template',
                'textarea',
                'tfoot',
                'th',
                'thead',
                'time',
                'title',
                'tr',
                'track',
                'u',
                'ul',
                'var',
                'video',
                'wbr',
                'webview',
            ],
            type: 'object',
        },
    },
    properties: {
        documents: {
            items: {
                $ref: '#/definitions/EducationAPITypes.AttachedDocuments',
            },
            type: 'array',
        },
        endDate: {
            type: ['null', 'string'],
        },
        groupAbbreviation: {
            type: ['null', 'string'],
        },
        htmlContent: {
            anyOf: [
                {
                    $ref: '#/definitions/React.ReactHTML',
                },
                {
                    type: ['null', 'string'],
                },
            ],
        },
        idDInstitute: {
            type: 'number',
        },
        idDKafedra: {
            type: 'number',
        },
        idEducationalPlan: {
            type: ['null', 'number'],
        },
        idStudentStart: {
            type: 'number',
        },
        instituteName: {
            type: 'string',
        },
        instituteUrl: {
            type: 'string',
        },
        kafedraName: {
            type: 'string',
        },
        kafedraUrl: {
            type: 'string',
        },
        kindTraining: {
            type: 'string',
        },
        qualificationName: {
            type: ['null', 'string'],
        },
        specName: {
            type: 'string',
        },
        specQualifier: {
            type: ['null', 'string'],
        },
        startDate: {
            type: 'string',
        },
    },
    required: [
        'documents',
        'endDate',
        'groupAbbreviation',
        'htmlContent',
        'idDInstitute',
        'idDKafedra',
        'idEducationalPlan',
        'idStudentStart',
        'instituteName',
        'instituteUrl',
        'kafedraName',
        'kafedraUrl',
        'kindTraining',
        'qualificationName',
        'specName',
        'specQualifier',
        'startDate',
    ],
    type: 'object',
}
export type ValidateFunction<T> = ((data: unknown) => data is T) &
    Pick<Ajv.ValidateFunction, 'errors'>
export const isEducationItem = ajv.compile(EducationItemSchema) as ValidateFunction<EducationItem>
export default function validate(value: unknown): EducationItem {
    if (isEducationItem(value)) {
        return value
    } else {
        throw new Error(
            ajv.errorsText(
                isEducationItem.errors!.filter((e: any) => e.keyword !== 'if'),
                { dataVar: 'EducationItem' },
            ) +
                '\n\n' +
                inspect(value),
        )
    }
}
