import React from 'react'
// Node
import { Route } from 'react-router-dom'
// Layouts
import WindowParamsSetter from '../../layouts/MainLayout/components/WindowParamsSetter'
// Pages
import MainPage from '@pages/MainPage'
import NewsPage from '@pages/NewsPage'
import AboutPage from '@pages/AboutPage'
import AdmissionPage from '@pages/AdmissionPage'
import EducationPage from '@pages/EducationPage'
import NotificationsPage from '@pages/NotificationsPage'
// Own
import useStyleMui from './styles/style.mui'

/*
 * Область "Контент"
 */

const ContentArea = () => {
    const styleMui = useStyleMui()

    return (
        <main className={styleMui.content}>
            <div className={styleMui.toolbar} />

            <Route path="/" exact render={() => <MainPage />} />
            <Route path="/news" exact render={() => <NewsPage />} />
            <Route path="/profile" exact render={() => <AboutPage />} />
            <Route path="/admission" exact render={() => <AdmissionPage />} />
            <Route path="/education" exact render={() => <EducationPage />} />
            <Route path="/notifications" exact render={() => <NotificationsPage />} />

            <WindowParamsSetter />
        </main>
    )
}

// Экспорт области
export default ContentArea
