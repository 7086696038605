import React from 'react'
// Node
import { connect } from 'react-redux'
import { useTheme } from '@mui/material/styles'
// Components
import MaterialTabs from '@materialComponents/MaterialTabs'
// Redux
import { getNotificationList } from '@redux/reducers/notifications-reducer'
// Types
import { AppStateType } from '@redux/store'
import NotificationsReducerTypes from '@redux/reducers/types/notifications-reducer'
import NotificationsPageTypes from './types/NotificationsPage'
// Own
import { NotificationsGroup } from './components'

/*
 * Страница "Уведомления"
 */

const NotificationsPage: React.FC<
    NotificationsPageTypes.MapStateToProps & NotificationsPageTypes.MapDispatchToProps
> = (
    props,
) => {
    // Преобразование пропсов в локальные константы
    const { notificationList, contentWidth, isUpSM, getNotificationList } = props

    // Тема MUI
    const theme = useTheme()
    
    // Хук эффекта
    React.useEffect(() => {
        // Получение списка групп уведомлений
        getNotificationList()        
    }, [])

    // Установка локального значения переменной activeTabIndex
    const [activeTabIndex, setActiveTabIndex] = React.useState(0)
    
    const notificationsTabs: Array<{label: React.ReactNode, content: React.ReactNode}>= []
    
    notificationList?.notificationGroups?.forEach((group: NotificationsReducerTypes.NotificationGroup) => {
        notificationsTabs.push({
            label: group?.title,
            content: <NotificationsGroup 
                groupId={group?.id} 
                notificationsPage={group?.notificationsPage} 
            />,
        })
    })
    
    return (
        <MaterialTabs
            tabs={notificationsTabs}
            tabsWidth={contentWidth}
            variant={'scrollable'}
            activeTabIndex={activeTabIndex}
            onClick={(newIndex) => setActiveTabIndex(newIndex)}
            tabStyle={{minWidth: 'fit-content'}}
            tabsListStyle={isUpSM ? {} : {marginLeft: '-15px'}}
            tabBoxStyle={isUpSM ? {} : {
                'h6': {fontSize: '1.1rem', lineHeight: '1.3'},
                '& > .MuiTypography-root': {mt: `${theme.spacing(1)} !important`},
            }}            
        />
    )
}

// Маппинг стейта в пропсы страницы
const mapStateToProps = (state: AppStateType): NotificationsPageTypes.MapStateToProps => ({
    contentWidth: state.windowParamsStore?.contentWidth,
    isUpSM: state.windowParamsStore?.isUpSM,
    notificationList: state.notificationsStore?.notificationList,    
})

// Экспорт страницы
export default connect(mapStateToProps, { getNotificationList })(NotificationsPage)
