// Node
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// Types
import AdmissionReducerTypes from '@redux/reducers/types/admission-reducer'

// Начальное состояние
const initialState: AdmissionReducerTypes.InitialState = {
    // Режим отображения
    showMode: 'list',
}

// Слайс поступлений
const admissionSlice = createSlice({
    name: 'admission',
    initialState,
    reducers: {
        // Установить режим отображения
        setShowMode(
            state: AdmissionReducerTypes.InitialState,
            action: PayloadAction<AdmissionReducerTypes.InitialState['showMode']>,
        ) {
            state.showMode = action.payload
        },
    },
})

// Экспорт создателей действий
export const { setShowMode } = admissionSlice.actions
// Экспорт редьюсера
export default admissionSlice.reducer
