import React from 'react'
// Node
import _ from 'lodash-contrib'
import { useSelector } from 'react-redux'
import { Box, Button, List, ListItem, Typography } from '@mui/material'
// Components
import { LinkField } from '@commonComponents/DataFields'
import MaterialSlider from '@materialComponents/MaterialSlider'
// Utils
import { fDateTime } from '@utils/formatTime'
// Types
import { AppStateType } from '@redux/store'
import EducationTabsBlockTypes from '../../types/EducationTabsBlock'
import EducationPortfolioReducerTypes from '@redux/reducers/types/education-portfolio-reducer'
// Own
import useStyleMui from './styles/style.mui'

/*
 * Компонент "Портфолио"
 */

const EducationPortfolio: React.FC<EducationTabsBlockTypes.MapStateToProps> = (props) => {
    // Преобразование пропсов в локальные константы
    const { educationPortfolio } = props
    
    // Стиль компонента
    const styleMui = useStyleMui()
    
    // Ширина контента
    const contentWidth = useSelector((state: AppStateType) => state.windowParamsStore?.contentWidth)
    
    // Маркер того, является ли разрешение экрана больше брекпоинта 'sm'
    const isUpSM = useSelector((state: AppStateType) => state.windowParamsStore?.isUpSM)
    
    return (
        <>
            {
                educationPortfolio?.map((item: EducationPortfolioReducerTypes.PortfolioItem, indexA: number) => {
                    // Контент документов
                    const documents = item?.documents
                        ? item?.documents?.map((document: EducationPortfolioReducerTypes.AttachedDocuments, indexB: number) => (
                            <Box className={styleMui.document} key={`educationPortfolioDocument_${indexA}_${indexB}`}>
                                <LinkField
                                    fieldId={`document_${indexA}_${indexB}`}
                                    linkText={document?.filename}
                                    linkTextPosition={'after'}
                                    linkURL={document?.downloadUrl}
                                    linkTarget={'_blank'}
                                    linkAlt={document?.sizeDisplay}
                                    linkSX={{ display: 'block', width: 'fit-content' }}
                                    image={document?.iconUrl}
                                />
                            </Box>
                        )) : []

                    // Контент комментариев
                    const comments = item?.comments
                        ? <MaterialSlider
                            enableAutoPlay={false}
                            showNavButtons={true}
                            navButtonsMaxWidth={'500px'}
                            sx={{ marginLeft: {xs: '-15px', sm: 0}, maxWidth: contentWidth }}
                            variant={isUpSM ? 'dots' : 'text'}
                            slides={
                                item?.comments?.map((comment: EducationPortfolioReducerTypes.Comment, indexB: number) => ({
                                    label: fDateTime(comment?.commentDate),
                                    src: (
                                        <Box className={styleMui.document} key={`educationPortfolioComment_${indexA}_${indexB}`}>
                                            <List className={styleMui.list} sx={{ marginLeft: {xs: '15px', sm: 0} }} >
                                                <ListItem className={styleMui.listItem}>
                                                    <Box className={`${styleMui.listItemCaption} _grey`} component={'span'}>
                                                        {fDateTime(comment?.commentDate)}
                                                    </Box>
                                                </ListItem>
                                            </List>
                                            <List className={styleMui.list} sx={{ ml: 3 }}>
                                                <ListItem className={styleMui.listItem}>
                                                    <LinkField
                                                        fieldId={`${comment?.idPortfolioComment}_${indexB}`}
                                                        linkText={comment?.commentatorFio}
                                                        linkTextPosition={'after'}
                                                        linkURL={comment?.commentatorUrl}
                                                        linkTarget={'_blank'}
                                                        linkSX={{ display: 'block', width: 'fit-content' }}
                                                        image={comment?.commentatorPhotoUrl}
                                                        imageSX={{ float: {xs: 'left', sm: 'none'} }}
                                                    />
                                                </ListItem>
                                            </List>
                                            <List className={styleMui.list} sx={{ ml: 3 }}>
                                                <ListItem className={styleMui.listItem}>
                                                    <Box dangerouslySetInnerHTML={{ __html: comment?.text }} />
                                                </ListItem>
                                            </List>
                                        </Box>)
                                }))
                            }
                        /> : []

                    return (
                        <Box key={`educationPortfolio_${item.idPortfolioItem}`}>
                            <Typography className={styleMui.disciplineName} variant={'h5'}>Достижение №{item?.sortingIndex}</Typography>
                            <Box>
                                <Box className={styleMui.contentBlock}>
                                    <Box className={styleMui.caption} component={'span'}>
                                        Описание достижения
                                    </Box>
                                    <List className={styleMui.list} >
                                        <ListItem className={styleMui.listItem}>
                                            <Box className={`${styleMui.listItemCaption} _grey`} component={'span'}>
                                                {item?.descriptionHtml}
                                            </Box>
                                        </ListItem>
                                    </List>
                                </Box>
                                {!_.isEmpty(documents)
                                    ? <Box className={styleMui.contentBlock}>
                                        <Box className={styleMui.caption} component={'span'}>
                                            Файлы
                                        </Box>
                                        {documents}
                                    </Box>
                                    : []
                                }
                                {!_.isEmpty(comments)
                                    ? <Box className={styleMui.contentBlock}>
                                        <Box className={styleMui.caption} component={'span'}>
                                            Рецензии
                                        </Box>
                                        {comments}
                                    </Box>
                                    : []
                                }
                                {item?.mayEdit || item?.mayEditRequest
                                    ? <Box className={styleMui.contentBlock}>
                                        <Box className={styleMui.caption} component={'span'}>
                                            Управление
                                        </Box>
                                        <List className={styleMui.list} >
                                            <ListItem className={styleMui.listItem}>
                                                <Box className={`${styleMui.listItemCaption} _grey`} component={'span'}>
                                                    {item?.mayEdit
                                                        ? <Button sx={{ mr: 1 }} color={'secondary'} variant={'outlined'}>Удалить</Button>
                                                        : []
                                                    }
                                                    {item?.mayEditRequest
                                                        ? <Button sx={{ mr: 1 }} color={'primary'} variant={'outlined'}>Отозвать</Button>
                                                        : []
                                                    }
                                                </Box>
                                            </ListItem>
                                        </List>
                                    </Box>
                                    : []
                                }
                            </Box>
                        </Box >
                    )
                })
            }
        </>
    )
}

// Экспорт компонента
export default EducationPortfolio
