// Node
import { makeStyles } from '@mui/styles'
import { Theme, useTheme } from '@mui/material/styles'

// Конструктор стилей
const useStyleMui = () => {
    // Использовать текущую тему
    const theme: Theme = useTheme()
    return makeStyles(() => ({
        modal: {
            left: `calc((${theme.spacing(7)} + 1px) / 2)`,
            width: `calc(100% - ${theme.spacing(7)} + 1px)`,
            [theme.breakpoints.up('sm')]: {
                left: `calc((${theme.spacing(9)} + 1px) / 2)`,
                width: `calc(100% - ${theme.spacing(9)} + 1px)`,
            },
            [theme.breakpoints.down('xs')]: {
                maxWidth: '400px',
                margin: '0 auto',
            },
        },
    }))()
}

// Экспорт конструктора стилей
export default useStyleMui
