import React from 'react'
// Node
import { connect } from 'react-redux'
import { Typography } from '@mui/material'
// Components
import { FileField, SecretField } from '@commonComponents/DataFields'
// Redux
import { getAccountPassword } from '@redux/reducers/accounts-reducer'
// Types
import { AppStateType } from '@redux/store'

// Экспорт компонента "Атрибут аккаунта"
export const AccountAttribute: React.FC<{
    currentPasswordAttributeValue: AboutPageTypes.MapStateToProps['currentPasswordAttributeValue']
    getAccountPassword: AboutPageTypes.MapDispatchToProps['getAccountPassword']
    object: AuthAPITypes.AccountAttribute
}> = (props) => {
    // Преобразование пропсов в локальные константы
    const { object, currentPasswordAttributeValue, getAccountPassword } = props
    // Преобразование свойств объекта атрибута в локальные константы
    const {
        idAccountAttribute,
        type,
        displayName,
        theValue,
        filename,
        filesizeDisplay,
        fileExtension,
        fileURL,
    } = object

    // Значение атрибута аккаунта
    const accountAttributValue: Array<React.ReactNode> = []
    // Установка значения атрибута аккаунта в зависимости от его типа
    switch (type) {
        case 'STRING':
            accountAttributValue.push(theValue)
            break
        case 'SECRET_STRING':
            accountAttributValue.push(
                <SecretField
                    fieldId={idAccountAttribute}
                    secretValue={currentPasswordAttributeValue}
                    onTooltipOpen={() =>
                        getAccountPassword && getAccountPassword(idAccountAttribute)
                    }
                    onTooltipClose={() => getAccountPassword && getAccountPassword(null)}
                />,
            )
            break
        case 'FILE':
            accountAttributValue.push(
                <FileField
                    fieldId={idAccountAttribute}
                    fileURL={fileURL}
                    fileName={filename}
                    fileExtension={fileExtension}
                    fileSizeDisplay={filesizeDisplay}
                />,
            )
            break
    }

    return (
        <>
            <Typography sx={{ float: 'left', fontWeight: 'bold' }}>
                {`${displayName}:`}&nbsp;
            </Typography>
            <Typography>{accountAttributValue}</Typography>
        </>
    )
}

// Маппинг стейта в пропсы компонента
const mapStateToProps = (state: AppStateType): AboutPageTypes.MapStateToProps => ({
    currentPasswordAttributeValue: state.accountsStore?.currentPasswordAttributeValue,
})

// Экспорт контейнера компонента "Атрибут аккаунта"
export const AccountAttributeContainer = connect(mapStateToProps, { getAccountPassword })(
    AccountAttribute,
)
