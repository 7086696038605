// Node
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import config from 'react-global-configuration'
// API
import { notificationsAPI } from '@api/endpoints/notificationsAPI'
// Types
import { AppGetStateType, AppDispatchType } from '@redux/store'
import NotificationsReducerTypes from '@redux/reducers/types/notifications-reducer'
// Создатели действий пагинации
import { setCurrentPage, setItemsPerPage, setTotalItems } from './pagination-reducer'
// Создатели действий загрузки
import { setLoading, setLoaded } from './load-reducer'

// Начальное состояние
const initialState: NotificationsReducerTypes.InitialState = {
    // Количество непрочитанных уведомлений
    unreadCount: null,
    // Количество необработанных запросов по непрочитанным уведомлениям
    unreadRequestsCount: 0,
    // Список групп уведомлений
    notificationList: {},
}

// Слайс оповещений
const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        // Установить общее количество непрочитанных уведомлений
        setUnreadCount(
            state: NotificationsReducerTypes.InitialState,
            action: PayloadAction<NotificationsReducerTypes.InitialState['unreadCount']>,
        ) {
            state.unreadCount = action.payload
        },
        // Установить количество необработанных запросов по непрочитанным уведомлениям
        setUnreadRequestsCount(
            state: NotificationsReducerTypes.InitialState,
            action: PayloadAction<NotificationsReducerTypes.InitialState['unreadRequestsCount']>,
        ) {
            state.unreadRequestsCount = action.payload
        },
        // Установить список групп уведомлений
        setNotificationList(
            state: NotificationsReducerTypes.InitialState,
            action: PayloadAction<NotificationsReducerTypes.InitialState['notificationList']>,
        ) {
            state.notificationList = action.payload
        },
        // Установить одну группу уведомлений в списке
        setNotificationGroup(
            state: NotificationsReducerTypes.InitialState,
            action: PayloadAction<NotificationsReducerTypes.NotificationGroup>,
        ) {            
            Object.assign(
                state, 
                {
                    ...state,
                    notificationList: {
                        ...state?.notificationList,
                        notificationGroups: state?.notificationList?.notificationGroups?.map(
                            (group: NotificationsReducerTypes.NotificationGroup) => 
                                (group?.id === action.payload?.id) 
                                ? action.payload
                                : group
                        ),
                    }
                }
            )
        },
        // Установить один элемент уведомления
        setNotificationItem(
            state: NotificationsReducerTypes.InitialState,
            action: PayloadAction<NotificationsReducerTypes.NotificationListItem>,
        ) {            
            Object.assign(
                state, 
                {
                    ...state,
                    notificationList: {
                        ...state?.notificationList,
                        notificationGroups: state?.notificationList?.notificationGroups?.map(
                            (group: NotificationsReducerTypes.NotificationGroup) => ({
                                ...group,
                                notificationsPage: {
                                    ...group?.notificationsPage,
                                    content: group?.notificationsPage?.content?.map(
                                        (listItem: NotificationsReducerTypes.NotificationListItem) =>
                                            (listItem?.id === action.payload?.id) 
                                            ? action.payload
                                            : listItem
                                    )
                                }                                                                
                            })
                        )
                    }
                }
            )
        },                        
    },
})

// Экспорт создателей действий
export const { 
    setUnreadCount, setNotificationList, setNotificationGroup, 
    setNotificationItem, setUnreadRequestsCount,
} = notificationsSlice.actions
// Экспорт редьюсера
export default notificationsSlice.reducer

// ВСПОМОГАТЕЛЬНЫЕ МЕТОДЫ

// Получение уведомления в списке стейта по айди
const getStateNotificationItemById = (itemId: number, getState: AppGetStateType) => {
    let stateNotificationItem: NotificationsReducerTypes.NotificationListItem | null = null
    
    getState().notificationsStore.notificationList?.notificationGroups?.forEach(
        (group: NotificationsReducerTypes.NotificationGroup) => {
            const findedItem = group?.notificationsPage?.content?.find(
                (item: NotificationsReducerTypes.NotificationListItem) => 
                    item?.id === itemId
            )
            stateNotificationItem = findedItem ? findedItem : null                    
        }
    )
    return stateNotificationItem
}

// Установка данных при первом открытии уведомления
const setNotificationItemFirstOpenData = (
    notificationItem: NotificationsReducerTypes.NotificationListItem | null, 
    dispatch: AppDispatchType,
    getState: AppGetStateType
) => {
    // Если у открытого уведомления не установлена дата
    if(notificationItem && !notificationItem['readDate']) {            
        // Установить один элемент уведомления
        dispatch(setNotificationItem({
            ...notificationItem as NotificationsReducerTypes.NotificationListItem, 
            readDate: `${new Date()}`
        }))        
        // Количество непрочитанных уведомлений из стейта
        let unreadCount = getState().notificationsStore.unreadCount
        // Количество необработанных запросов по непрочитанным уведомлениям
        let unreadRequestsCount = getState().notificationsStore.unreadRequestsCount
        // Установить количество необработанных запросов по непрочитанным уведомлениям
        dispatch(setUnreadRequestsCount(++unreadRequestsCount))
        // Установить количество непрочитанных уведомлений
        dispatch(setUnreadCount(unreadCount ? --unreadCount : 0))
    }
}

// Получение группы уведомлений в стейте по айди
const getStateNotificationGroupById = (groupId: number, getState: AppGetStateType) => {   
    const group = getState().notificationsStore.notificationList?.notificationGroups?.map(
        (group: NotificationsReducerTypes.NotificationGroup) => {
            if(group?.id === groupId) {
                return group
            }                               
        }
    )
    return group?.length ? group[0] : null    
}

// Установка единой даты прочтения для всех уведомлений в группе
const setCommonReadDateInGroup = (
    groupId: number, dispatch: AppDispatchType, getState: AppGetStateType, 
    readDate: NotificationsReducerTypes.NotificationListItem['readDate']
) => {
    // Получение группы уведомлений в стейте по айди
    const group = getStateNotificationGroupById(groupId, getState)
    const groupModified = {...group, notificationsPage: {...group.notificationsPage, content: []}}           
    // Проход по списку уведомлений
    for(let i = 0; i < group?.notificationsPage?.content?.length; i++) {
        const item = {...group.notificationsPage.content[i]}
        // Установка даты прочтения уведомления
        item.readDate = readDate
        groupModified.notificationsPage.content.push(item)                
    }
    // Установить одну группу уведомлений в списке        
    dispatch(setNotificationGroup(groupModified))    
}

//THUNK CREATORS

// Получение количества непрочитанных уведомлений
export const getUnreadCount = (checkUnreadRequests = false, onExecute?: () => void) => {
    return (dispatch: AppDispatchType, getState: AppGetStateType) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading('notificationsUnreadCount'))

        // Получение данных через API уведомлений
        notificationsAPI.getUnreadCount().then((response: APITypes.AxiosResponse) => {
            // Количество необработанных запросов по непрочитанным уведомлениям
            let unreadRequestsCount = getState().notificationsStore.unreadRequestsCount
            // Если режим проверки необработанных запросов(и их количество не равно 0)
            if(checkUnreadRequests && unreadRequestsCount) {
                // Установить количество необработанных запросов по непрочитанным уведомлениям
                dispatch(setUnreadRequestsCount(--unreadRequestsCount))
            }
            
            // Если обычный режим или режим проверки необработанных запросов(и их количество равно 0)
            if(!checkUnreadRequests || (checkUnreadRequests && !unreadRequestsCount)) {                
                // Установить количество непрочитанных уведомлений
                dispatch(setUnreadCount(response))
                onExecute && onExecute()            
            }
            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded('notificationsUnreadCount'))
        })
    }
}

// Получение одного элемента уведомления
export const getNotificationsItem = (itemId = 1) => {
    return (dispatch: AppDispatchType, getState: AppGetStateType) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading('notificationsItem'))
              
        // Получение открытого уведомления из стейта
        const currentNotificationsItem = getStateNotificationItemById(itemId, getState)        
        // Установка данных при первом открытии уведомления
        setNotificationItemFirstOpenData(currentNotificationsItem, dispatch, getState)        
                
        // Получение данных через API уведомлений
        notificationsAPI.getNotificationsItem(itemId).then((response: APITypes.AxiosResponse) => {        
            // Установить один элемент уведомления
            dispatch(setNotificationItem(!response?.readDate // Если дата прочтения у открытого уведомления не задана                                
                ? {...response, readDate: `${new Date()}`}
                : response
            ))            
            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded('notificationsItem'))
                     
            // Если дата прочтения у открытого уведомления не задана
            if(!response?.readDate) {                                  
                // Установка данных через API уведомлений
                notificationsAPI.markNotificationsItemAsRead(itemId).then((response2: APITypes.AxiosResponse) => {
                    response2 // - для EsLint 'no-unused-vars'                                                  
                    // Установить один элемент уведомления
                    dispatch(setNotificationItem({...response, readDate: `${new Date()}`}))                    
                    // Получение количества непрочитанных уведомлений
                    dispatch(getUnreadCount(true))
                })
            }
        })        
    }
}

// Получение одной группы уведомлений в списке
export const getNotificationsGroup = (groupId = 1, page = config.get('api.news.pagination.currentPage')) => {
    return (dispatch: AppDispatchType) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading(`notificationsGroup${groupId}`))
        // Получение данных через API уведомлений
        notificationsAPI.getNotificationsGroup(groupId, page).then((response: APITypes.AxiosResponse) => {        
            // Установить одну группу уведомлений в списке        
            dispatch(setNotificationGroup(response))
            
            // Установить текущую страницу пагинации
            dispatch(setCurrentPage([`notificationsGroup${groupId}`, response?.notificationsPage?.number]))            
            // Установить количество элементов на странице
            dispatch(setItemsPerPage([`notificationsGroup${groupId}`, response?.notificationsPage?.size]))
            // Установить общее количество элементов
            dispatch(setTotalItems([`notificationsGroup${groupId}`, response?.notificationsPage?.totalElements]))            
            
            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded(`notificationsGroup${groupId}`))
        })        
    }
}

// Получение списка групп уведомлений
export const getNotificationList = () => {
    return (dispatch: AppDispatchType) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading('notificationsStructure'))

        // Получение данных через API уведомлений
        notificationsAPI.getNotificationsStructure().then((response: APITypes.AxiosResponse) => {
            // Установить список групп уведомлений
            dispatch(setNotificationList(response))
            
            // Проход по группам уведомлений
            response?.notificationGroups?.forEach((group: APITypes.AxiosResponse) => {             
                // Получение одной группы уведомлений в списке
                dispatch(getNotificationsGroup(group?.id))
            })            
            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded('notificationsStructure'))
        })
    }
}    

// Пометить группу уведомлений как прочитанную
export const markNotificationsGroupAsRead = (groupId = 1, uiCallback?: () => void) => {
    return (dispatch: AppDispatchType, getState: AppGetStateType) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading('notificationsMarkGroupAsRead'))

        // Установка данных через API уведомлений
        notificationsAPI.markNotificationsGroupAsRead({ groupId }).then((response: APITypes.AxiosResponse) => {
            response // - для EsLint 'no-unused-vars'                        
            // Получение количества непрочитанных уведомлений
            dispatch(getUnreadCount(false, () => {
                // Установка единой даты прочтения для всех уведомлений в группе
                setCommonReadDateInGroup(groupId, dispatch, getState, `${new Date()}`)                
                // Выполнение колбэка UI                                   
                uiCallback && uiCallback()
            }))                 
            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded('notificationsMarkGroupAsRead'))
        })
    }
}

// Пометить группу уведомлений как непрочитанную
export const markNotificationsGroupAsUnread = (groupId = 1, uiCallback?: () => void) => {
    return (dispatch: AppDispatchType, getState: AppGetStateType) => {
        // Установить состояние загрузки в "Загружается"
        dispatch(setLoading('notificationsMarkGroupAsUnread'))

        // Установка данных через API уведомлений
        notificationsAPI.markNotificationsGroupAsUnread({ groupId }).then((response: APITypes.AxiosResponse) => {
            response // - для EsLint 'no-unused-vars'                        
            // Получение количества непрочитанных уведомлений
            dispatch(getUnreadCount(false, () => {
                // Установка единой даты прочтения для всех уведомлений в группе
                setCommonReadDateInGroup(groupId, dispatch, getState, null)                
                // Выполнение колбэка UI                                   
                uiCallback && uiCallback()
            }))                 
            // Установить состояние загрузки в "Загружено"
            dispatch(setLoaded('notificationsMarkGroupAsUnread'))
        })
    }
}
