// Node
import { makeStyles } from '@mui/styles'

// Конструктор стилей
const useStyleMui = () => {
    return makeStyles(() => ({
        stepperStep: {
            '&:hover': {
                cursor: 'pointer',
                opacity: 0.6,
            },
        },
    }))()
}

// Экспорт конструктора стилей
export default useStyleMui
