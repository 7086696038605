// Node
import config from 'react-global-configuration'
// Types
import APITypes from '@api/types/api'
import NotificationsAPITypes from '@api/endpoints/notificationsAPI/types/notificationsAPI'

export const notificationsGroupBuilderInstance = {
    dataForGet(group: number, page: number) {
        return config.get('api.notificationsGroup.url').replace('{groupId}', group) + `?page=${--page}`
    },
    dataForThen(response: APITypes.AxiosResponse, page: number): NotificationsAPITypes.NotificationGroup {
        page // - для EsLint 'no-unused-vars'
        response = response.data
        // Инкремент номера страницы пагинации(т.к. 1-я страница в API имеет индекс = 0)
        response.notificationsPage.number++

        return response
    }, 
}
