// Node
import config from 'react-global-configuration'
// API
import axios from '@api/instance'
// Types
import APITypes from '@api/types/api'

// Экспорт API меню
export const menuAPI = {
    getMenuItems() {
        return axios[config.get('api.menu.type') as APITypes.AxiosType]
            .get(config.get('api.menu.url'))
            .then((response: APITypes.AxiosResponse) => {
                return response.data
            })
            .catch(() => {
                return null
            })
    },
}
